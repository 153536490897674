/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NotificationUpdateDto,
  UINotificationResponseDto,
} from '../models/index';
import {
    NotificationUpdateDtoFromJSON,
    NotificationUpdateDtoToJSON,
    UINotificationResponseDtoFromJSON,
    UINotificationResponseDtoToJSON,
} from '../models/index';

export interface DeleteUINotificationRequest {
    id: string;
}

export interface GetUINotificationRequest {
    id: string;
}

export interface GetUINotificationsByUserIdAndIsReadRequest {
    userId: string;
    isRead?: boolean;
}

export interface UpdateNewUINotificationRequest {
    notificationUpdateDto: NotificationUpdateDto;
}

/**
 * 
 */
export class UiNotificationControllerApi extends runtime.BaseAPI {

    /**
     */
    async deleteUINotificationRaw(requestParameters: DeleteUINotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteUINotification().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/notification/ui-notification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async deleteUINotification(requestParameters: DeleteUINotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteUINotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUINotificationRaw(requestParameters: GetUINotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UINotificationResponseDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getUINotification().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/notification/ui-notification/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UINotificationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async getUINotification(requestParameters: GetUINotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UINotificationResponseDto | null | undefined > {
        const response = await this.getUINotificationRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get a list of all notifications by UserId. If is_read is absent returns all notifications.If is_read = true, returns only read, if is_read = false returns not read
     * Get notifications by UserId
     */
    async getUINotificationsByUserIdAndIsReadRaw(requestParameters: GetUINotificationsByUserIdAndIsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUINotificationsByUserIdAndIsRead().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['user-id'] = requestParameters['userId'];
        }

        if (requestParameters['isRead'] != null) {
            queryParameters['is-read'] = requestParameters['isRead'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/notification/ui-notification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Get a list of all notifications by UserId. If is_read is absent returns all notifications.If is_read = true, returns only read, if is_read = false returns not read
     * Get notifications by UserId
     */
    async getUINotificationsByUserIdAndIsRead(requestParameters: GetUINotificationsByUserIdAndIsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getUINotificationsByUserIdAndIsReadRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     */
    async updateNewUINotificationRaw(requestParameters: UpdateNewUINotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UINotificationResponseDto>> {
        if (requestParameters['notificationUpdateDto'] == null) {
            throw new runtime.RequiredError(
                'notificationUpdateDto',
                'Required parameter "notificationUpdateDto" was null or undefined when calling updateNewUINotification().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/notification/ui-notification/status`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NotificationUpdateDtoToJSON(requestParameters['notificationUpdateDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UINotificationResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async updateNewUINotification(requestParameters: UpdateNewUINotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UINotificationResponseDto> {
        const response = await this.updateNewUINotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
