import { WarningCircle } from 'phosphor-react'
import React, { useState } from 'react'
import tailwindConfig from 'tailwind.config'

interface IProps {
  size?: number
  color?: string
  hoverColor?: string
  weight?: 'thin' | 'light' | 'regular' | 'bold' | 'fill' | 'duotone'
}

function ErrorRound({
  size = 20,
  color = tailwindConfig.theme.extend.colors.SecondaryRed900,
  hoverColor,
  weight = 'regular'
}: IProps) {
  const [iconColor, setColor] = useState(color)

  return (
    <span className="changeColor">
      <WarningCircle
        size={size}
        color={iconColor}
        weight={weight}
        onMouseOver={() => {
          hoverColor && setColor(hoverColor)
        }}
        onMouseOut={() => setColor(color)}
      />
    </span>
  )
}

export default ErrorRound
