import { isAllUpperCase } from '@/utils/common'
import { ICredentialAttibute } from './types/credential'
import { capitalCase } from 'change-case'
import { ISchema } from './types/schema'
import { consoleError } from '@/utils/console-logger'

export const RESERVED_ATTRIBUTE_KEYS = ['d', 'dt', 'i', 'u']
export const RESERVED_RULE_KEYS = ['d']
export const RESERVED_EDGE_KEYS = ['d']

export function parseSchemaList(schemas: any[]): ISchema[] {
  let parsedSchemas: ISchema[] = []
  for (let i = 0; i < schemas.length; i++) {
    try {
      let schema = parseSchema(schemas[i])
      parsedSchemas.push(schema)
    } catch (ex) {
      consoleError('Error while parsing schema:', ex)
    }
  }
  return parsedSchemas
}

export function parseSchema(schema: any): ISchema {
  const { allAttributes, nonReservedAttributes } = parseSchemaAttributes(schema)
  const edge = parseSchemaEdgeOrRuleSection(schema.properties?.e)
  const rules = parseSchemaEdgeOrRuleSection(schema.properties?.r)

  let hasIssuee = allAttributes.some((attribute) => attribute.key === 'i')
  let hasPrivateNonce = allAttributes.some((attribute) => attribute.key === 'u')

  return {
    said: schema.$id,
    title: schema.title,
    description: schema.description,
    credentialType: schema.credentialType,
    version: schema.version,
    attributes: nonReservedAttributes,
    edge: edge,
    rules: rules,
    isUntargetedACDC: hasIssuee === true ? false : true,
    isPrivateACDC: hasPrivateNonce,
    raw: schema
  }
}

export function parseSchemaAttributes(schema: any): {
  allAttributes: ICredentialAttibute[]
  nonReservedAttributes: ICredentialAttibute[]
} {
  let properties: any
  let required: any[] = []
  let attrObj = schema.properties?.a

  if (attrObj?.oneOf) {
    properties = attrObj?.oneOf?.find(
      (item: any) => item.type === 'object'
    )?.properties
    required =
      attrObj?.oneOf?.find((item: any) => item.type === 'object')?.required ||
      []
  } else {
    properties = attrObj?.properties
    required = attrObj?.required || []
  }

  const allAttributes: ICredentialAttibute[] = []
  const nonReservedAttributes: ICredentialAttibute[] = []

  if (properties) {
    for (const key in properties) {
      if (properties.hasOwnProperty(key)) {
        const attr = properties[key]
        const humanReadableKey =
          isAllUpperCase(key) === true ? key : capitalCase(key)
        const isRequired = required.includes(key)

        let defaultValue: any = ''
        let validationRule: ((value: any) => string | null) | undefined

        switch (attr.type) {
          case 'string':
            defaultValue = ''
            validationRule = isRequired
              ? (value: any) => {
                  if (!value.trim())
                    return 'credential.attribute.validation.required'
                  return null
                }
              : undefined
            break
          case 'boolean':
            defaultValue = false
            validationRule = isRequired
              ? (value: any) => {
                  if (value !== true && value !== false)
                    return 'credential.attribute.validation.required'
                  return null
                }
              : undefined
            break
          case 'array':
            defaultValue = []
            validationRule = isRequired
              ? (value: any) => {
                  if (!Array.isArray(value) || value.length === 0)
                    return 'credential.attribute.validation.required'
                  return null
                }
              : undefined
            break
          case 'number':
          case 'integer':
            defaultValue = 0
            validationRule = isRequired
              ? (value: any) => {
                  if (value === null || value === undefined || isNaN(value))
                    return 'credential.attribute.validation.required'
                  return null
                }
              : undefined
            break
          default:
            defaultValue = ''
            validationRule = undefined
        }

        const attribute: ICredentialAttibute = {
          key: key,
          value: defaultValue,
          description: attr.description,
          valueType: attr.type,
          isRequired: isRequired,
          humanReadableKey: humanReadableKey,
          validationRule: validationRule
        }

        allAttributes.push(attribute)
        if (!RESERVED_ATTRIBUTE_KEYS.includes(key)) {
          nonReservedAttributes.push(attribute)
        }
      }
    }
  }

  return {
    allAttributes,
    nonReservedAttributes
  }
}

export function parseSchemaEdgeOrRuleSection(schemaSection: any): any {
  if (!schemaSection) {
    return null
  }
  const findObjectInOneOf = (oneOfArray: any[]) => {
    return oneOfArray.find((item: any) => item.type === 'object')
  }

  const parseProperties = (properties: any) => {
    let parsedObject: any = {}

    for (const key in properties) {
      if (properties.hasOwnProperty(key)) {
        let field = properties[key]

        // Handle oneOf inside the properties
        if (field.oneOf) {
          const objectInOneOf = findObjectInOneOf(field.oneOf)
          if (objectInOneOf) {
            // Recursive call to handle nested properties within oneOf
            parsedObject[key] = parseProperties(objectInOneOf.properties)
          } else {
            parsedObject[key] = field.oneOf[0]
          }
        } else if (field.type === 'object') {
          parsedObject[key] = parseProperties(field.properties)
        } else {
          parsedObject[key] = {
            description: field.description,
            type: field.type,
            ...(field.const && { const: field.const })
          }
        }
      }
    }

    return parsedObject
  }

  if (schemaSection?.oneOf) {
    const properties = schemaSection?.oneOf?.find(
      (item: any) => item.type === 'object'
    )?.properties

    if (properties) {
      return parseProperties(properties)
    }
  } else if (schemaSection?.properties) {
    const properties = schemaSection?.properties
    return parseProperties(properties)
  } else {
    const properties = schemaSection?.properties
    if (properties) {
      return parseProperties(properties)
    }
    if (
      schemaSection &&
      typeof schemaSection === 'object' &&
      schemaSection.type === 'string'
    ) {
      return schemaSection.hasOwnProperty('const')
        ? schemaSection.const
        : undefined
    }
  }

  return undefined
}

export const formatAsCredentialEdgeOrRuleObject = (
  schemaSectionObject: any
) => {
  if (
    !schemaSectionObject ||
    (typeof schemaSectionObject === 'object' &&
      Object.keys(schemaSectionObject).length === 0)
  ) {
    return null
  }

  const output: any = {}

  const parseNestedSchemaFieldObject = (nestedObject: any) => {
    const nestedOutput: any = {}

    for (const key in nestedObject) {
      if (nestedObject.hasOwnProperty(key)) {
        const subItem = nestedObject[key]

        if (subItem && typeof subItem === 'object') {
          if ('type' in subItem) {
            nestedOutput[key] = parseSchemaFieldObject(subItem)
          } else {
            nestedOutput[key] = parseNestedSchemaFieldObject(subItem)
          }
        }
      }
    }

    return nestedOutput
  }

  if (typeof schemaSectionObject === 'string' && schemaSectionObject) {
    return schemaSectionObject
  } else {
    for (const key in schemaSectionObject) {
      if (schemaSectionObject.hasOwnProperty(key)) {
        const fieldItem = schemaSectionObject[key]
        if (fieldItem && typeof fieldItem === 'object') {
          if ('type' in fieldItem) {
            output[key] = parseSchemaFieldObject(fieldItem)
          } else {
            output[key] = parseNestedSchemaFieldObject(fieldItem)
          }
        }
      }
    }
  }

  return output
}

const parseSchemaFieldObject = (item: any) => {
  if (item && typeof item === 'object' && item.type === 'string') {
    return item.hasOwnProperty('const') ? item.const : ''
  }
  return {}
}

export const getSchemaFieldOfEdge = (edgeObj) => {
  if (!edgeObj) {
    return null
  }

  for (const key in edgeObj) {
    if (edgeObj.hasOwnProperty(key)) {
      if (edgeObj[key].hasOwnProperty('s')) {
        return edgeObj[key]['s']
      }
    }
  }
  return null
}

export const setNodeValueInEdge = (obj, nodeSaid) => {
  if (!obj) {
    return null
  }

  for (const key in obj) {
    if (obj[key].hasOwnProperty('n')) {
      obj[key]['n'] = nodeSaid
    }
  }
  return obj
}

export const convertSchemaAttributeValueType = (
  attribute: ICredentialAttibute
) => {
  let value
  switch (attribute.valueType) {
    case 'string':
      value = String(attribute.value)
      break
    case 'integer':
      value = parseInt(attribute.value, 10)
      break
    case 'boolean':
      value = attribute.value === 'true' || attribute.value === true
      break
    case 'array':
      value = Array.isArray(attribute.value)
        ? attribute.value
        : [attribute.value]
      break
    case 'number':
      value = parseFloat(attribute.value)
      break
    default:
      value = attribute.value
  }
  return value
}

// /// // TODO: to be modified for nested edges, once new ACDC spec is implemented
// // /**
// //  * Finds the first object in the provided edge object that contains the `s` field.
// //  * Returns the value of the schema `s` field along with a reference to the object
// //  * where the node `n` field will be set later.
// //  *
// //  * @param {object} obj - The edge object to search through.
// //  * @returns {{ edgeSchema: string, ref: object } | null} - An object containing the `edgeSchema` and a reference to the object where the `s` field was found.
// //  * Returns `null` if no object with an `s` field is found.
// //  *
// //  * @example
// //  * const schema = {
// //  *   "auth": {
// //  *     "n": "",
// //  *     "s": "said_of_edge_schema",
// //  *   }
// //  * };
// //  * const result = getSchemaFieldValueOfEdge(schema);
// //  * // result.edgeSchema would be "said_of_edge_schema"
// //  * // result.ref would reference the "auth" object
// //  */
// // export const getSchemaFieldOfEdge = (EdgeObj: any): { edgeSchema: string, ref: any } | null => {
// //   for (const key in EdgeObj) {
// //     if (EdgeObj.hasOwnProperty(key)) {
// //       const value = EdgeObj[key]

// //       if (value && typeof value === 'object' && 's' in value) {
// //         return { edgeSchema: value['s'], ref: value }
// //       }
// //     }
// //   }
// //   return null
// // }

// // /**
// //  * Sets the node `n` field of a referenced object of edge block using the provided `nodeSaid`.
// //  * The reference is expected to point to an object that contains the `n` field,
// //  * typically found via the `getSchemaFieldOfEdge` function.
// //  *
// //  * @param {object} ref - The reference to the edge object where the node `n` field should be updated.
// //  * @param {string} nodeSaid - The value to set for the node `n` field.
// //  * @returns {void}
// //  *
// //  * @example
// //  * const ref = {
// //  *   "n": "",
// //  *   "s": "said_of_edge_schema",
// //  * };
// //  * setNodeUsingSchemaFieldRefInEdge(ref, "new_n_value");
// //  * // ref.n is now "new_n_value"
// //  */
// // export const setNodeUsingSchemaFieldRefInEdge = (ref: any, nodeSaid: string) => {
// //   ref['n'] = nodeSaid
// // }
