/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TitleAndGrantStatus
 */
export interface TitleAndGrantStatus {
    /**
     * 
     * @type {string}
     * @memberof TitleAndGrantStatus
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleAndGrantStatus
     */
    shortTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleAndGrantStatus
     */
    grantStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleAndGrantStatus
     */
    elfCode?: string;
    /**
     * 
     * @type {string}
     * @memberof TitleAndGrantStatus
     */
    jobType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TitleAndGrantStatus
     */
    recipient?: boolean;
}

/**
 * Check if a given object implements the TitleAndGrantStatus interface.
 */
export function instanceOfTitleAndGrantStatus(value: object): boolean {
    return true;
}

export function TitleAndGrantStatusFromJSON(json: any): TitleAndGrantStatus {
    return TitleAndGrantStatusFromJSONTyped(json, false);
}

export function TitleAndGrantStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): TitleAndGrantStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'shortTitle': json['shortTitle'] == null ? undefined : json['shortTitle'],
        'grantStatus': json['grantStatus'] == null ? undefined : json['grantStatus'],
        'elfCode': json['elfCode'] == null ? undefined : json['elfCode'],
        'jobType': json['jobType'] == null ? undefined : json['jobType'],
        'recipient': json['recipient'] == null ? undefined : json['recipient'],
    };
}

export function TitleAndGrantStatusToJSON(value?: TitleAndGrantStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'shortTitle': value['shortTitle'],
        'grantStatus': value['grantStatus'],
        'elfCode': value['elfCode'],
        'jobType': value['jobType'],
        'recipient': value['recipient'],
    };
}

