/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OorProposalResponseDto
 */
export interface OorProposalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof OorProposalResponseDto
     */
    workflowInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof OorProposalResponseDto
     */
    workflowInstanceAssignedTaskId?: string;
    /**
     * 
     * @type {string}
     * @memberof OorProposalResponseDto
     */
    requesterId?: string;
    /**
     * 
     * @type {string}
     * @memberof OorProposalResponseDto
     */
    status?: OorProposalResponseDtoStatusEnum;
}


/**
 * @export
 */
export const OorProposalResponseDtoStatusEnum = {
    PreAssigned: 'PRE_ASSIGNED',
    Assigned: 'ASSIGNED',
    InProgress: 'IN_PROGRESS',
    QarRejected: 'QAR_REJECTED',
    Rejected: 'REJECTED',
    Failed: 'FAILED',
    Complete: 'COMPLETE'
} as const;
export type OorProposalResponseDtoStatusEnum = typeof OorProposalResponseDtoStatusEnum[keyof typeof OorProposalResponseDtoStatusEnum];


/**
 * Check if a given object implements the OorProposalResponseDto interface.
 */
export function instanceOfOorProposalResponseDto(value: object): boolean {
    return true;
}

export function OorProposalResponseDtoFromJSON(json: any): OorProposalResponseDto {
    return OorProposalResponseDtoFromJSONTyped(json, false);
}

export function OorProposalResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OorProposalResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': json['workflowInstanceAssignedTaskId'] == null ? undefined : json['workflowInstanceAssignedTaskId'],
        'requesterId': json['requesterId'] == null ? undefined : json['requesterId'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function OorProposalResponseDtoToJSON(value?: OorProposalResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': value['workflowInstanceAssignedTaskId'],
        'requesterId': value['requesterId'],
        'status': value['status'],
    };
}

