/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrgDto
 */
export interface OrgDto {
    /**
     * 
     * @type {string}
     * @memberof OrgDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDto
     */
    brandId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDto
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDto
     */
    lei?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDto
     */
    createdByUser?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDto
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof OrgDto
     */
    orgType?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrgDto
     */
    publicRecordLinks?: Array<string>;
}

/**
 * Check if a given object implements the OrgDto interface.
 */
export function instanceOfOrgDto(value: object): boolean {
    return true;
}

export function OrgDtoFromJSON(json: any): OrgDto {
    return OrgDtoFromJSONTyped(json, false);
}

export function OrgDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrgDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'brandId': json['brandId'] == null ? undefined : json['brandId'],
        'name': json['name'] == null ? undefined : json['name'],
        'domain': json['domain'] == null ? undefined : json['domain'],
        'lei': json['lei'] == null ? undefined : json['lei'],
        'status': json['status'] == null ? undefined : json['status'],
        'createdByUser': json['createdByUser'] == null ? undefined : json['createdByUser'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'orgType': json['orgType'] == null ? undefined : json['orgType'],
        'publicRecordLinks': json['publicRecordLinks'] == null ? undefined : json['publicRecordLinks'],
    };
}

export function OrgDtoToJSON(value?: OrgDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'brandId': value['brandId'],
        'name': value['name'],
        'domain': value['domain'],
        'lei': value['lei'],
        'status': value['status'],
        'createdByUser': value['createdByUser'],
        'createdAt': value['createdAt'],
        'orgType': value['orgType'],
        'publicRecordLinks': value['publicRecordLinks'],
    };
}

