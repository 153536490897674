import { userRoleService, userService, utilService } from '@/utils/apis'

// we c
export async function getUserDetails() {
  return await userService.userInfo()

  // return await get(`/user/info`, {
  //   BASE_URL: constants.AUTH_URL
  // })
}

// export const getUserDetailsByUserId = async (
//   userId: string
// ): Promise<UserInfo> => {
//   return await get(`/user/${userId}/info`, {
//     BASE_URL: constants.AUTH_URL
//   })
// }

export async function getLarResponse(encryptedParams: string) {
  return await utilService.acceptanceForGrant({ encryptedParams })
  // return await get(`/util/acceptance/${response}`, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function getUsersByRole(role: string) {
  return await userRoleService.userInfo1({ role })
  // return await get(`/role/${role}/user/info`, {
  //   BASE_URL: constants.AUTH_URL
  // })
}

export async function getUserJobTitles(user: string, org: string) {
  return await userRoleService.getJobTitlesByUserIdAndOrgId({ org, user })
  // return await get(`/job-title/user/${userId}/org/${orgId}/info`, {
  //   BASE_URL: constants.AUTH_URL
  // })
}
