/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePublicRecordsRequestDto
 */
export interface UpdatePublicRecordsRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePublicRecordsRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePublicRecordsRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdatePublicRecordsRequestDto
     */
    publicRecordLinks?: Array<string>;
}

/**
 * Check if a given object implements the UpdatePublicRecordsRequestDto interface.
 */
export function instanceOfUpdatePublicRecordsRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('orgId' in value)) return false;
    return true;
}

export function UpdatePublicRecordsRequestDtoFromJSON(json: any): UpdatePublicRecordsRequestDto {
    return UpdatePublicRecordsRequestDtoFromJSONTyped(json, false);
}

export function UpdatePublicRecordsRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePublicRecordsRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'orgId': json['orgId'],
        'publicRecordLinks': json['publicRecordLinks'] == null ? undefined : json['publicRecordLinks'],
    };
}

export function UpdatePublicRecordsRequestDtoToJSON(value?: UpdatePublicRecordsRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'orgId': value['orgId'],
        'publicRecordLinks': value['publicRecordLinks'],
    };
}

