/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ResearchGleifResponseDto,
} from '../models/index';
import {
    ResearchGleifResponseDtoFromJSON,
    ResearchGleifResponseDtoToJSON,
} from '../models/index';

export interface ResearchGleifRequest {
    parameters: { [key: string]: string; };
}

/**
 * 
 */
export class ResearchGleifControllerApi extends runtime.BaseAPI {

    /**
     * Calls GLEIF\'s LEI lookup API and returns a JSON array of possible matches. In the array containing results, matches are listed in descending order of confidence (best match first).
     * Research GLEIF for org parameters
     */
    async researchGleifRaw(requestParameters: ResearchGleifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ResearchGleifResponseDto>>> {
        if (requestParameters['parameters'] == null) {
            throw new runtime.RequiredError(
                'parameters',
                'Required parameter "parameters" was null or undefined when calling researchGleif().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['parameters'] != null) {
            queryParameters['parameters'] = requestParameters['parameters'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/research/gleif`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ResearchGleifResponseDtoFromJSON));
    }

    /**
     * Calls GLEIF\'s LEI lookup API and returns a JSON array of possible matches. In the array containing results, matches are listed in descending order of confidence (best match first).
     * Research GLEIF for org parameters
     */
    async researchGleif(requestParameters: ResearchGleifRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ResearchGleifResponseDto>> {
        const response = await this.researchGleifRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
