import React from 'react'
import tailwindConfig from 'tailwind.config'

interface IProps {
  size?: number
  color?: string
  className?: string
}

function ErrorSquare({
  size = 20,
  color = 'currentColor',
  className = ''
}: IProps) {
  return (
    <span className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 20C9.734 20 9.48047 19.9501 9.2394 19.8504C8.99834 19.7506 8.77806 19.6093 8.57855 19.4264L0.573566 11.4214C0.39069 11.2219 0.249377 11.0017 0.149626 10.7606C0.0498753 10.5195 0 10.266 0 10C0 9.734 0.0498753 9.47631 0.149626 9.22693C0.249377 8.97756 0.39069 8.76143 0.573566 8.57855L8.57855 0.573566C8.77806 0.374065 8.99834 0.228595 9.2394 0.137157C9.48047 0.045719 9.734 0 10 0C10.266 0 10.5237 0.045719 10.7731 0.137157C11.0224 0.228595 11.2386 0.374065 11.4214 0.573566L19.4264 8.57855C19.6259 8.76143 19.7714 8.97756 19.8628 9.22693C19.9543 9.47631 20 9.734 20 10C20 10.266 19.9543 10.5195 19.8628 10.7606C19.7714 11.0017 19.6259 11.2219 19.4264 11.4214L11.4214 19.4264C11.2386 19.6093 11.0224 19.7506 10.7731 19.8504C10.5237 19.9501 10.266 20 10 20ZM10 10.9975C10.2826 10.9975 10.5195 10.9019 10.7107 10.7107C10.9019 10.5195 10.9975 10.2826 10.9975 10V6.00998C10.9975 5.72735 10.9019 5.49044 10.7107 5.29925C10.5195 5.10806 10.2826 5.01247 10 5.01247C9.71737 5.01247 9.48047 5.10806 9.28928 5.29925C9.09809 5.49044 9.00249 5.72735 9.00249 6.00998V10C9.00249 10.2826 9.09809 10.5195 9.28928 10.7107C9.48047 10.9019 9.71737 10.9975 10 10.9975ZM10 13.99C10.2826 13.99 10.5195 13.8944 10.7107 13.7032C10.9019 13.5121 10.9975 13.2751 10.9975 12.9925C10.9975 12.7099 10.9019 12.473 10.7107 12.2818C10.5195 12.0906 10.2826 11.995 10 11.995C9.71737 11.995 9.48047 12.0906 9.28928 12.2818C9.09809 12.473 9.00249 12.7099 9.00249 12.9925C9.00249 13.2751 9.09809 13.5121 9.28928 13.7032C9.48047 13.8944 9.71737 13.99 10 13.99Z"
          fill={color}
        />
      </svg>
    </span>
  )
}

export default ErrorSquare
