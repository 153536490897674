import { create } from 'zustand'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import enterpriseStore, { IEnterpriseStore } from './enterprise'
import connectionStore, { IConnectionStore } from './connection'
import vleiStore, { IVLEIStore } from './vlei'
import authenticationStore, { AuthenticationStore } from './authentication'
import credentialsStore, { ICredential } from './credentials'
import signifyStore, { ISignify } from './signify'
import identifierStore, { IIdentifierStore } from './identifier'
import meetStore, { IMeetStore } from './meet'
import localizationStore, { ILocalization } from './localization'
import organizationDetailsStore, { IOrganization } from './organizationDetails'
import constants from '@/config/constants'

interface BaseIStore {
  initStoreSubscription: () => void
  resetAllStores: () => void
}

export type IStore = IEnterpriseStore &
  IConnectionStore &
  AuthenticationStore &
  ICredential &
  IIdentifierStore &
  ISignify &
  IVLEIStore &
  IMeetStore &
  ILocalization &
  BaseIStore &
  IOrganization

const useStore = create<IStore>()(
  subscribeWithSelector(
    devtools(
      (...a) => {
        const [_set, get] = a

        return {
          ...enterpriseStore(...a),
          ...connectionStore(...a),
          ...authenticationStore(...a),
          ...credentialsStore(...a),
          ...identifierStore(...a),
          ...signifyStore(...a),
          ...vleiStore(...a),
          ...meetStore(...a),
          ...localizationStore(...a),
          ...organizationDetailsStore(...a),
          // initialize sub-stores with subscriptions if necessary
          initStoreSubscription: () => {
            get().initMeetSubscribers()
          },
          resetAllStores: () => {
            // for now we have this global reset store functionality that we are only calling it on logout action,
            // normally we should aim for modules that gets unloaded and get automatically the store cleared
            const store = get()

            store.resetEnterpriseStore()
            store.resetConnectionStore()
            store.resetAuthenticationStore()
            store.resetCredentialsStore()
            store.resetIdentifierStore()
            store.resetSignifyStore()
            store.resetVleiStore()
            store.resetMeetStore()
            store.resetOrganizationDetailsStore()
          }
        }
      },
      { enabled: constants.PROFILE === 'development' }
    )
  )
)

export default useStore
