/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OorPrerequisitesRequestDto
 */
export interface OorPrerequisitesRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OorPrerequisitesRequestDto
     * @deprecated
     */
    modelId?: string;
    /**
     * 
     * @type {string}
     * @memberof OorPrerequisitesRequestDto
     */
    orgId?: string;
}

/**
 * Check if a given object implements the OorPrerequisitesRequestDto interface.
 */
export function instanceOfOorPrerequisitesRequestDto(value: object): boolean {
    return true;
}

export function OorPrerequisitesRequestDtoFromJSON(json: any): OorPrerequisitesRequestDto {
    return OorPrerequisitesRequestDtoFromJSONTyped(json, false);
}

export function OorPrerequisitesRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OorPrerequisitesRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'modelId': json['modelId'] == null ? undefined : json['modelId'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
    };
}

export function OorPrerequisitesRequestDtoToJSON(value?: OorPrerequisitesRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'modelId': value['modelId'],
        'orgId': value['orgId'],
    };
}

