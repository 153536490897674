import {
  CreateMultisigRequest,
  CreateNativeAidRequest
} from '@/api/origin-agent-svc'
import { getOobis } from '@/services/signify'
import {
  findAllNativeAidsFromAgent,
  saveIdentifierToAgent,
  saveMultisigToAgent
} from '@/services/wallet/agent'
import { getIdentifierTypeFromAlias } from '@/services/wallet/util'
import { CLIENT_CONNECTIVITY_STATUS } from '@/state/signify'
import useStore from '@/state/store'
import { useEffect, useState } from 'react'

export enum SyncStatus {
  IDLE = 'IDLE',
  SYNCING = 'SYNCING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}
const useKeriaAgentSync = () => {
  const {
    signifyClient,
    clientConnectivityStatus,
    notificationProcessingStatus,
    getIdentifiers,
    updateIdentifier,
    authentication: { userInfo }
  } = useStore()

  const [status, setStatus] = useState<SyncStatus>(SyncStatus.IDLE)
  const [error, setError] = useState(null)

  const sync = async () => {
    setStatus(SyncStatus.SYNCING)
    try {
      const identifiers = await getIdentifiers()
      const agentAids = await findAllNativeAidsFromAgent({
        userId: userInfo.id
      })

      const multisigKeriaIdentifiers = identifiers.filter(
        (identifier) => identifier.isMultisig
      )
      const singleSigKeriaIdentifiers = identifiers.filter(
        (identifier) => !identifier.isMultisig
      )

      for (const identifier of singleSigKeriaIdentifiers) {
        const foundInAgent = agentAids.find(
          (agentAid) => agentAid.aid === identifier.prefix
        )
        let type
        if (!foundInAgent) {
          const oobis = await getOobis(signifyClient, identifier.name, 'agent')
          type = getIdentifierTypeFromAlias(identifier.name, userInfo.id)
          const payload: CreateNativeAidRequest = {
            aid: identifier.prefix,
            alias: identifier.name,
            oobi: oobis.oobis,
            userId: userInfo.id,
            isMultisig: identifier.isMultisig,
            orgId: userInfo.orgs[0].id,
            type: type
          }

          try {
            await saveIdentifierToAgent(payload)
          } catch (err) {
            console.log(err)
          }
        } else {
          type = foundInAgent.type
        }

        const updatedIdentifier = {
          ...identifier,
          type: type
        }
        updateIdentifier(updatedIdentifier)
      }

      for (const identifier of multisigKeriaIdentifiers) {
        const foundInAgent = agentAids.find(
          (agentAid) => agentAid.aid === identifier.prefix
        )

        let type
        if (!foundInAgent) {
          const oobi = await getOobis(signifyClient, identifier.name, 'agent')
          const oobiMultisig = oobi.oobis[0].split('/agent/')[0]
          type = getIdentifierTypeFromAlias(identifier.name, userInfo.id)

          let threshold
          if (typeof identifier?.raw?.state?.kt === 'string') {
            threshold = identifier?.raw?.state?.kt
          } else threshold = JSON.stringify(identifier?.raw?.state?.kt)

          const payload: CreateMultisigRequest = {
            multisigAid: {
              aid: identifier.prefix,
              alias: identifier.name,
              oobi: [oobiMultisig],
              userId: userInfo.id,
              isMultisig: identifier.isMultisig,
              orgId: userInfo.orgs[0].id,
              type: type,
              signingThreshold: threshold
            },
            memberAIDs: identifier.group.members.map((member) => member.aid)
          }

          try {
            await saveMultisigToAgent(payload)
          } catch (err) {
            console.log(err)
          }
        } else {
          type = foundInAgent.type
        }
        const updatedIdentifier = {
          ...identifier,
          type: type
        }
        updateIdentifier(updatedIdentifier)
      }

      setStatus(SyncStatus.COMPLETED)
    } catch (err) {
      setStatus(SyncStatus.FAILED)
      setError(err)
    }
  }

  useEffect(() => {
    if (
      clientConnectivityStatus !== CLIENT_CONNECTIVITY_STATUS.CONNECTED ||
      notificationProcessingStatus.length > 0 ||
      status === SyncStatus.SYNCING
    )
      return
    sync()
  }, [clientConnectivityStatus, notificationProcessingStatus])

  return { status, error }
}

export default useKeriaAgentSync
