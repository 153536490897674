import { StateCreator } from 'zustand'
import { IStore } from '@/state/store'
import { AuthStatus } from '@/state/authentication'

export type Connection = {
  id: string
  alias: string
}

export interface IConnectionStore {
  connections: Connection[]
  setConnections: (connection: Connection[]) => void
  resetConnectionStore: () => void
}

export enum Actions {
  SET_CONNECTIONS
}

export type Payload = Connection[] | Connection

type dispatchProps = {
  action: Actions
  payload: Payload
}

const reducer = (
  _state: IConnectionStore,
  { action, payload }: dispatchProps
) => {
  switch (action) {
    case Actions.SET_CONNECTIONS:
      return { connections: payload as Connection[] }
  }
}

const initialState = {
  connections: []
}

const connectionStore: StateCreator<IStore, [], [], IConnectionStore> = (
  set
) => ({
  ...initialState,
  setConnections: (connections: Connection[]) => set(() => ({ connections })),
  dispatch: (args: dispatchProps) => set((state) => reducer(state, args)),
  resetConnectionStore: () => {
    set(initialState)
  }
})

export default connectionStore
