/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface L10nBatchRequestDto
 */
export interface L10nBatchRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof L10nBatchRequestDto
     */
    ids?: Array<string>;
}

/**
 * Check if a given object implements the L10nBatchRequestDto interface.
 */
export function instanceOfL10nBatchRequestDto(value: object): boolean {
    return true;
}

export function L10nBatchRequestDtoFromJSON(json: any): L10nBatchRequestDto {
    return L10nBatchRequestDtoFromJSONTyped(json, false);
}

export function L10nBatchRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): L10nBatchRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'ids': json['ids'] == null ? undefined : json['ids'],
    };
}

export function L10nBatchRequestDtoToJSON(value?: L10nBatchRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ids': value['ids'],
    };
}

