import Link from 'next/link'
import { useRouter } from 'next/router'

export interface ILinks {
  href: string
  label: React.ReactNode
}

export interface INavbarProps {
  applicationName: string
  links?: ILinks[]
}

const Navbar = ({ applicationName, links = [] }: INavbarProps) => {
  const router = useRouter()
  return (
    <div className="fixed top-0 z-10 flex justify-between w-full p-4 text-gray-300 bg-royalBlue">
      <Link className="font-bold text-white" href="/">
        {applicationName}
      </Link>
      <ul className="flex">
        {links.map((link, key) => {
          const isActiveRoute = router.pathname === link.href
          return (
            <li
              key={key}
              className="px-4 text-white transition-all duration-300 hover:text-gray-300"
            >
              {link.href ? (
                <Link
                  href={link.href}
                  className={isActiveRoute ? 'font-bold' : ''}
                >
                  {link.label}
                </Link>
              ) : (
                link.label
              )}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Navbar
