import { StateCreator } from 'zustand'
import en from '@/locale/en.json'
import { FETCHING_STATUS } from '@/config/constants'
import { getTranslations } from '@/services/localization'
import { produce } from 'immer'

type Language = {
  lang: string
  fetchingStatus: FETCHING_STATUS
  symbolicTextIdToLocalizedText: { [key: string]: string }
}

export type ILocalization = {
  localization: Language
  getMessages: (lang: string) => void
}

const localizationStore: StateCreator<ILocalization, [], [], ILocalization> = (
  set,
  _get
) => ({
  localization: {
    lang: 'en',
    fetchingStatus: FETCHING_STATUS.NOT_INITIATED,
    symbolicTextIdToLocalizedText: en
  },
  getMessages: async (lang) => {
    set(
      produce((state: ILocalization) => {
        state.localization.lang = lang
        state.localization.fetchingStatus = FETCHING_STATUS.FETCHING
      })
    )
    try {
      const translations = await getTranslations(lang)
      set(
        produce((state: ILocalization) => {
          state.localization.fetchingStatus = FETCHING_STATUS.COMPLETE
          state.localization.symbolicTextIdToLocalizedText = {
            ...state.localization.symbolicTextIdToLocalizedText,
            ...translations.symbolicTextIdToLocalizedText
          }
        })
      )
    } catch (ex) {
      set(
        produce((state: ILocalization) => {
          state.localization.fetchingStatus = FETCHING_STATUS.FAILED
        })
      )
    }
  }
})

export default localizationStore
