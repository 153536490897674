export enum LogEmoji {
  WAVE_HAND = '👋',
  RAISE_HAND = '🙋‍♂️',
  FIRE = '🔥',
  ROCKET = '🚀',
  SUCCESS = '🎉',
  CROSS = '❌',
  BUG = '🐞'
}

export function consoleLog(
  message: string,
  data?: any,
  logLevel: LogLevel = LogLevel.INFO,
  prettyJson: boolean = false
): void {
  const outputMessage = `${getEmoji(logLevel)} >>>> ${message}`

  if (data !== undefined) {
    if (prettyJson) console.log(outputMessage, JSON.stringify(data, null, 2))
    else console.log(outputMessage, JSON.stringify(data))
  } else {
    console.log(outputMessage)
  }
}

export function consoleError(
  message: string,
  error?: any,
  data: any = undefined
): void {
  const outputMessage = `${getEmoji(LogLevel.ERROR)} >>>> ${message}`

  if (data !== undefined) {
    console.log(outputMessage, JSON.stringify(data))
  } else {
    console.log(outputMessage)
  }
  if (error !== undefined) {
    console.error(error)
  }
}

function getEmoji(logLevel: LogLevel) {
  if (logLevel == LogLevel.INFO) return LogEmoji.RAISE_HAND
  else if (logLevel == LogLevel.DEBUG) return LogEmoji.FIRE
  else if (logLevel == LogLevel.WARNING) return LogEmoji.CROSS
  else if (logLevel == LogLevel.ERROR) return LogEmoji.BUG
}

export enum LogLevel {
  INFO,
  DEBUG,
  WARNING,
  ERROR
}
