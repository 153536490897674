/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GettingInstancesRequest,
  QVIWorkflowResponseDto,
  WorkflowInstanceDto,
} from '../models/index';
import {
    GettingInstancesRequestFromJSON,
    GettingInstancesRequestToJSON,
    QVIWorkflowResponseDtoFromJSON,
    QVIWorkflowResponseDtoToJSON,
    WorkflowInstanceDtoFromJSON,
    WorkflowInstanceDtoToJSON,
} from '../models/index';

export interface CancelInstanceRequest {
    workflowInstanceId: string;
}

export interface CompleteInstanceRequest {
    workflowInstanceId: string;
}

export interface FindAllForQVIRequest {
    orgId: string;
    gettingInstancesRequest: GettingInstancesRequest;
}

export interface FindAllForRequesterRequest {
    status?: string;
    withTasks?: boolean;
}

export interface FindAllForRequesterAndOrgRequest {
    orgId: string;
    status?: string;
    withTasks?: boolean;
}

export interface FindOneRequest {
    workflowInstanceId: string;
    withTasks?: boolean;
}

/**
 * 
 */
export class WorkflowInstanceControllerApi extends runtime.BaseAPI {

    /**
     * Cancel the workflow instance
     */
    async cancelInstanceRaw(requestParameters: CancelInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['workflowInstanceId'] == null) {
            throw new runtime.RequiredError(
                'workflowInstanceId',
                'Required parameter "workflowInstanceId" was null or undefined when calling cancelInstance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/workflow-instance/{workflow_instance_id}/cancel`.replace(`{${"workflow_instance_id"}}`, encodeURIComponent(String(requestParameters['workflowInstanceId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Cancel the workflow instance
     */
    async cancelInstance(requestParameters: CancelInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.cancelInstanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Complete the workflow instance
     */
    async completeInstanceRaw(requestParameters: CompleteInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['workflowInstanceId'] == null) {
            throw new runtime.RequiredError(
                'workflowInstanceId',
                'Required parameter "workflowInstanceId" was null or undefined when calling completeInstance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/workflow-instance/{workflow_instance_id}/completion`.replace(`{${"workflow_instance_id"}}`, encodeURIComponent(String(requestParameters['workflowInstanceId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Complete the workflow instance
     */
    async completeInstance(requestParameters: CompleteInstanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.completeInstanceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Parameter orgId is org id for QVI organization
     * Get all workflow instances associated to QARs for the specified QVI.
     */
    async findAllForQVIRaw(requestParameters: FindAllForQVIRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QVIWorkflowResponseDto>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling findAllForQVI().'
            );
        }

        if (requestParameters['gettingInstancesRequest'] == null) {
            throw new runtime.RequiredError(
                'gettingInstancesRequest',
                'Required parameter "gettingInstancesRequest" was null or undefined when calling findAllForQVI().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/workflow-instance/qvi/{org-id}`.replace(`{${"org-id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GettingInstancesRequestToJSON(requestParameters['gettingInstancesRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QVIWorkflowResponseDtoFromJSON(jsonValue));
    }

    /**
     * Parameter orgId is org id for QVI organization
     * Get all workflow instances associated to QARs for the specified QVI.
     */
    async findAllForQVI(requestParameters: FindAllForQVIRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QVIWorkflowResponseDto> {
        const response = await this.findAllForQVIRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * If query param \"status\" is not used, will return all instances
     * Get all workflow instances associated to a user.
     */
    async findAllForRequesterRaw(requestParameters: FindAllForRequesterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkflowInstanceDto>>> {
        const queryParameters: any = {};

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['withTasks'] != null) {
            queryParameters['with-tasks'] = requestParameters['withTasks'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/workflow-instance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkflowInstanceDtoFromJSON));
    }

    /**
     * If query param \"status\" is not used, will return all instances
     * Get all workflow instances associated to a user.
     */
    async findAllForRequester(requestParameters: FindAllForRequesterRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkflowInstanceDto> | null | undefined > {
        const response = await this.findAllForRequesterRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * If query param \"status\" is not used, will return all instances
     * Get all workflow instances associated to a user and for the specified org.
     */
    async findAllForRequesterAndOrgRaw(requestParameters: FindAllForRequesterAndOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WorkflowInstanceDto>>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling findAllForRequesterAndOrg().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        if (requestParameters['withTasks'] != null) {
            queryParameters['with-tasks'] = requestParameters['withTasks'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/workflow-instance/org/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WorkflowInstanceDtoFromJSON));
    }

    /**
     * If query param \"status\" is not used, will return all instances
     * Get all workflow instances associated to a user and for the specified org.
     */
    async findAllForRequesterAndOrg(requestParameters: FindAllForRequesterAndOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WorkflowInstanceDto> | null | undefined > {
        const response = await this.findAllForRequesterAndOrgRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get specific workflow instance detail, its tasks
     */
    async findOneRaw(requestParameters: FindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkflowInstanceDto>> {
        if (requestParameters['workflowInstanceId'] == null) {
            throw new runtime.RequiredError(
                'workflowInstanceId',
                'Required parameter "workflowInstanceId" was null or undefined when calling findOne().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTasks'] != null) {
            queryParameters['with-tasks'] = requestParameters['withTasks'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/workflow-instance/{workflow_instance_id}`.replace(`{${"workflow_instance_id"}}`, encodeURIComponent(String(requestParameters['workflowInstanceId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowInstanceDtoFromJSON(jsonValue));
    }

    /**
     * Get specific workflow instance detail, its tasks
     */
    async findOne(requestParameters: FindOneRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkflowInstanceDto | null | undefined > {
        const response = await this.findOneRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
