import CLink from '@/components/base/CLink'
import Provenant from '@/components/icons/Provenant'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import Link from 'next/link'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import NextNProgress from 'nextjs-progressbar'
import tailwindConfig from 'tailwind.config'

interface IProps {
  appProps: AppProps
}

const PublicPage = ({ appProps: { Component, pageProps } }: IProps) => {
  const [year] = useState(new Date().getFullYear())
  const { formatMessage } = useIntl()

  return (
    <>
      <Head>
        <title>{formatMessage({ id: 'page.public.title' })}</title>
      </Head>
      <NextNProgress
        color={tailwindConfig.theme.extend.colors.white}
        options={{ showSpinner: false }}
      />

      <div className="flex flex-col min-h-screen bg-bg">
        <section
          className="flex flex-col justify-center px-5 font-bold bg-royalBlue"
          style={{ height: '60px' }}
        >
          <Link href={'/'}>
            <Provenant />
          </Link>
        </section>
        <section className="grow ">
          <div className="grid grid-cols-1 px-3 mt-10 md:grid-cols-6">
            <div className="px-3 md:col-start-2 md:col-end-6 lg:col-start-3 lg:col-end-5">
              <Component {...pageProps} />
            </div>
          </div>
        </section>

        <footer className="p-5 text-center text-neutralsAdditional800">
          <a
            className="text-neutralsAdditional800 border-b-neutralsAdditional800 visited:border-b-neutralsProductInkNormal900 visited:text-neutralsProductInkNormal900 focus:border-b-neutralsProductInkNormal900 focus:text-neutralsProductInkNormal900 hover:border-b-neutralsProductInkNormal900 hover:text-neutralsProductInkNormal900 focus:border-b-neutralsProductInkNormal900 focus:text-neutralsProductInkNormal900 hover:border-b-primarySupportBluePressed hover:text-primarySupportBluePressed underline decoration-dashed underline-offset-[5px] decoration-1 tansition-all duration-700 hover:text-blue-600 active:text-royalBlue "
            href={formatMessage({ id: 'app.termsAndConditions.link' })}
            target="_blank"
            rel="noreferrer"
          >
            {formatMessage({ id: 'page.generic.termsAndConditions' })}{' '}
          </a>
          &nbsp;&nbsp;
          <CLink
            color="grey"
            href={'#'}
            className="border-b border-dashed text-neutralsAdditional800 border-b-neutralsAdditional800"
          >
            {formatMessage({ id: 'page.generic.contact' })}
          </CLink>
          &nbsp; &copy; {year} {formatMessage({ id: 'page.generic.copyright' })}
        </footer>
      </div>
    </>
  )
}

export default PublicPage
