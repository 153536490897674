export function blindUUID(uuid: string): string {
  // when we send the uuid to either logger or to sentry
  // we should blind the uuid to protect the user's privacy
  // and also to satisfy the GDPR requirements

  if (uuid.length !== 36) {
    return uuid
  }

  const start = uuid.slice(0, 12)
  const end = uuid.slice(-12)
  return `${start}...${end}`
}

export function blindString(input: string): string {
  const length = input.length

  if (length <= 1) {
    return input
  }

  if (length === 2) {
    return input[0] + '*'
  }

  if (length === 3) {
    return input[0] + '*' + input[2]
  }

  if (length === 4) {
    return input.slice(0, 2) + '**'
  }

  const start = input.slice(0, 2)
  const end = input.slice(-2)
  const middle = '*'.repeat(length - 4)

  return `${start}${middle}${end}`
}
