/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SortingItem } from './SortingItem';
import {
    SortingItemFromJSON,
    SortingItemFromJSONTyped,
    SortingItemToJSON,
} from './SortingItem';

/**
 * 
 * @export
 * @interface GettingInstancesRequest
 */
export interface GettingInstancesRequest {
    /**
     * 
     * @type {string}
     * @memberof GettingInstancesRequest
     */
    fuzzySearch?: string;
    /**
     * Instance filters
     * @type {{ [key: string]: string; }}
     * @memberof GettingInstancesRequest
     */
    instanceFilters?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof GettingInstancesRequest
     */
    activeStatuses?: boolean;
    /**
     * Interval for started at parameter. If any bound is not defined, is is not used. Can have null in first or second item.
     * @type {Array<string>}
     * @memberof GettingInstancesRequest
     */
    startedAtInterval?: Array<string>;
    /**
     * 
     * @type {Array<SortingItem>}
     * @memberof GettingInstancesRequest
     */
    sortingItems?: Array<SortingItem>;
    /**
     * 
     * @type {number}
     * @memberof GettingInstancesRequest
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof GettingInstancesRequest
     */
    limit?: number;
}

/**
 * Check if a given object implements the GettingInstancesRequest interface.
 */
export function instanceOfGettingInstancesRequest(value: object): boolean {
    return true;
}

export function GettingInstancesRequestFromJSON(json: any): GettingInstancesRequest {
    return GettingInstancesRequestFromJSONTyped(json, false);
}

export function GettingInstancesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GettingInstancesRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'fuzzySearch': json['fuzzySearch'] == null ? undefined : json['fuzzySearch'],
        'instanceFilters': json['instanceFilters'] == null ? undefined : json['instanceFilters'],
        'activeStatuses': json['activeStatuses'] == null ? undefined : json['activeStatuses'],
        'startedAtInterval': json['startedAtInterval'] == null ? undefined : json['startedAtInterval'],
        'sortingItems': json['sortingItems'] == null ? undefined : ((json['sortingItems'] as Array<any>).map(SortingItemFromJSON)),
        'offset': json['offset'] == null ? undefined : json['offset'],
        'limit': json['limit'] == null ? undefined : json['limit'],
    };
}

export function GettingInstancesRequestToJSON(value?: GettingInstancesRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fuzzySearch': value['fuzzySearch'],
        'instanceFilters': value['instanceFilters'],
        'activeStatuses': value['activeStatuses'],
        'startedAtInterval': value['startedAtInterval'],
        'sortingItems': value['sortingItems'] == null ? undefined : ((value['sortingItems'] as Array<any>).map(SortingItemToJSON)),
        'offset': value['offset'],
        'limit': value['limit'],
    };
}

