/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdditionalPublicRecordsRequestDto,
  CancelCeremonyRequestDto,
  CommonResponseDto,
  DarClarificationRequestDto,
  DarProposalRequestDto,
  DarReplacementRequestDto,
  DarResponseDto,
  LarProposalRequestDto,
  LarRemovalRequestDto,
  LarsResponseDto,
  OrgFillingDetailRequestDto,
  QarAcceptsDarRequestDto,
  QarAcceptsOrgRequestDto,
  QarRejectsDarRequestDto,
  RemovalResponseDto,
  ResentNotificationRequestDto,
  ResentNotificationResponseDto,
  ResponseDto,
  ScheduleCeremonyVLEIRequestDto,
  VLEIPrerequisitesRequestDto,
  WorkflowExecutedResponseDto,
} from '../models/index';
import {
    AdditionalPublicRecordsRequestDtoFromJSON,
    AdditionalPublicRecordsRequestDtoToJSON,
    CancelCeremonyRequestDtoFromJSON,
    CancelCeremonyRequestDtoToJSON,
    CommonResponseDtoFromJSON,
    CommonResponseDtoToJSON,
    DarClarificationRequestDtoFromJSON,
    DarClarificationRequestDtoToJSON,
    DarProposalRequestDtoFromJSON,
    DarProposalRequestDtoToJSON,
    DarReplacementRequestDtoFromJSON,
    DarReplacementRequestDtoToJSON,
    DarResponseDtoFromJSON,
    DarResponseDtoToJSON,
    LarProposalRequestDtoFromJSON,
    LarProposalRequestDtoToJSON,
    LarRemovalRequestDtoFromJSON,
    LarRemovalRequestDtoToJSON,
    LarsResponseDtoFromJSON,
    LarsResponseDtoToJSON,
    OrgFillingDetailRequestDtoFromJSON,
    OrgFillingDetailRequestDtoToJSON,
    QarAcceptsDarRequestDtoFromJSON,
    QarAcceptsDarRequestDtoToJSON,
    QarAcceptsOrgRequestDtoFromJSON,
    QarAcceptsOrgRequestDtoToJSON,
    QarRejectsDarRequestDtoFromJSON,
    QarRejectsDarRequestDtoToJSON,
    RemovalResponseDtoFromJSON,
    RemovalResponseDtoToJSON,
    ResentNotificationRequestDtoFromJSON,
    ResentNotificationRequestDtoToJSON,
    ResentNotificationResponseDtoFromJSON,
    ResentNotificationResponseDtoToJSON,
    ResponseDtoFromJSON,
    ResponseDtoToJSON,
    ScheduleCeremonyVLEIRequestDtoFromJSON,
    ScheduleCeremonyVLEIRequestDtoToJSON,
    VLEIPrerequisitesRequestDtoFromJSON,
    VLEIPrerequisitesRequestDtoToJSON,
    WorkflowExecutedResponseDtoFromJSON,
    WorkflowExecutedResponseDtoToJSON,
} from '../models/index';

export interface AddPublicRecords1Request {
    additionalPublicRecordsRequestDto: AdditionalPublicRecordsRequestDto;
}

export interface CancelCeremony1Request {
    cancelCeremonyRequestDto: CancelCeremonyRequestDto;
}

export interface DarProposalRequest {
    darProposalRequestDto: DarProposalRequestDto;
}

export interface DarReplacementRequest {
    darReplacementRequestDto: DarReplacementRequestDto;
}

export interface FindActiveLarRecipientsRequest {
    workflow: string;
}

export interface FindDarRecipientRequest {
    workflow: string;
}

export interface LarProposalRequest {
    larProposalRequestDto: LarProposalRequestDto;
}

export interface LarRemovalRequest {
    larRemovalRequestDto: LarRemovalRequestDto;
}

export interface OrgFillingDetailRequest {
    orgFillingDetailRequestDto: OrgFillingDetailRequestDto;
}

export interface QarAcceptsDarRequest {
    qarAcceptsDarRequestDto: QarAcceptsDarRequestDto;
}

export interface QarApprovesOrgParametersRequest {
    qarAcceptsOrgRequestDto: QarAcceptsOrgRequestDto;
}

export interface QarRejectsDarRequest {
    qarRejectsDarRequestDto: QarRejectsDarRequestDto;
}

export interface QarRequestsToClarifyDarRequest {
    darClarificationRequestDto: DarClarificationRequestDto;
}

export interface ResendNotificationToLarRequest {
    resentNotificationRequestDto: ResentNotificationRequestDto;
}

export interface ScheduleCeremony1Request {
    scheduleCeremonyVLEIRequestDto: ScheduleCeremonyVLEIRequestDto;
}

export interface Start1Request {
    vLEIPrerequisitesRequestDto: VLEIPrerequisitesRequestDto;
}

/**
 * 
 */
export class VleiPrerequisitesControllerApi extends runtime.BaseAPI {

    /**
     * QAR adds Org filing detail and public record links
     */
    async addPublicRecords1Raw(requestParameters: AddPublicRecords1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['additionalPublicRecordsRequestDto'] == null) {
            throw new runtime.RequiredError(
                'additionalPublicRecordsRequestDto',
                'Required parameter "additionalPublicRecordsRequestDto" was null or undefined when calling addPublicRecords1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/additional-public-records`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdditionalPublicRecordsRequestDtoToJSON(requestParameters['additionalPublicRecordsRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * QAR adds Org filing detail and public record links
     */
    async addPublicRecords1(requestParameters: AddPublicRecords1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.addPublicRecords1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancels scheduled ceremony
     */
    async cancelCeremony1Raw(requestParameters: CancelCeremony1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommonResponseDto>> {
        if (requestParameters['cancelCeremonyRequestDto'] == null) {
            throw new runtime.RequiredError(
                'cancelCeremonyRequestDto',
                'Required parameter "cancelCeremonyRequestDto" was null or undefined when calling cancelCeremony1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/cancel-ceremony`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelCeremonyRequestDtoToJSON(requestParameters['cancelCeremonyRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommonResponseDtoFromJSON(jsonValue));
    }

    /**
     * Cancels scheduled ceremony
     */
    async cancelCeremony1(requestParameters: CancelCeremony1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommonResponseDto> {
        const response = await this.cancelCeremony1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Propose DAR
     */
    async darProposalRaw(requestParameters: DarProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DarProposalRequestDto>> {
        if (requestParameters['darProposalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'darProposalRequestDto',
                'Required parameter "darProposalRequestDto" was null or undefined when calling darProposal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/dar-proposal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DarProposalRequestDtoToJSON(requestParameters['darProposalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DarProposalRequestDtoFromJSON(jsonValue));
    }

    /**
     * Propose DAR
     */
    async darProposal(requestParameters: DarProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DarProposalRequestDto> {
        const response = await this.darProposalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * QAR replace DAR
     */
    async darReplacementRaw(requestParameters: DarReplacementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DarProposalRequestDto>> {
        if (requestParameters['darReplacementRequestDto'] == null) {
            throw new runtime.RequiredError(
                'darReplacementRequestDto',
                'Required parameter "darReplacementRequestDto" was null or undefined when calling darReplacement().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/dar-replacement`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DarReplacementRequestDtoToJSON(requestParameters['darReplacementRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DarProposalRequestDtoFromJSON(jsonValue));
    }

    /**
     * QAR replace DAR
     */
    async darReplacement(requestParameters: DarReplacementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DarProposalRequestDto> {
        const response = await this.darReplacementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get active LAR recipients user id
     */
    async findActiveLarRecipientsRaw(requestParameters: FindActiveLarRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LarsResponseDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling findActiveLarRecipients().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/lars`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LarsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get active LAR recipients user id
     */
    async findActiveLarRecipients(requestParameters: FindActiveLarRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LarsResponseDto> {
        const response = await this.findActiveLarRecipientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get DAR recipient user id
     */
    async findDarRecipientRaw(requestParameters: FindDarRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DarResponseDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling findDarRecipient().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/dar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DarResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get DAR recipient user id
     */
    async findDarRecipient(requestParameters: FindDarRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DarResponseDto> {
        const response = await this.findDarRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * DAR proposes LARs
     */
    async larProposalRaw(requestParameters: LarProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LarProposalRequestDto>> {
        if (requestParameters['larProposalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'larProposalRequestDto',
                'Required parameter "larProposalRequestDto" was null or undefined when calling larProposal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/lar-proposal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LarProposalRequestDtoToJSON(requestParameters['larProposalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LarProposalRequestDtoFromJSON(jsonValue));
    }

    /**
     * DAR proposes LARs
     */
    async larProposal(requestParameters: LarProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LarProposalRequestDto> {
        const response = await this.larProposalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove one of the lars
     */
    async larRemovalRaw(requestParameters: LarRemovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RemovalResponseDto>> {
        if (requestParameters['larRemovalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'larRemovalRequestDto',
                'Required parameter "larRemovalRequestDto" was null or undefined when calling larRemoval().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/lar-removal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LarRemovalRequestDtoToJSON(requestParameters['larRemovalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RemovalResponseDtoFromJSON(jsonValue));
    }

    /**
     * Remove one of the lars
     */
    async larRemoval(requestParameters: LarRemovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RemovalResponseDto> {
        const response = await this.larRemovalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Org filing detail and public record links
     */
    async orgFillingDetailRaw(requestParameters: OrgFillingDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgFillingDetailRequestDto>> {
        if (requestParameters['orgFillingDetailRequestDto'] == null) {
            throw new runtime.RequiredError(
                'orgFillingDetailRequestDto',
                'Required parameter "orgFillingDetailRequestDto" was null or undefined when calling orgFillingDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/org-filing-detail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrgFillingDetailRequestDtoToJSON(requestParameters['orgFillingDetailRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgFillingDetailRequestDtoFromJSON(jsonValue));
    }

    /**
     * Update Org filing detail and public record links
     */
    async orgFillingDetail(requestParameters: OrgFillingDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgFillingDetailRequestDto> {
        const response = await this.orgFillingDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * QAR approves proposed DAR
     */
    async qarAcceptsDarRaw(requestParameters: QarAcceptsDarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QarAcceptsDarRequestDto>> {
        if (requestParameters['qarAcceptsDarRequestDto'] == null) {
            throw new runtime.RequiredError(
                'qarAcceptsDarRequestDto',
                'Required parameter "qarAcceptsDarRequestDto" was null or undefined when calling qarAcceptsDar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/dar-approve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QarAcceptsDarRequestDtoToJSON(requestParameters['qarAcceptsDarRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QarAcceptsDarRequestDtoFromJSON(jsonValue));
    }

    /**
     * QAR approves proposed DAR
     */
    async qarAcceptsDar(requestParameters: QarAcceptsDarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QarAcceptsDarRequestDto> {
        const response = await this.qarAcceptsDarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * QAR accepts org parameters
     */
    async qarApprovesOrgParametersRaw(requestParameters: QarApprovesOrgParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['qarAcceptsOrgRequestDto'] == null) {
            throw new runtime.RequiredError(
                'qarAcceptsOrgRequestDto',
                'Required parameter "qarAcceptsOrgRequestDto" was null or undefined when calling qarApprovesOrgParameters().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/org-approve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QarAcceptsOrgRequestDtoToJSON(requestParameters['qarAcceptsOrgRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * QAR accepts org parameters
     */
    async qarApprovesOrgParameters(requestParameters: QarApprovesOrgParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.qarApprovesOrgParametersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deprecated, use /dar-clarification instead
     * QAR rejects proposed DAR
     */
    async qarRejectsDarRaw(requestParameters: QarRejectsDarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QarRejectsDarRequestDto>> {
        if (requestParameters['qarRejectsDarRequestDto'] == null) {
            throw new runtime.RequiredError(
                'qarRejectsDarRequestDto',
                'Required parameter "qarRejectsDarRequestDto" was null or undefined when calling qarRejectsDar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/dar-rejection`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QarRejectsDarRequestDtoToJSON(requestParameters['qarRejectsDarRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QarRejectsDarRequestDtoFromJSON(jsonValue));
    }

    /**
     * Deprecated, use /dar-clarification instead
     * QAR rejects proposed DAR
     */
    async qarRejectsDar(requestParameters: QarRejectsDarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QarRejectsDarRequestDto> {
        const response = await this.qarRejectsDarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * QAR rejects proposed DAR
     */
    async qarRequestsToClarifyDarRaw(requestParameters: QarRequestsToClarifyDarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DarClarificationRequestDto>> {
        if (requestParameters['darClarificationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'darClarificationRequestDto',
                'Required parameter "darClarificationRequestDto" was null or undefined when calling qarRequestsToClarifyDar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/dar-clarification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DarClarificationRequestDtoToJSON(requestParameters['darClarificationRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DarClarificationRequestDtoFromJSON(jsonValue));
    }

    /**
     * QAR rejects proposed DAR
     */
    async qarRequestsToClarifyDar(requestParameters: QarRequestsToClarifyDarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DarClarificationRequestDto> {
        const response = await this.qarRequestsToClarifyDarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend the notification to lar
     */
    async resendNotificationToLarRaw(requestParameters: ResendNotificationToLarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResentNotificationResponseDto>> {
        if (requestParameters['resentNotificationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'resentNotificationRequestDto',
                'Required parameter "resentNotificationRequestDto" was null or undefined when calling resendNotificationToLar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/resent-notification-to-lar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResentNotificationRequestDtoToJSON(requestParameters['resentNotificationRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResentNotificationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Resend the notification to lar
     */
    async resendNotificationToLar(requestParameters: ResendNotificationToLarRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResentNotificationResponseDto> {
        const response = await this.resendNotificationToLarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Schedule issuance ceremony as per selected time slot
     */
    async scheduleCeremony1Raw(requestParameters: ScheduleCeremony1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduleCeremonyVLEIRequestDto>> {
        if (requestParameters['scheduleCeremonyVLEIRequestDto'] == null) {
            throw new runtime.RequiredError(
                'scheduleCeremonyVLEIRequestDto',
                'Required parameter "scheduleCeremonyVLEIRequestDto" was null or undefined when calling scheduleCeremony1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/scheduling-ceremony`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleCeremonyVLEIRequestDtoToJSON(requestParameters['scheduleCeremonyVLEIRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleCeremonyVLEIRequestDtoFromJSON(jsonValue));
    }

    /**
     * Schedule issuance ceremony as per selected time slot
     */
    async scheduleCeremony1(requestParameters: ScheduleCeremony1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduleCeremonyVLEIRequestDto> {
        const response = await this.scheduleCeremony1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Execute node from existing workflow execution
     * Create and start a new Request LE VLEIPrerequisites Workflow Instance
     */
    async start1Raw(requestParameters: Start1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkflowExecutedResponseDto>> {
        if (requestParameters['vLEIPrerequisitesRequestDto'] == null) {
            throw new runtime.RequiredError(
                'vLEIPrerequisitesRequestDto',
                'Required parameter "vLEIPrerequisitesRequestDto" was null or undefined when calling start1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/le-vlei-prereq/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VLEIPrerequisitesRequestDtoToJSON(requestParameters['vLEIPrerequisitesRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowExecutedResponseDtoFromJSON(jsonValue));
    }

    /**
     * Execute node from existing workflow execution
     * Create and start a new Request LE VLEIPrerequisites Workflow Instance
     */
    async start1(requestParameters: Start1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkflowExecutedResponseDto> {
        const response = await this.start1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
