import { StateCreator } from 'zustand'
import { IStore } from './store'
import { OrgDetailResponseDto } from '@/api/origin-workflow-svc'
import { produce } from 'immer'

export interface IOrganization {
  orgDetails: OrgDetailResponseDto & { workflowInstanceId?: string }
  setOrgDetails: (
    details: OrgDetailResponseDto,
    workflowInstanceId: string
  ) => void
  resetOrganizationDetailsStore: () => void
}

const initialState = {
  orgDetails: {}
}

const organizationDetailsStore: StateCreator<IStore, [], [], IOrganization> = (
  set,
  get
) => ({
  ...initialState,
  setOrgDetails: (details, workflowInstanceId) => {
    set(
      produce((state: IOrganization) => {
        state.orgDetails = { ...details, workflowInstanceId }
      })
    )
  },
  resetOrganizationDetailsStore: () => {
    set(initialState)
  }
})

export default organizationDetailsStore
