/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DarClarificationRequestDto
 */
export interface DarClarificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DarClarificationRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof DarClarificationRequestDto
     */
    rejectedDarId: string;
    /**
     * 
     * @type {string}
     * @memberof DarClarificationRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof DarClarificationRequestDto
     */
    note: string;
}

/**
 * Check if a given object implements the DarClarificationRequestDto interface.
 */
export function instanceOfDarClarificationRequestDto(value: object): boolean {
    if (!('orgId' in value)) return false;
    if (!('rejectedDarId' in value)) return false;
    if (!('workflowInstanceId' in value)) return false;
    if (!('note' in value)) return false;
    return true;
}

export function DarClarificationRequestDtoFromJSON(json: any): DarClarificationRequestDto {
    return DarClarificationRequestDtoFromJSONTyped(json, false);
}

export function DarClarificationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DarClarificationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'orgId': json['orgId'],
        'rejectedDarId': json['rejectedDarId'],
        'workflowInstanceId': json['workflowInstanceId'],
        'note': json['note'],
    };
}

export function DarClarificationRequestDtoToJSON(value?: DarClarificationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orgId': value['orgId'],
        'rejectedDarId': value['rejectedDarId'],
        'workflowInstanceId': value['workflowInstanceId'],
        'note': value['note'],
    };
}

