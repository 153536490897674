import React from 'react'

interface IProps {
  size?: number
  mode?: 'success' | 'normal'
  className?: string
  color?: string
}

function VerifyRoundIcon({
  size = 16,
  mode = 'normal',
  className = '',
  color
}: IProps) {
  return (
    <span className={className}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {!color && mode === 'normal' && (
          <path
            d="M7.02 9.26L5.4975 7.7375C5.36917 7.60917 5.20887 7.54803 5.0166 7.5541C4.82387 7.5597 4.66333 7.62667 4.535 7.755C4.40667 7.88333 4.3425 8.04667 4.3425 8.245C4.3425 8.44333 4.40667 8.60667 4.535 8.735L6.53 10.73C6.65833 10.8583 6.82167 10.9225 7.02 10.9225C7.21833 10.9225 7.38167 10.8583 7.51 10.73L11.4825 6.7575C11.6108 6.62917 11.6722 6.46863 11.6666 6.2759C11.6605 6.08363 11.5933 5.92333 11.465 5.795C11.3367 5.66667 11.1733 5.6025 10.975 5.6025C10.7767 5.6025 10.6133 5.66667 10.485 5.795L7.02 9.26ZM8 15C7.03167 15 6.12167 14.8161 5.27 14.4484C4.41833 14.0811 3.6775 13.5825 3.0475 12.9525C2.4175 12.3225 1.91887 11.5817 1.5516 10.73C1.18387 9.87833 1 8.96833 1 8C1 7.03167 1.18387 6.12167 1.5516 5.27C1.91887 4.41833 2.4175 3.6775 3.0475 3.0475C3.6775 2.4175 4.41833 1.91863 5.27 1.5509C6.12167 1.18363 7.03167 1 8 1C8.96833 1 9.87833 1.18363 10.73 1.5509C11.5817 1.91863 12.3225 2.4175 12.9525 3.0475C13.5825 3.6775 14.0811 4.41833 14.4484 5.27C14.8161 6.12167 15 7.03167 15 8C15 8.96833 14.8161 9.87833 14.4484 10.73C14.0811 11.5817 13.5825 12.3225 12.9525 12.9525C12.3225 13.5825 11.5817 14.0811 10.73 14.4484C9.87833 14.8161 8.96833 15 8 15ZM8 13.6C9.55167 13.6 10.873 13.0547 11.9641 11.9641C13.0547 10.873 13.6 9.55167 13.6 8C13.6 6.44833 13.0547 5.12697 11.9641 4.0359C10.873 2.9453 9.55167 2.4 8 2.4C6.44833 2.4 5.1272 2.9453 4.0366 4.0359C2.94553 5.12697 2.4 6.44833 2.4 8C2.4 9.55167 2.94553 10.873 4.0366 11.9641C5.1272 13.0547 6.44833 13.6 8 13.6Z"
            fill="#202020"
          />
        )}

        {!color && mode === 'success' && (
          <path
            d="M7.02 9.26L5.4975 7.7375C5.36917 7.60917 5.21167 7.545 5.025 7.545C4.83833 7.545 4.675 7.615 4.535 7.755C4.40667 7.88333 4.3425 8.04667 4.3425 8.245C4.3425 8.44333 4.40667 8.60667 4.535 8.735L6.53 10.73C6.65833 10.8583 6.82167 10.9225 7.02 10.9225C7.21833 10.9225 7.38167 10.8583 7.51 10.73L11.4825 6.7575C11.6108 6.62917 11.675 6.47167 11.675 6.285C11.675 6.09833 11.605 5.935 11.465 5.795C11.3367 5.66667 11.1733 5.6025 10.975 5.6025C10.7767 5.6025 10.6133 5.66667 10.485 5.795L7.02 9.26ZM8 15C7.03167 15 6.12167 14.8161 5.27 14.4484C4.41833 14.0811 3.6775 13.5825 3.0475 12.9525C2.4175 12.3225 1.91887 11.5817 1.5516 10.73C1.18387 9.87833 1 8.96833 1 8C1 7.03167 1.18387 6.12167 1.5516 5.27C1.91887 4.41833 2.4175 3.6775 3.0475 3.0475C3.6775 2.4175 4.41833 1.91863 5.27 1.5509C6.12167 1.18363 7.03167 1 8 1C8.96833 1 9.87833 1.18363 10.73 1.5509C11.5817 1.91863 12.3225 2.4175 12.9525 3.0475C13.5825 3.6775 14.0811 4.41833 14.4484 5.27C14.8161 6.12167 15 7.03167 15 8C15 8.96833 14.8161 9.87833 14.4484 10.73C14.0811 11.5817 13.5825 12.3225 12.9525 12.9525C12.3225 13.5825 11.5817 14.0811 10.73 14.4484C9.87833 14.8161 8.96833 15 8 15Z"
            fill="#1F7C19"
          />
        )}

        {color && (
          <path
            d="M7.02 9.26L5.4975 7.7375C5.36917 7.60917 5.21167 7.545 5.025 7.545C4.83833 7.545 4.675 7.615 4.535 7.755C4.40667 7.88333 4.3425 8.04667 4.3425 8.245C4.3425 8.44333 4.40667 8.60667 4.535 8.735L6.53 10.73C6.65833 10.8583 6.82167 10.9225 7.02 10.9225C7.21833 10.9225 7.38167 10.8583 7.51 10.73L11.4825 6.7575C11.6108 6.62917 11.675 6.47167 11.675 6.285C11.675 6.09833 11.605 5.935 11.465 5.795C11.3367 5.66667 11.1733 5.6025 10.975 5.6025C10.7767 5.6025 10.6133 5.66667 10.485 5.795L7.02 9.26ZM8 15C7.03167 15 6.12167 14.8161 5.27 14.4484C4.41833 14.0811 3.6775 13.5825 3.0475 12.9525C2.4175 12.3225 1.91887 11.5817 1.5516 10.73C1.18387 9.87833 1 8.96833 1 8C1 7.03167 1.18387 6.12167 1.5516 5.27C1.91887 4.41833 2.4175 3.6775 3.0475 3.0475C3.6775 2.4175 4.41833 1.91863 5.27 1.5509C6.12167 1.18363 7.03167 1 8 1C8.96833 1 9.87833 1.18363 10.73 1.5509C11.5817 1.91863 12.3225 2.4175 12.9525 3.0475C13.5825 3.6775 14.0811 4.41833 14.4484 5.27C14.8161 6.12167 15 7.03167 15 8C15 8.96833 14.8161 9.87833 14.4484 10.73C14.0811 11.5817 13.5825 12.3225 12.9525 12.9525C12.3225 13.5825 11.5817 14.0811 10.73 14.4484C9.87833 14.8161 8.96833 15 8 15Z"
            fill={color}
          />
        )}
      </svg>
    </span>
  )
}

export default VerifyRoundIcon
