import React from 'react'

interface IProps extends React.HTMLAttributes<HTMLSpanElement> {
  type?: 'SPAN' | 'PARAGRAPH'
  className?: string
}

const Error = ({ className, children, type = 'SPAN', ...props }: IProps) => {
  if (type === 'SPAN')
    return (
      <span className={`text-SecondaryRed900 text-sm ${className}`} {...props}>
        {children}
      </span>
    )
  else
    return (
      <p className="text-SecondaryRed900 text-sm mt-[2px]" {...props}>
        {children}
      </p>
    )
}

export default Error
