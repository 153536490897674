/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProposedUserDto } from './ProposedUserDto';
import {
    ProposedUserDtoFromJSON,
    ProposedUserDtoFromJSONTyped,
    ProposedUserDtoToJSON,
} from './ProposedUserDto';

/**
 * 
 * @export
 * @interface LarProposalRequestDto
 */
export interface LarProposalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof LarProposalRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof LarProposalRequestDto
     */
    workflowInstanceAssignedTaskId?: string;
    /**
     * 
     * @type {string}
     * @memberof LarProposalRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {Array<ProposedUserDto>}
     * @memberof LarProposalRequestDto
     */
    proposedUsers?: Array<ProposedUserDto>;
}

/**
 * Check if a given object implements the LarProposalRequestDto interface.
 */
export function instanceOfLarProposalRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('orgId' in value)) return false;
    return true;
}

export function LarProposalRequestDtoFromJSON(json: any): LarProposalRequestDto {
    return LarProposalRequestDtoFromJSONTyped(json, false);
}

export function LarProposalRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LarProposalRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': json['workflowInstanceAssignedTaskId'] == null ? undefined : json['workflowInstanceAssignedTaskId'],
        'orgId': json['orgId'],
        'proposedUsers': json['proposedUsers'] == null ? undefined : ((json['proposedUsers'] as Array<any>).map(ProposedUserDtoFromJSON)),
    };
}

export function LarProposalRequestDtoToJSON(value?: LarProposalRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': value['workflowInstanceAssignedTaskId'],
        'orgId': value['orgId'],
        'proposedUsers': value['proposedUsers'] == null ? undefined : ((value['proposedUsers'] as Array<any>).map(ProposedUserDtoToJSON)),
    };
}

