enum DefaultIdentifierIntendedRole {
  QVI = 'qvi',
  LE = 'le', // legal-entity
  QAR = 'qar',
  LAR = 'lar',
  DAR = 'dar',
  EMPLOYEE = 'employee',
  ORIGIN_USER = 'origin-user'
}

export default DefaultIdentifierIntendedRole
