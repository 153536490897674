/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserVerificationProcess
 */
export interface UserVerificationProcess {
    /**
     * 
     * @type {string}
     * @memberof UserVerificationProcess
     */
    verificationTransactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationProcess
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationProcess
     */
    workflowInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationProcess
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserVerificationProcess
     */
    status?: string;
}

/**
 * Check if a given object implements the UserVerificationProcess interface.
 */
export function instanceOfUserVerificationProcess(value: object): boolean {
    return true;
}

export function UserVerificationProcessFromJSON(json: any): UserVerificationProcess {
    return UserVerificationProcessFromJSONTyped(json, false);
}

export function UserVerificationProcessFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserVerificationProcess {
    if (json == null) {
        return json;
    }
    return {
        
        'verificationTransactionId': json['verificationTransactionId'] == null ? undefined : json['verificationTransactionId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function UserVerificationProcessToJSON(value?: UserVerificationProcess | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'verificationTransactionId': value['verificationTransactionId'],
        'userId': value['userId'],
        'workflowInstanceId': value['workflowInstanceId'],
        'orgId': value['orgId'],
        'status': value['status'],
    };
}

