/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LeiTestStatus,
} from '../models/index';
import {
    LeiTestStatusFromJSON,
    LeiTestStatusToJSON,
} from '../models/index';

export interface UpdateTestGLEIFClientBehaviourRequest {
    lei: string;
    leiTestStatus: LeiTestStatus;
}

export interface UpdateTestGLEIFClientBehaviour1Request {
    body: string;
}

/**
 * 
 */
export class GleifApiTestControllerApi extends runtime.BaseAPI {

    /**
     */
    async cleanTestGLEIFClientBehaviourRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/.private/gleif/behaviour`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async cleanTestGLEIFClientBehaviour(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.cleanTestGLEIFClientBehaviourRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async clearLeiStatusesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/.private/gleif`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async clearLeiStatuses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.clearLeiStatusesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTestGLEIFClientBehaviourRaw(requestParameters: UpdateTestGLEIFClientBehaviourRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['lei'] == null) {
            throw new runtime.RequiredError(
                'lei',
                'Required parameter "lei" was null or undefined when calling updateTestGLEIFClientBehaviour().'
            );
        }

        if (requestParameters['leiTestStatus'] == null) {
            throw new runtime.RequiredError(
                'leiTestStatus',
                'Required parameter "leiTestStatus" was null or undefined when calling updateTestGLEIFClientBehaviour().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/.private/gleif/{lei}/status`.replace(`{${"lei"}}`, encodeURIComponent(String(requestParameters['lei']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LeiTestStatusToJSON(requestParameters['leiTestStatus']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async updateTestGLEIFClientBehaviour(requestParameters: UpdateTestGLEIFClientBehaviourRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateTestGLEIFClientBehaviourRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateTestGLEIFClientBehaviour1Raw(requestParameters: UpdateTestGLEIFClientBehaviour1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling updateTestGLEIFClientBehaviour1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/.private/gleif/behaviour`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async updateTestGLEIFClientBehaviour1(requestParameters: UpdateTestGLEIFClientBehaviour1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.updateTestGLEIFClientBehaviour1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
