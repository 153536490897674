enum OorWorkflowTask {
  INTRODUCTION = 'INTRODUCTION',
  PROPOSE_RECIPIENTS = 'PROPOSE_RECIPIENTS',
  QAR_ORG_VERIFICATION = 'QAR_ORG_VERIFICATION',
  PROVIDE_ADDITIONAL_DOCUMENTS = 'PROVIDE_ADDITIONAL_DOCUMENTS',
  IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION',
  SCHEDULE_ISSUANCE_CEREMONY = 'SCHEDULE_ISSUANCE_CEREMONY',
  ISSUANCE_CEREMONY = 'ISSUANCE_CEREMONY'
}

export default OorWorkflowTask
