/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TaskActorResponseDto
 */
export interface TaskActorResponseDto {
    /**
     * 
     * @type {string}
     * @memberof TaskActorResponseDto
     */
    workflowInstanceAssignedTaskId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskActorResponseDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskActorResponseDto
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskActorResponseDto
     */
    workflowInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskActorResponseDto
     */
    taskType?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskActorResponseDto
     */
    symbolicTextId?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskActorResponseDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof TaskActorResponseDto
     */
    callbackUrl?: string;
}

/**
 * Check if a given object implements the TaskActorResponseDto interface.
 */
export function instanceOfTaskActorResponseDto(value: object): boolean {
    return true;
}

export function TaskActorResponseDtoFromJSON(json: any): TaskActorResponseDto {
    return TaskActorResponseDtoFromJSONTyped(json, false);
}

export function TaskActorResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TaskActorResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceAssignedTaskId': json['workflowInstanceAssignedTaskId'] == null ? undefined : json['workflowInstanceAssignedTaskId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userName': json['userName'] == null ? undefined : json['userName'],
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'taskType': json['taskType'] == null ? undefined : json['taskType'],
        'symbolicTextId': json['symbolicTextId'] == null ? undefined : json['symbolicTextId'],
        'status': json['status'] == null ? undefined : json['status'],
        'callbackUrl': json['callbackUrl'] == null ? undefined : json['callbackUrl'],
    };
}

export function TaskActorResponseDtoToJSON(value?: TaskActorResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceAssignedTaskId': value['workflowInstanceAssignedTaskId'],
        'userId': value['userId'],
        'userName': value['userName'],
        'workflowInstanceId': value['workflowInstanceId'],
        'taskType': value['taskType'],
        'symbolicTextId': value['symbolicTextId'],
        'status': value['status'],
        'callbackUrl': value['callbackUrl'],
    };
}

