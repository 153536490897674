export function isValidEmail(email?: string) {
  if (!email) return false
  const emailRegex = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
  return emailRegex.test(email)
}

export function isValidPasscode(passcode?: string) {
  if (!passcode) return false
  const passcodeRegex = new RegExp(/^[a-zA-Z0-9]{21}$/)
  return passcodeRegex.test(passcode)
}
