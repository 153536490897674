import React from 'react'

interface IProps {
  size?: number
  color?: string
}

const HoldIcon = ({ size = 15, color = '#000' }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.799635 12.6928L6.39705 15.8716C6.64017 16.0111 6.91595 16.0845 7.19668 16.0845C7.47741 16.0845 7.75319 16.0111 7.99631 15.8716L13.5937 12.6928C13.8374 12.553 14.0396 12.3518 14.18 12.1095C14.3204 11.8672 14.394 11.5923 14.3934 11.3127V4.95525C14.394 4.67563 14.3204 4.4008 14.18 4.15848C14.0396 3.91617 13.8374 3.71495 13.5937 3.57515L7.99631 0.396407C7.75319 0.256911 7.47741 0.183472 7.19668 0.183472C6.91595 0.183472 6.64017 0.256911 6.39705 0.396407L0.799635 3.57515C0.555974 3.71495 0.353738 3.91617 0.213341 4.15848C0.0729437 4.4008 -0.000644661 4.67563 4.25481e-06 4.95525V11.3127C-0.000644661 11.5923 0.0729437 11.8672 0.213341 12.1095C0.353738 12.3518 0.555974 12.553 0.799635 12.6928V12.6928ZM1.59927 4.95525L7.19668 1.77651L12.7941 4.95525V11.3127L7.19668 14.4915L1.59927 11.3127V4.95525Z"
        fill={color}
      />
      <path
        d="M7.99632 11.8425V9.60151C8.60381 9.41165 9.12282 9.01191 9.45941 8.47465C9.79599 7.93739 9.9279 7.29813 9.83126 6.67258C9.73462 6.04703 9.41581 5.47654 8.93255 5.06438C8.44929 4.65222 7.83351 4.42563 7.19669 4.42563C6.55987 4.42563 5.9441 4.65222 5.46084 5.06438C4.97758 5.47654 4.65877 6.04703 4.56213 6.67258C4.46549 7.29813 4.5974 7.93739 4.93398 8.47465C5.27056 9.01191 5.78958 9.41165 6.39706 9.60151V11.8425H7.99632ZM6.44237 6.32476C6.59153 6.1767 6.78151 6.0759 6.98829 6.03511C7.19508 5.99432 7.40939 6.01537 7.60414 6.09561C7.79888 6.17584 7.96533 6.31165 8.08243 6.48587C8.19953 6.66009 8.26203 6.8649 8.26203 7.07442C8.26203 7.28393 8.19953 7.48874 8.08243 7.66296C7.96533 7.83718 7.79888 7.97299 7.60414 8.05323C7.40939 8.13346 7.19508 8.15451 6.98829 8.11372C6.78151 8.07293 6.59153 7.97214 6.44237 7.82407C6.34325 7.72566 6.26461 7.6088 6.21095 7.48017C6.1573 7.35154 6.12968 7.21366 6.12968 7.07442C6.12968 6.93517 6.1573 6.79729 6.21095 6.66866C6.26461 6.54003 6.34325 6.42317 6.44237 6.32476Z"
        fill={color}
      />
    </svg>
  )
}
export default HoldIcon
