import { Observable, interval, Subscription } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { NotificationList } from './types'
import { handleIpexGrantExnMessage } from './credential'
import useStore from '@/state/store'
import { SignifyClient } from 'signify-ts'
import { consoleLog } from '@/utils/console-logger'
import { getAllNotificationsByType } from './notification'
import { ExnMessageType } from '@/enums/keri-event-type'

/**
 * Class for processing the KERI notifications. Exposes start and stop method polling.
 *
 * Notifications are fetched from KERIA by polling based on the time interval passed in constructor. Notifications are then processed based on the exchange message route
 *
 * Usage:
 *
 *    const notificationProcessor = KeriNotificationProcessor.getInstance(5000);
 *
 *    To start polling: notificationProcessor.start()
 *
 *    To stop polling: notificationProcessor.stop()
 *
 */
class KeriNotificationProcessor {
  private static instance: KeriNotificationProcessor | null = null

  private intervalTime: number
  private pollInterval$: Observable<number>
  private subscription: Subscription | null = null
  private signifyClient: SignifyClient

  static getInstance(
    pollInterval: number = 3000,
    signifyClient: SignifyClient
  ) {
    if (!KeriNotificationProcessor.instance) {
      KeriNotificationProcessor.instance = new KeriNotificationProcessor(
        pollInterval,
        signifyClient
      )
    }
    return KeriNotificationProcessor.instance
  }

  constructor(intervalTime: number = 3000, signifyClient: SignifyClient) {
    // default interval is 3000 ms
    this.intervalTime = intervalTime
    this.pollInterval$ = interval(this.intervalTime)
    this.signifyClient = signifyClient
  }

  start(): void {
    this.subscription = this.pollInterval$
      .pipe(switchMap(() => this.fetchNotifications()))
      .subscribe({
        next: (notifications) => this.handleNotifications(notifications),
        error: (error) => console.error('Error in polling:', error)
        // complete: () => {}
      })
  }

  stop(): void {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  private async fetchNotifications(): Promise<NotificationList> {
    let client = this.signifyClient ?? useStore.getState().signifyClient
    let notifications = await getAllNotificationsByType(
      client,
      ExnMessageType.GRANT,
      30,
      true
    )
    return notifications
  }

  private async handleNotifications(
    notifications: NotificationList
  ): Promise<void> {
    let client = this.signifyClient ?? useStore.getState().signifyClient
    await processNotifications(notifications, client)
  }
}

export async function processNotifications(
  notifications: NotificationList,
  client: SignifyClient
) {
  if (!notifications?.notes) return

  const filteredResponse = notifications.notes
  consoleLog(
    'Keri Notification Processor: notification in last 5 mins ',
    filteredResponse
  )

  for (const notification of filteredResponse) {
    if (notification.r == true) {
      continue
    }
    consoleLog(
      'Keri Notification Processor: processing notification ',
      notification
    )

    switch (notification.a.r) {
      case '/exn/ipex/grant':
        await handleIpexGrantExnMessage(notification, client)
        break
    }
  }
}

export default KeriNotificationProcessor
