import { Identifier } from '@/state/signify'

export const generateAlias = (
  who: string,
  role: string,
  org: string,
  isMultisigParticipant: boolean = false,
  isOrgAid: boolean = false
) => {
  let alias = who
  if (isMultisigParticipant == true)
    alias = `${who}-as-representative-of-org-${org}`
  else if (isOrgAid == true) {
    //alias = `${org} (group) as ${role}`
    alias = `${org} as ${role}`
  } else if (org == '') alias = `${who}-as-${role}`
  else alias = `${who}-as-${role}-at-${org}`

  return alias.trim().replace(/\s+/g, '-').toLowerCase()
}

export function calculateEqualMultisigWeightage(totalMembers) {
  let weightages = Array.from({ length: totalMembers }, () => ({
    numerator: 1,
    denominator: totalMembers
  }))

  return weightages
}

export const shortenAid = (aid: string): string => {
  if (!aid) return ''

  const prefixLength = aid.length <= 44 ? 4 : 8
  return aid.slice(0, prefixLength) + '...' + aid.slice(-4)
}

export const sortAids = (aids: string[]) => {
  let sortedAids = aids.sort(compareAids)
  return sortedAids
}

export const compareAids = (a: string, b: string) => {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

export function findOrgGroupAidByType(
  type: string,
  identifiers: Identifier[],
  orgName: string
): Identifier {
  try {
    let orgGroupAidAlias = undefined
    if (type === 'legal-entity')
      orgGroupAidAlias = generateAlias('', 'legal-entity', orgName, false, true)
    else if (type === 'qvi')
      orgGroupAidAlias = generateAlias('', 'qvi', orgName, false, true)
    const groupAid = identifiers.find(
      (i) =>
        i.isMultisig === true &&
        i.name
          .toUpperCase()
          .includes(`${orgGroupAidAlias.trim().toUpperCase()}`)
    )
    return groupAid
  } catch (ex) {
    return undefined
  }
}

export function isGroupAid(aid: any): boolean {
  return (
    aid.hasOwnProperty('group') &&
    typeof aid.group === 'object' &&
    aid.group !== null
  )
}

export function getLocalMemberFromGroupAid(aid: any): {
  name: string
  prefix: string
} {
  if (!isGroupAid(aid)) return null

  if (
    aid.group.hasOwnProperty('mhab') &&
    typeof aid.group.mhab === 'object' &&
    aid.group.mhab !== null
  ) {
    return {
      name: aid.group.mhab.name,
      prefix: aid.group.mhab.prefix
    }
  }
  return null
}

export function getLocalStateFromGroupAid(aid: any): any {
  if (
    aid.hasOwnProperty('group') &&
    typeof aid.group === 'object' &&
    aid.group !== null
  ) {
    if (
      aid.group.hasOwnProperty('mhab') &&
      typeof aid.group.mhab === 'object' &&
      aid.group.mhab !== null
    ) {
      return aid.group.mhab.state
    }
  }
  return null
}

export function getGroupSigningMemberAids(
  groupMembers: any,
  sort: boolean = false
): any {
  let smids = groupMembers['signing'].map((m: { aid: string }) => m.aid)
  if (sort === true) smids = sortAids(smids)
  return smids
}

export function getFirstWitnessFromEnds(ends: any): {
  prefix: string
  http: string
} {
  if (ends && ends.witness) {
    const witnessAids = Object.keys(ends.witness)
    if (witnessAids?.length > 0) {
      return { prefix: witnessAids[0], http: ends.witness[witnessAids[0]].http }
    }
  }
  return null
}
