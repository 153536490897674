/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OorResentNotificationRequestDto
 */
export interface OorResentNotificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OorResentNotificationRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof OorResentNotificationRequestDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof OorResentNotificationRequestDto
     */
    email?: string;
}

/**
 * Check if a given object implements the OorResentNotificationRequestDto interface.
 */
export function instanceOfOorResentNotificationRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    return true;
}

export function OorResentNotificationRequestDtoFromJSON(json: any): OorResentNotificationRequestDto {
    return OorResentNotificationRequestDtoFromJSONTyped(json, false);
}

export function OorResentNotificationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OorResentNotificationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'email': json['email'] == null ? undefined : json['email'],
    };
}

export function OorResentNotificationRequestDtoToJSON(value?: OorResentNotificationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'userId': value['userId'],
        'email': value['email'],
    };
}

