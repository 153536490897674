/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CredentialRevocationNotificationDto,
  FindOrgLarsResponseDto,
  OrgDetailResponseDto,
  OrgDto,
  QviOrgIdResponseDto,
  RoleAcceptanceResponseDto,
  TimeSlotResponse,
  UserInOrgAndGrantResponseDto,
  UserVerificationProcess,
} from '../models/index';
import {
    CredentialRevocationNotificationDtoFromJSON,
    CredentialRevocationNotificationDtoToJSON,
    FindOrgLarsResponseDtoFromJSON,
    FindOrgLarsResponseDtoToJSON,
    OrgDetailResponseDtoFromJSON,
    OrgDetailResponseDtoToJSON,
    OrgDtoFromJSON,
    OrgDtoToJSON,
    QviOrgIdResponseDtoFromJSON,
    QviOrgIdResponseDtoToJSON,
    RoleAcceptanceResponseDtoFromJSON,
    RoleAcceptanceResponseDtoToJSON,
    TimeSlotResponseFromJSON,
    TimeSlotResponseToJSON,
    UserInOrgAndGrantResponseDtoFromJSON,
    UserInOrgAndGrantResponseDtoToJSON,
    UserVerificationProcessFromJSON,
    UserVerificationProcessToJSON,
} from '../models/index';

export interface AcceptanceForGrantRequest {
    encryptedParams: string;
}

export interface AvailableTimeSlotsRequest {
    after: string;
    before: string;
    workflow: string;
    minutes?: number;
    requiredNumberOfQars?: number;
}

export interface CredentialRevocationNotificationRequest {
    credentialRevocationNotificationDto: CredentialRevocationNotificationDto;
}

export interface FindAllUsersInOrgAndGrantRequest {
    org: string;
    role: string;
}

export interface FindOrgLarsRequest {
    workflow: string;
}

export interface FindUserForOrgAndGrantRequest {
    user: string;
    org: string;
    role: string;
}

export interface FindUserVerificationDetailsRequest {
    user: string;
    workflow: string;
}

export interface GetOrgByWorkflowInstanceIdRequest {
    workflow: string;
}

export interface GetQviOrgIdByWorkflowInstanceIdRequest {
    workflow: string;
}

export interface IsLeiStatusValidRequest {
    workflowInstanceId: string;
}

export interface OrgDetailRequest {
    workflow: string;
}

/**
 * 
 */
export class UtilControllerApi extends runtime.BaseAPI {

    /**
     * + User accepts proposed grant
     */
    async acceptanceForGrantRaw(requestParameters: AcceptanceForGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleAcceptanceResponseDto>> {
        if (requestParameters['encryptedParams'] == null) {
            throw new runtime.RequiredError(
                'encryptedParams',
                'Required parameter "encryptedParams" was null or undefined when calling acceptanceForGrant().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/util/acceptance/{encrypted-params}`.replace(`{${"encrypted-params"}}`, encodeURIComponent(String(requestParameters['encryptedParams']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleAcceptanceResponseDtoFromJSON(jsonValue));
    }

    /**
     * + User accepts proposed grant
     */
    async acceptanceForGrant(requestParameters: AcceptanceForGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleAcceptanceResponseDto> {
        const response = await this.acceptanceForGrantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * QARs available appointment slots for issuance ceremony
     */
    async availableTimeSlotsRaw(requestParameters: AvailableTimeSlotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TimeSlotResponse>>> {
        if (requestParameters['after'] == null) {
            throw new runtime.RequiredError(
                'after',
                'Required parameter "after" was null or undefined when calling availableTimeSlots().'
            );
        }

        if (requestParameters['before'] == null) {
            throw new runtime.RequiredError(
                'before',
                'Required parameter "before" was null or undefined when calling availableTimeSlots().'
            );
        }

        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling availableTimeSlots().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['after'] != null) {
            queryParameters['after'] = requestParameters['after'];
        }

        if (requestParameters['before'] != null) {
            queryParameters['before'] = requestParameters['before'];
        }

        if (requestParameters['minutes'] != null) {
            queryParameters['minutes'] = requestParameters['minutes'];
        }

        if (requestParameters['requiredNumberOfQars'] != null) {
            queryParameters['required-number-of-qars'] = requestParameters['requiredNumberOfQars'];
        }

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/util/available-appt-slots-for-qars`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TimeSlotResponseFromJSON));
    }

    /**
     * QARs available appointment slots for issuance ceremony
     */
    async availableTimeSlots(requestParameters: AvailableTimeSlotsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TimeSlotResponse> | null | undefined > {
        const response = await this.availableTimeSlotsRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Send email notification to all QARs about credential revocation. Returns credential from request.
     */
    async credentialRevocationNotificationRaw(requestParameters: CredentialRevocationNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['credentialRevocationNotificationDto'] == null) {
            throw new runtime.RequiredError(
                'credentialRevocationNotificationDto',
                'Required parameter "credentialRevocationNotificationDto" was null or undefined when calling credentialRevocationNotification().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/util/credential-revocation-notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CredentialRevocationNotificationDtoToJSON(requestParameters['credentialRevocationNotificationDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Send email notification to all QARs about credential revocation. Returns credential from request.
     */
    async credentialRevocationNotification(requestParameters: CredentialRevocationNotificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.credentialRevocationNotificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get users with specific grant in an org
     */
    async findAllUsersInOrgAndGrantRaw(requestParameters: FindAllUsersInOrgAndGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserInOrgAndGrantResponseDto>>> {
        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling findAllUsersInOrgAndGrant().'
            );
        }

        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling findAllUsersInOrgAndGrant().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/util/grant/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserInOrgAndGrantResponseDtoFromJSON));
    }

    /**
     * Get users with specific grant in an org
     */
    async findAllUsersInOrgAndGrant(requestParameters: FindAllUsersInOrgAndGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserInOrgAndGrantResponseDto> | null | undefined > {
        const response = await this.findAllUsersInOrgAndGrantRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get org LARs
     */
    async findOrgLarsRaw(requestParameters: FindOrgLarsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindOrgLarsResponseDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling findOrgLars().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/util/org-lars`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindOrgLarsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get org LARs
     */
    async findOrgLars(requestParameters: FindOrgLarsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindOrgLarsResponseDto> {
        const response = await this.findOrgLarsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get specific grant details of user in an org
     */
    async findUserForOrgAndGrantRaw(requestParameters: FindUserForOrgAndGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInOrgAndGrantResponseDto>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling findUserForOrgAndGrant().'
            );
        }

        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling findUserForOrgAndGrant().'
            );
        }

        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling findUserForOrgAndGrant().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/util/grant`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInOrgAndGrantResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get specific grant details of user in an org
     */
    async findUserForOrgAndGrant(requestParameters: FindUserForOrgAndGrantRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInOrgAndGrantResponseDto | null | undefined > {
        const response = await this.findUserForOrgAndGrantRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Returns a list, as it is possible in case the user validates their identity more than once
     * Get ID verification detail of user
     */
    async findUserVerificationDetailsRaw(requestParameters: FindUserVerificationDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserVerificationProcess>> {
        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling findUserVerificationDetails().'
            );
        }

        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling findUserVerificationDetails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/util/user-verification-details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserVerificationProcessFromJSON(jsonValue));
    }

    /**
     * Returns a list, as it is possible in case the user validates their identity more than once
     * Get ID verification detail of user
     */
    async findUserVerificationDetails(requestParameters: FindUserVerificationDetailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserVerificationProcess | null | undefined > {
        const response = await this.findUserVerificationDetailsRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get Org parameters for workflow instance id
     */
    async getOrgByWorkflowInstanceIdRaw(requestParameters: GetOrgByWorkflowInstanceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling getOrgByWorkflowInstanceId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/util/org`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * Get Org parameters for workflow instance id
     */
    async getOrgByWorkflowInstanceId(requestParameters: GetOrgByWorkflowInstanceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto | null | undefined > {
        const response = await this.getOrgByWorkflowInstanceIdRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get QVI org Id for specified workflow instance
     */
    async getQviOrgIdByWorkflowInstanceIdRaw(requestParameters: GetQviOrgIdByWorkflowInstanceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<QviOrgIdResponseDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling getQviOrgIdByWorkflowInstanceId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/util/qvi-org-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => QviOrgIdResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get QVI org Id for specified workflow instance
     */
    async getQviOrgIdByWorkflowInstanceId(requestParameters: GetQviOrgIdByWorkflowInstanceIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<QviOrgIdResponseDto> {
        const response = await this.getQviOrgIdByWorkflowInstanceIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns LEI status. True - status is ok, false - not ok (for example, expired), empty - result is unknown
     */
    async isLeiStatusValidRaw(requestParameters: IsLeiStatusValidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['workflowInstanceId'] == null) {
            throw new runtime.RequiredError(
                'workflowInstanceId',
                'Required parameter "workflowInstanceId" was null or undefined when calling isLeiStatusValid().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflowInstanceId'] != null) {
            queryParameters['workflow-instance-id'] = requestParameters['workflowInstanceId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/util/lei-status-ok`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Returns LEI status. True - status is ok, false - not ok (for example, expired), empty - result is unknown
     */
    async isLeiStatusValid(requestParameters: IsLeiStatusValidRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.isLeiStatusValidRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get Org details
     */
    async orgDetailRaw(requestParameters: OrgDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDetailResponseDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling orgDetail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/util/org-detail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDetailResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get Org details
     */
    async orgDetail(requestParameters: OrgDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDetailResponseDto> {
        const response = await this.orgDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
