/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CancelCeremonyRequestDto,
  CommonResponseDto,
  EcrPrerequisitesRequestDto,
  EcrProposalRequestDto,
  EcrRemovalRequestDto,
  EcrRemovalResponseDto,
  EcrResentNotificationRequestDto,
  EcrResentNotificationResponseDto,
  PossibleOorRecipientsResponseDto,
  ScheduleEcrCeremonyRequestDto,
  WorkflowExecutedResponseDto,
} from '../models/index';
import {
    CancelCeremonyRequestDtoFromJSON,
    CancelCeremonyRequestDtoToJSON,
    CommonResponseDtoFromJSON,
    CommonResponseDtoToJSON,
    EcrPrerequisitesRequestDtoFromJSON,
    EcrPrerequisitesRequestDtoToJSON,
    EcrProposalRequestDtoFromJSON,
    EcrProposalRequestDtoToJSON,
    EcrRemovalRequestDtoFromJSON,
    EcrRemovalRequestDtoToJSON,
    EcrRemovalResponseDtoFromJSON,
    EcrRemovalResponseDtoToJSON,
    EcrResentNotificationRequestDtoFromJSON,
    EcrResentNotificationRequestDtoToJSON,
    EcrResentNotificationResponseDtoFromJSON,
    EcrResentNotificationResponseDtoToJSON,
    PossibleOorRecipientsResponseDtoFromJSON,
    PossibleOorRecipientsResponseDtoToJSON,
    ScheduleEcrCeremonyRequestDtoFromJSON,
    ScheduleEcrCeremonyRequestDtoToJSON,
    WorkflowExecutedResponseDtoFromJSON,
    WorkflowExecutedResponseDtoToJSON,
} from '../models/index';

export interface CancelCeremony2Request {
    cancelCeremonyRequestDto: CancelCeremonyRequestDto;
}

export interface EcrProposalRequest {
    ecrProposalRequestDto: EcrProposalRequestDto;
}

export interface EcrRemovalRequest {
    ecrRemovalRequestDto: EcrRemovalRequestDto;
}

export interface FindCeremonyParticipants1Request {
    workflow: string;
}

export interface GetEcrWorkflowModeRequest {
    workflow: string;
}

export interface ProposedECRsRequest {
    workflow?: string;
    meeting?: string;
}

export interface ResendNotificationToEcrRecipientRequest {
    ecrResentNotificationRequestDto: EcrResentNotificationRequestDto;
}

export interface ScheduleCeremony2Request {
    scheduleEcrCeremonyRequestDto: ScheduleEcrCeremonyRequestDto;
}

export interface Start2Request {
    ecrPrerequisitesRequestDto: EcrPrerequisitesRequestDto;
}

/**
 * 
 */
export class EcrPrerequisitesControllerApi extends runtime.BaseAPI {

    /**
     * Cancels scheduled ceremony
     */
    async cancelCeremony2Raw(requestParameters: CancelCeremony2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommonResponseDto>> {
        if (requestParameters['cancelCeremonyRequestDto'] == null) {
            throw new runtime.RequiredError(
                'cancelCeremonyRequestDto',
                'Required parameter "cancelCeremonyRequestDto" was null or undefined when calling cancelCeremony2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/ecr-prereq/cancel-ceremony`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CancelCeremonyRequestDtoToJSON(requestParameters['cancelCeremonyRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommonResponseDtoFromJSON(jsonValue));
    }

    /**
     * Cancels scheduled ceremony
     */
    async cancelCeremony2(requestParameters: CancelCeremony2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommonResponseDto> {
        const response = await this.cancelCeremony2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Requester proposes ECR recipients
     */
    async ecrProposalRaw(requestParameters: EcrProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EcrProposalRequestDto>> {
        if (requestParameters['ecrProposalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'ecrProposalRequestDto',
                'Required parameter "ecrProposalRequestDto" was null or undefined when calling ecrProposal().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/ecr-prereq/ecr-proposal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EcrProposalRequestDtoToJSON(requestParameters['ecrProposalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EcrProposalRequestDtoFromJSON(jsonValue));
    }

    /**
     * Requester proposes ECR recipients
     */
    async ecrProposal(requestParameters: EcrProposalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EcrProposalRequestDto> {
        const response = await this.ecrProposalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Remove one of the recipients
     */
    async ecrRemovalRaw(requestParameters: EcrRemovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EcrRemovalResponseDto>> {
        if (requestParameters['ecrRemovalRequestDto'] == null) {
            throw new runtime.RequiredError(
                'ecrRemovalRequestDto',
                'Required parameter "ecrRemovalRequestDto" was null or undefined when calling ecrRemoval().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/ecr-prereq/ecr-removal`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EcrRemovalRequestDtoToJSON(requestParameters['ecrRemovalRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EcrRemovalResponseDtoFromJSON(jsonValue));
    }

    /**
     * Remove one of the recipients
     */
    async ecrRemoval(requestParameters: EcrRemovalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EcrRemovalResponseDto> {
        const response = await this.ecrRemovalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The list includes requester, ECRs and LARs. The list include QARs only if meeting is scheduled, because the final list of QARs depend on the chosen timeslot
     * Return list of possible recipients.
     */
    async findCeremonyParticipants1Raw(requestParameters: FindCeremonyParticipants1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PossibleOorRecipientsResponseDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling findCeremonyParticipants1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/ecr-prereq/ceremony-participants`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PossibleOorRecipientsResponseDtoFromJSON(jsonValue));
    }

    /**
     * The list includes requester, ECRs and LARs. The list include QARs only if meeting is scheduled, because the final list of QARs depend on the chosen timeslot
     * Return list of possible recipients.
     */
    async findCeremonyParticipants1(requestParameters: FindCeremonyParticipants1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PossibleOorRecipientsResponseDto> {
        const response = await this.findCeremonyParticipants1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns mode of ecr workflow
     */
    async getEcrWorkflowModeRaw(requestParameters: GetEcrWorkflowModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommonResponseDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling getEcrWorkflowMode().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/ecr-prereq/mode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommonResponseDtoFromJSON(jsonValue));
    }

    /**
     * Returns mode of ecr workflow
     */
    async getEcrWorkflowMode(requestParameters: GetEcrWorkflowModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommonResponseDto> {
        const response = await this.getEcrWorkflowModeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Return list of proposed ECRs
     */
    async proposedECRsRaw(requestParameters: ProposedECRsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PossibleOorRecipientsResponseDto>> {
        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        if (requestParameters['meeting'] != null) {
            queryParameters['meeting'] = requestParameters['meeting'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/ecr-prereq/proposed-recipients`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PossibleOorRecipientsResponseDtoFromJSON(jsonValue));
    }

    /**
     * Return list of proposed ECRs
     */
    async proposedECRs(requestParameters: ProposedECRsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PossibleOorRecipientsResponseDto> {
        const response = await this.proposedECRsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend the notification to ECR recipient
     */
    async resendNotificationToEcrRecipientRaw(requestParameters: ResendNotificationToEcrRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EcrResentNotificationResponseDto>> {
        if (requestParameters['ecrResentNotificationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'ecrResentNotificationRequestDto',
                'Required parameter "ecrResentNotificationRequestDto" was null or undefined when calling resendNotificationToEcrRecipient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/ecr-prereq/resent-notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EcrResentNotificationRequestDtoToJSON(requestParameters['ecrResentNotificationRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EcrResentNotificationResponseDtoFromJSON(jsonValue));
    }

    /**
     * Resend the notification to ECR recipient
     */
    async resendNotificationToEcrRecipient(requestParameters: ResendNotificationToEcrRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EcrResentNotificationResponseDto> {
        const response = await this.resendNotificationToEcrRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Schedule issuance ceremony as per selected time slot
     */
    async scheduleCeremony2Raw(requestParameters: ScheduleCeremony2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ScheduleEcrCeremonyRequestDto>> {
        if (requestParameters['scheduleEcrCeremonyRequestDto'] == null) {
            throw new runtime.RequiredError(
                'scheduleEcrCeremonyRequestDto',
                'Required parameter "scheduleEcrCeremonyRequestDto" was null or undefined when calling scheduleCeremony2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/ecr-prereq/scheduling-ceremony`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleEcrCeremonyRequestDtoToJSON(requestParameters['scheduleEcrCeremonyRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ScheduleEcrCeremonyRequestDtoFromJSON(jsonValue));
    }

    /**
     * Schedule issuance ceremony as per selected time slot
     */
    async scheduleCeremony2(requestParameters: ScheduleCeremony2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ScheduleEcrCeremonyRequestDto> {
        const response = await this.scheduleCeremony2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Execute node from existing workflow execution
     * Create and start a new ECR vLEI Prerequisites Workflow Instance
     */
    async start2Raw(requestParameters: Start2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WorkflowExecutedResponseDto>> {
        if (requestParameters['ecrPrerequisitesRequestDto'] == null) {
            throw new runtime.RequiredError(
                'ecrPrerequisitesRequestDto',
                'Required parameter "ecrPrerequisitesRequestDto" was null or undefined when calling start2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/ecr-prereq/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EcrPrerequisitesRequestDtoToJSON(requestParameters['ecrPrerequisitesRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WorkflowExecutedResponseDtoFromJSON(jsonValue));
    }

    /**
     * Execute node from existing workflow execution
     * Create and start a new ECR vLEI Prerequisites Workflow Instance
     */
    async start2(requestParameters: Start2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WorkflowExecutedResponseDto> {
        const response = await this.start2Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
