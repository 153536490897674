/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface L10nTextBatchResponseDto
 */
export interface L10nTextBatchResponseDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof L10nTextBatchResponseDto
     */
    localizedValues?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof L10nTextBatchResponseDto
     */
    lang?: string;
}

/**
 * Check if a given object implements the L10nTextBatchResponseDto interface.
 */
export function instanceOfL10nTextBatchResponseDto(value: object): boolean {
    return true;
}

export function L10nTextBatchResponseDtoFromJSON(json: any): L10nTextBatchResponseDto {
    return L10nTextBatchResponseDtoFromJSONTyped(json, false);
}

export function L10nTextBatchResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): L10nTextBatchResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'localizedValues': json['localizedValues'] == null ? undefined : json['localizedValues'],
        'lang': json['lang'] == null ? undefined : json['lang'],
    };
}

export function L10nTextBatchResponseDtoToJSON(value?: L10nTextBatchResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'localizedValues': value['localizedValues'],
        'lang': value['lang'],
    };
}

