/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { WorkflowTaskActorDto } from './WorkflowTaskActorDto';
import {
    WorkflowTaskActorDtoFromJSON,
    WorkflowTaskActorDtoFromJSONTyped,
    WorkflowTaskActorDtoToJSON,
} from './WorkflowTaskActorDto';

/**
 * 
 * @export
 * @interface WorkflowExecutedResponseDto
 */
export interface WorkflowExecutedResponseDto {
    /**
     * 
     * @type {string}
     * @memberof WorkflowExecutedResponseDto
     */
    workflowInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowExecutedResponseDto
     */
    createdAt?: string;
    /**
     * 
     * @type {Array<WorkflowTaskActorDto>}
     * @memberof WorkflowExecutedResponseDto
     */
    assignedTasks?: Array<WorkflowTaskActorDto>;
}

/**
 * Check if a given object implements the WorkflowExecutedResponseDto interface.
 */
export function instanceOfWorkflowExecutedResponseDto(value: object): boolean {
    return true;
}

export function WorkflowExecutedResponseDtoFromJSON(json: any): WorkflowExecutedResponseDto {
    return WorkflowExecutedResponseDtoFromJSONTyped(json, false);
}

export function WorkflowExecutedResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowExecutedResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'createdAt': json['createdAt'] == null ? undefined : json['createdAt'],
        'assignedTasks': json['assignedTasks'] == null ? undefined : ((json['assignedTasks'] as Array<any>).map(WorkflowTaskActorDtoFromJSON)),
    };
}

export function WorkflowExecutedResponseDtoToJSON(value?: WorkflowExecutedResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'createdAt': value['createdAt'],
        'assignedTasks': value['assignedTasks'] == null ? undefined : ((value['assignedTasks'] as Array<any>).map(WorkflowTaskActorDtoToJSON)),
    };
}

