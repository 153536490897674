/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewUserRequestDto
 */
export interface NewUserRequestDto {
    /**
     * 
     * @type {string}
     * @memberof NewUserRequestDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUserRequestDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUserRequestDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof NewUserRequestDto
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUserRequestDto
     */
    phoneNumber?: string;
}

/**
 * Check if a given object implements the NewUserRequestDto interface.
 */
export function instanceOfNewUserRequestDto(value: object): boolean {
    if (!('email' in value)) return false;
    return true;
}

export function NewUserRequestDtoFromJSON(json: any): NewUserRequestDto {
    return NewUserRequestDtoFromJSONTyped(json, false);
}

export function NewUserRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewUserRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'email': json['email'],
        'countryCode': json['countryCode'] == null ? undefined : json['countryCode'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
    };
}

export function NewUserRequestDtoToJSON(value?: NewUserRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'email': value['email'],
        'countryCode': value['countryCode'],
        'phoneNumber': value['phoneNumber'],
    };
}

