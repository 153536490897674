import { Label } from 'flowbite-react'
import { useIntl } from 'react-intl'
import TextInput from '@/components/base/TextInput'

interface IProps {
  optional?: boolean
  className?: string
  type?: string
  id: string
  htmlFor: string
  name?: string
  value: string
  disabled?: boolean
  placeholder?: string
  onChange: (e?: any) => void
  error?: string
}

const RecipientTextInput = ({
  className,
  type = 'text',
  id,
  htmlFor,
  value,
  placeholder = '',
  disabled = false,
  onChange,
  error = '',
  optional = false,
  name,
  ...props
}: IProps) => {
  const { formatMessage } = useIntl()
  return (
    <div className={`flex flex-col ${className}`}>
      <div className="mb-2 block">
        <Label
          htmlFor={htmlFor}
          value={formatMessage({
            id: id
          })}
          className="text-neutral-800 text-sm font-normal"
        />
        {!optional ? (
          <span className="text-red-500 font-bold ml-1">*</span>
        ) : (
          <></>
        )}
      </div>
      <TextInput
        id={htmlFor}
        name={name}
        className="w-full"
        placeholder={placeholder}
        type={type}
        value={value}
        disabled={disabled}
        onChange={onChange}
        error={error}
        {...props}
      />
    </div>
  )
}

export default RecipientTextInput
