import useStore from '@/state/store'

const getErrorMessage = (code: string) => {
  const translations =
    useStore.getState().localization.symbolicTextIdToLocalizedText
  if (code in translations) return translations[code]

  return translations['INTERNAL_ERROR']
}

export { getErrorMessage }
