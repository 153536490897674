import tailwindConfig from 'tailwind.config'

interface IProps {
  size?: number
  color?: string
}

const Shield = ({
  size = 22,
  color = tailwindConfig.theme.extend.colors.secondaryGreen900
}: IProps) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8.1665 13.1171C9.27796 12.788 10.2061 12.138 10.9509 11.1671C11.6957 10.1963 12.1311 9.11292 12.2571 7.91714H8.1665V2.73359L4.0415 4.21461V7.62094C4.0415 7.69773 4.05296 7.79646 4.07588 7.91714H8.1665V13.1171ZM8.1665 14.4336C8.0863 14.4336 8.01182 14.4281 7.94307 14.4171C7.87432 14.4062 7.80557 14.3897 7.73682 14.3678C6.18994 13.8741 4.95817 12.9608 4.0415 11.6279C3.12484 10.295 2.6665 8.8606 2.6665 7.32473V4.21461C2.6665 3.94034 2.74958 3.69351 2.91572 3.4741C3.08187 3.25469 3.29671 3.09562 3.56025 2.99688L7.68525 1.51587C7.84567 1.46102 8.00609 1.43359 8.1665 1.43359C8.32692 1.43359 8.48734 1.46102 8.64775 1.51587L12.7728 2.99688C13.0363 3.09562 13.2511 3.25469 13.4173 3.4741C13.5834 3.69351 13.6665 3.94034 13.6665 4.21461V7.32473C13.6665 8.8606 13.2082 10.295 12.2915 11.6279C11.3748 12.9608 10.1431 13.8741 8.59619 14.3678C8.52744 14.3897 8.45869 14.4062 8.38994 14.4171C8.32119 14.4281 8.24671 14.4336 8.1665 14.4336Z"
          fill={color}
        />
      </svg>
    </>
  )
}

export default Shield
