/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CredentialRevocationNotificationDto
 */
export interface CredentialRevocationNotificationDto {
    /**
     * 
     * @type {string}
     * @memberof CredentialRevocationNotificationDto
     */
    aid: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialRevocationNotificationDto
     */
    cred: string;
}

/**
 * Check if a given object implements the CredentialRevocationNotificationDto interface.
 */
export function instanceOfCredentialRevocationNotificationDto(value: object): boolean {
    if (!('aid' in value)) return false;
    if (!('cred' in value)) return false;
    return true;
}

export function CredentialRevocationNotificationDtoFromJSON(json: any): CredentialRevocationNotificationDto {
    return CredentialRevocationNotificationDtoFromJSONTyped(json, false);
}

export function CredentialRevocationNotificationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialRevocationNotificationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'aid': json['aid'],
        'cred': json['cred'],
    };
}

export function CredentialRevocationNotificationDtoToJSON(value?: CredentialRevocationNotificationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'aid': value['aid'],
        'cred': value['cred'],
    };
}

