/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAidOobiRequest
 */
export interface UpdateAidOobiRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateAidOobiRequest
     */
    oobi: Array<string>;
}

/**
 * Check if a given object implements the UpdateAidOobiRequest interface.
 */
export function instanceOfUpdateAidOobiRequest(value: object): boolean {
    if (!('oobi' in value)) return false;
    return true;
}

export function UpdateAidOobiRequestFromJSON(json: any): UpdateAidOobiRequest {
    return UpdateAidOobiRequestFromJSONTyped(json, false);
}

export function UpdateAidOobiRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateAidOobiRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'oobi': json['oobi'],
    };
}

export function UpdateAidOobiRequestToJSON(value?: UpdateAidOobiRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'oobi': value['oobi'],
    };
}

