/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddLarsRecipientDto,
  CreateDarRecipientDto,
  CreateTaskActorDto,
} from '../models/index';
import {
    AddLarsRecipientDtoFromJSON,
    AddLarsRecipientDtoToJSON,
    CreateDarRecipientDtoFromJSON,
    CreateDarRecipientDtoToJSON,
    CreateTaskActorDtoFromJSON,
    CreateTaskActorDtoToJSON,
} from '../models/index';

export interface AddLarsRecipientsRequest {
    addLarsRecipientDto: AddLarsRecipientDto;
}

export interface CreateDarRecipientRequest {
    createDarRecipientDto: CreateDarRecipientDto;
}

export interface CreateOrUpdateStatusForTaskActorRequest {
    createTaskActorDto: CreateTaskActorDto;
}

/**
 * 
 */
export class ApiTestControllerApi extends runtime.BaseAPI {

    /**
     * Add lar recipients
     */
    async addLarsRecipientsRaw(requestParameters: AddLarsRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['addLarsRecipientDto'] == null) {
            throw new runtime.RequiredError(
                'addLarsRecipientDto',
                'Required parameter "addLarsRecipientDto" was null or undefined when calling addLarsRecipients().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/.private/lar-recipients`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddLarsRecipientDtoToJSON(requestParameters['addLarsRecipientDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Add lar recipients
     */
    async addLarsRecipients(requestParameters: AddLarsRecipientsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addLarsRecipientsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create dar recipient
     */
    async createDarRecipientRaw(requestParameters: CreateDarRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['createDarRecipientDto'] == null) {
            throw new runtime.RequiredError(
                'createDarRecipientDto',
                'Required parameter "createDarRecipientDto" was null or undefined when calling createDarRecipient().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/.private/dar-recipient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateDarRecipientDtoToJSON(requestParameters['createDarRecipientDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create dar recipient
     */
    async createDarRecipient(requestParameters: CreateDarRecipientRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createDarRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create task actor. For testing only
     */
    async createOrUpdateStatusForTaskActorRaw(requestParameters: CreateOrUpdateStatusForTaskActorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['createTaskActorDto'] == null) {
            throw new runtime.RequiredError(
                'createTaskActorDto',
                'Required parameter "createTaskActorDto" was null or undefined when calling createOrUpdateStatusForTaskActor().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/.private/task-actor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTaskActorDtoToJSON(requestParameters['createTaskActorDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Create task actor. For testing only
     */
    async createOrUpdateStatusForTaskActor(requestParameters: CreateOrUpdateStatusForTaskActorRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.createOrUpdateStatusForTaskActorRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
