/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LarsInfoDto } from './LarsInfoDto';
import {
    LarsInfoDtoFromJSON,
    LarsInfoDtoFromJSONTyped,
    LarsInfoDtoToJSON,
} from './LarsInfoDto';

/**
 * 
 * @export
 * @interface FindOrgLarsResponseDto
 */
export interface FindOrgLarsResponseDto {
    /**
     * 
     * @type {Array<LarsInfoDto>}
     * @memberof FindOrgLarsResponseDto
     */
    larsInfos?: Array<LarsInfoDto>;
}

/**
 * Check if a given object implements the FindOrgLarsResponseDto interface.
 */
export function instanceOfFindOrgLarsResponseDto(value: object): boolean {
    return true;
}

export function FindOrgLarsResponseDtoFromJSON(json: any): FindOrgLarsResponseDto {
    return FindOrgLarsResponseDtoFromJSONTyped(json, false);
}

export function FindOrgLarsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindOrgLarsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'larsInfos': json['larsInfos'] == null ? undefined : ((json['larsInfos'] as Array<any>).map(LarsInfoDtoFromJSON)),
    };
}

export function FindOrgLarsResponseDtoToJSON(value?: FindOrgLarsResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'larsInfos': value['larsInfos'] == null ? undefined : ((value['larsInfos'] as Array<any>).map(LarsInfoDtoToJSON)),
    };
}

