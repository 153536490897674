import React from 'react'

interface IProps {
  size?: number
}

const SettingsIcon = ({ size = 15 }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5937 3.39168L7.99631 0.212936C7.75319 0.0734391 7.47741 0 7.19668 0C6.91595 0 6.64017 0.0734391 6.39705 0.212936L0.799635 3.39168C0.555974 3.53148 0.353738 3.7327 0.213341 3.97502C0.0729437 4.21733 -0.000644661 4.49217 4.25481e-06 4.77178V11.1293C-0.000644661 11.4089 0.0729437 11.6837 0.213341 11.926C0.353738 12.1683 0.555974 12.3696 0.799635 12.5094L6.39705 15.6881C6.64017 15.8276 6.91595 15.901 7.19668 15.901C7.47741 15.901 7.75319 15.8276 7.99631 15.6881L13.5937 12.5094C13.8374 12.3696 14.0396 12.1683 14.18 11.926C14.3204 11.6837 14.394 11.4089 14.3934 11.1293V4.77178C14.394 4.49217 14.3204 4.21733 14.18 3.97502C14.0396 3.7327 13.8374 3.53148 13.5937 3.39168ZM12.7941 11.1293L7.19668 14.308L1.59927 11.1293V4.77178L7.19668 1.59304L12.7941 4.77178V11.1293Z"
        fill="#BABABA"
      />
      <path
        d="M6.39676 3.44733V5.1506C5.92975 5.28227 5.50295 5.52712 5.15466 5.86317L3.67268 5.01021L2.87305 6.38766L4.3577 7.24062C4.23496 7.70694 4.23496 8.19679 4.3577 8.66311L2.87305 9.51342L3.67268 10.8909L5.15733 10.0406C5.50515 10.3753 5.93097 10.6192 6.39676 10.7505V12.4538H7.99602V10.7505C8.46207 10.6184 8.88791 10.3736 9.23545 10.0379L10.7201 10.8882L11.5197 9.51077L10.0351 8.66046C10.1583 8.1933 10.1583 7.70248 10.0351 7.23532L11.5197 6.38501L10.7201 5.00756L9.23545 5.85787C8.88733 5.52412 8.46154 5.28114 7.99602 5.1506V3.44733H6.39676ZM8.5291 7.95054C8.5291 8.21249 8.45094 8.46857 8.3045 8.68638C8.15806 8.90419 7.94992 9.07395 7.7064 9.17419C7.46287 9.27444 7.19491 9.30067 6.93639 9.24956C6.67786 9.19846 6.4404 9.07231 6.25401 8.88708C6.06763 8.70185 5.9407 8.46585 5.88928 8.20893C5.83785 7.95201 5.86424 7.6857 5.96512 7.44368C6.06599 7.20167 6.2368 6.99481 6.45597 6.84928C6.67513 6.70374 6.9328 6.62606 7.19639 6.62606C7.54985 6.62606 7.88883 6.76561 8.13876 7.01399C8.38869 7.26238 8.5291 7.59927 8.5291 7.95054Z"
        fill="#202020"
      />
    </svg>
  )
}
export default SettingsIcon
