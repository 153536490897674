/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimeSlotDto } from './TimeSlotDto';
import {
    TimeSlotDtoFromJSON,
    TimeSlotDtoFromJSONTyped,
    TimeSlotDtoToJSON,
} from './TimeSlotDto';

/**
 * 
 * @export
 * @interface ScheduleOorCeremonyRequestDto
 */
export interface ScheduleOorCeremonyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof ScheduleOorCeremonyRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {TimeSlotDto}
     * @memberof ScheduleOorCeremonyRequestDto
     */
    timeSlot: TimeSlotDto;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduleOorCeremonyRequestDto
     */
    lars: Array<string>;
}

/**
 * Check if a given object implements the ScheduleOorCeremonyRequestDto interface.
 */
export function instanceOfScheduleOorCeremonyRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('timeSlot' in value)) return false;
    if (!('lars' in value)) return false;
    return true;
}

export function ScheduleOorCeremonyRequestDtoFromJSON(json: any): ScheduleOorCeremonyRequestDto {
    return ScheduleOorCeremonyRequestDtoFromJSONTyped(json, false);
}

export function ScheduleOorCeremonyRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScheduleOorCeremonyRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'timeSlot': TimeSlotDtoFromJSON(json['timeSlot']),
        'lars': json['lars'],
    };
}

export function ScheduleOorCeremonyRequestDtoToJSON(value?: ScheduleOorCeremonyRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'timeSlot': TimeSlotDtoToJSON(value['timeSlot']),
        'lars': value['lars'],
    };
}

