/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkflowTaskActorDto
 */
export interface WorkflowTaskActorDto {
    /**
     * 
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    workflowInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    userName?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    taskType?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkflowTaskActorDto
     */
    symbolicTextId?: string;
}

/**
 * Check if a given object implements the WorkflowTaskActorDto interface.
 */
export function instanceOfWorkflowTaskActorDto(value: object): boolean {
    return true;
}

export function WorkflowTaskActorDtoFromJSON(json: any): WorkflowTaskActorDto {
    return WorkflowTaskActorDtoFromJSONTyped(json, false);
}

export function WorkflowTaskActorDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkflowTaskActorDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'status': json['status'] == null ? undefined : json['status'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'userName': json['userName'] == null ? undefined : json['userName'],
        'taskType': json['taskType'] == null ? undefined : json['taskType'],
        'symbolicTextId': json['symbolicTextId'] == null ? undefined : json['symbolicTextId'],
    };
}

export function WorkflowTaskActorDtoToJSON(value?: WorkflowTaskActorDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'workflowInstanceId': value['workflowInstanceId'],
        'status': value['status'],
        'userId': value['userId'],
        'userName': value['userName'],
        'taskType': value['taskType'],
        'symbolicTextId': value['symbolicTextId'],
    };
}

