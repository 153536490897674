/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TitleAndGrantStatus } from './TitleAndGrantStatus';
import {
    TitleAndGrantStatusFromJSON,
    TitleAndGrantStatusFromJSONTyped,
    TitleAndGrantStatusToJSON,
} from './TitleAndGrantStatus';

/**
 * 
 * @export
 * @interface PossibleOorRecipientDto
 */
export interface PossibleOorRecipientDto {
    /**
     * 
     * @type {string}
     * @memberof PossibleOorRecipientDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof PossibleOorRecipientDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PossibleOorRecipientDto
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PossibleOorRecipientDto
     */
    emails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PossibleOorRecipientDto
     */
    orgName?: string;
    /**
     * 
     * @type {Array<TitleAndGrantStatus>}
     * @memberof PossibleOorRecipientDto
     */
    titleAndGrantStatusList?: Array<TitleAndGrantStatus>;
    /**
     * 
     * @type {boolean}
     * @memberof PossibleOorRecipientDto
     */
    recipient?: boolean;
}

/**
 * Check if a given object implements the PossibleOorRecipientDto interface.
 */
export function instanceOfPossibleOorRecipientDto(value: object): boolean {
    return true;
}

export function PossibleOorRecipientDtoFromJSON(json: any): PossibleOorRecipientDto {
    return PossibleOorRecipientDtoFromJSONTyped(json, false);
}

export function PossibleOorRecipientDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PossibleOorRecipientDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'emails': json['emails'] == null ? undefined : json['emails'],
        'orgName': json['orgName'] == null ? undefined : json['orgName'],
        'titleAndGrantStatusList': json['titleAndGrantStatusList'] == null ? undefined : ((json['titleAndGrantStatusList'] as Array<any>).map(TitleAndGrantStatusFromJSON)),
        'recipient': json['recipient'] == null ? undefined : json['recipient'],
    };
}

export function PossibleOorRecipientDtoToJSON(value?: PossibleOorRecipientDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'emails': value['emails'],
        'orgName': value['orgName'],
        'titleAndGrantStatusList': value['titleAndGrantStatusList'] == null ? undefined : ((value['titleAndGrantStatusList'] as Array<any>).map(TitleAndGrantStatusToJSON)),
        'recipient': value['recipient'],
    };
}

