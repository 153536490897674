import useStore from '@/state/store'
import useKeriaAgentSync from './useKeriaAgentSync'
import { useEffect } from 'react'
import constants from '@/config/constants'
import NotificationProcessor from '@/services/notificationProcessor'
import { AuthStatus } from '@/state/authentication'

const useNotificationProcessor = () => {
  const authentication = useStore((state) => state.authentication)
  useKeriaAgentSync()

  useEffect(() => {
    if (authentication.authStatus != AuthStatus.SignedIn) {
      return
    }
    const pollInterval = constants.NOTIFICATION_POLL_INTERVAL
    const notificationProcessor =
      NotificationProcessor.getInstance(pollInterval)
    const notificationSubscription = notificationProcessor
      .subscribeToNotifications()
      .subscribe((notification) => {
        console.log(JSON.stringify(notification))
      })
    //notificationProcessor.start()
    notificationProcessor.processOnce()
    return () => {
      notificationProcessor?.stop()
      notificationSubscription?.unsubscribe()
    }
  }, [authentication])
}

export default useNotificationProcessor
