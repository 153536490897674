/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WitnessDto
 */
export interface WitnessDto {
    /**
     * 
     * @type {string}
     * @memberof WitnessDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof WitnessDto
     */
    prefix?: string;
    /**
     * 
     * @type {string}
     * @memberof WitnessDto
     */
    url?: string;
}

/**
 * Check if a given object implements the WitnessDto interface.
 */
export function instanceOfWitnessDto(value: object): boolean {
    return true;
}

export function WitnessDtoFromJSON(json: any): WitnessDto {
    return WitnessDtoFromJSONTyped(json, false);
}

export function WitnessDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): WitnessDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'prefix': json['prefix'] == null ? undefined : json['prefix'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function WitnessDtoToJSON(value?: WitnessDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'prefix': value['prefix'],
        'url': value['url'],
    };
}

