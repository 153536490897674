import { StateCreator } from 'zustand'
import { AuthStatus } from '@/state/authentication'
import { IStore } from '@/state/store'

export type EnterpriseIdentifiers = {
  id: string
  aid: string
  alias: string
  oobi: string
  credentialRegistry: string
  status: string
  enterpriseIdentifiers: Enterprise
}

export type Enterprise = {
  id: string
  name: string
  legalEntityIdentifier: string
  keriAgentEndpoint: string
  status: string
  roleType: string
  enterpriseIdentifiers?: EnterpriseIdentifiers[]
}

export interface IEnterpriseStore {
  enterprises: Enterprise[]
  setEnterprises: (enterprises: Enterprise[]) => void
  selectedEnterprise?: Enterprise
  setSelectedEnterprise: (enterprise: Enterprise) => void
  allIdentifiers: EnterpriseIdentifiers[]
  setAllIdentifiers: (enterpriseIdentifiers: EnterpriseIdentifiers[]) => void
  resetEnterpriseStore: () => void
}

export enum Actions {
  SET_ENTERPRISES,
  SELECT_ENTERPRISE,
  SET_ALL_IDENTIFIERS
}

export type Payload = Enterprise[] | EnterpriseIdentifiers[] | Enterprise

type dispatchProps = {
  action: Actions
  payload: Payload
}

const reducer = (
  _state: IEnterpriseStore,
  { action, payload }: dispatchProps
) => {
  switch (action) {
    case Actions.SET_ENTERPRISES:
      return { enterprises: payload as Enterprise[] }
    case Actions.SELECT_ENTERPRISE:
      return { selectedEnterprise: payload as Enterprise }
    case Actions.SET_ALL_IDENTIFIERS:
      return { allIdentifiers: payload as EnterpriseIdentifiers[] }
  }
}

const initialState = {
  enterprises: [],
  allIdentifiers: []
}

const enterpriseStore: StateCreator<IStore, [], [], IEnterpriseStore> = (
  set
) => ({
  ...initialState,

  //   Actions
  setEnterprises: (enterprises: Enterprise[]) => {
    set(() => ({ enterprises }))
  },
  setSelectedEnterprise: (selectedEnterprise: Enterprise) => {
    set(() => ({ selectedEnterprise }))
  },
  setAllIdentifiers: (allIdentifiers: EnterpriseIdentifiers[]) => {
    set(() => ({ allIdentifiers }))
  },
  dispatch: (args: dispatchProps) => set((state) => reducer(state, args)),
  resetEnterpriseStore: () => {
    set(initialState)
  }
})

export default enterpriseStore
