/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface VerifiedPhotoRequest {
    userId: string;
}

/**
 * 
 */
export class IdVerificationPhotoControllerApi extends runtime.BaseAPI {

    /**
     */
    async verifiedPhotoRaw(requestParameters: VerifiedPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling verifiedPhoto().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/id-verification/{user-id}/photo`.replace(`{${"user-id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async verifiedPhoto(requestParameters: VerifiedPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifiedPhotoRaw(requestParameters, initOverrides);
    }

}
