import React from 'react'
import ErrorRound from '../icons/ErrorRound'
import VerifyRoundIcon from '../icons/VerifyRoundIcon'
import CloseIcon from '../icons/CloseIcon'

interface IProps {
  mode: 'SUCCESS' | 'INFO' | 'BLACK' | 'WARNING' | 'ERROR' | 'WHITE'
  children?: React.ReactNode
  onClose?: () => void
  placement?:
    | 'TOP-LEFT'
    | 'TOP-CENTER'
    | 'TOP-RIGHT'
    | 'BOTTOM-LEFT'
    | 'BOTTOM-CENTER'
    | 'BOTTOM-RIGHT'
}

const getPlacementClass = (placement: IProps['placement']) => {
  switch (placement) {
    case 'TOP-LEFT':
      return 'top-10 left-10'
    case 'TOP-CENTER':
      return 'top-10 left-1/2 transform -translate-x-1/2'
    case 'TOP-RIGHT':
      return 'top-10 right-10'
    case 'BOTTOM-LEFT':
      return 'bottom-10 left-10'
    case 'BOTTOM-CENTER':
      return 'bottom-10 left-1/2 transform -translate-x-1/2'
    case 'BOTTOM-RIGHT':
      return 'bottom-10 right-10'
    default:
      return 'top-10 left-1/2 transform -translate-x-1/2'
  }
}

function Toast({
  mode = 'SUCCESS',
  placement = 'TOP-CENTER',
  children,
  onClose
}: IProps) {
  const boxShadowStyle = {
    boxShadow:
      '0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)'
  }

  const modeClassNamesMap = new Map<IProps['mode'], string>([
    [
      'SUCCESS',
      'bg-secondaryGreen50 border-secondaryGreen100 text-secondaryGreen900'
    ],
    ['INFO', 'bg-blue-100 border-b-blue-200 text-royalBlue'],
    ['BLACK', 'bg-neutral-800 border-neutral-900 text-neutral-50'],
    ['WARNING', 'bg-orange-100 border-orange-200 text-SecondaryOrange900'],
    ['ERROR', 'bg-red-100 border-red-200 text-SecondaryRed900'],
    ['WHITE', 'bg-[#F9F9FA] border-neutral-50 text-neutralsProductInkNormal900']
  ])
  const modeClassNames = modeClassNamesMap.get(mode)

  return (
    <div
      className={`fixed z-[9999] border rounded-md px-4 py-3 text-base font-medium ${getPlacementClass(
        placement
      )} max-w-[95vw] ${modeClassNames}`}
      style={boxShadowStyle}
    >
      <div className="flex gap-2 items-center">
        {mode === 'SUCCESS' && <VerifyRoundIcon mode="success" />}
        {mode === 'ERROR' && <ErrorRound weight="bold" />}
        <div>{children}</div>
        {onClose && (
          <button onClick={onClose}>
            <CloseIcon
              className={`w-4 h-4 ml-2 hover:text-red-900 transition-all duration-500 ${modeClassNames}`}
            ></CloseIcon>
          </button>
        )}
      </div>
    </div>
  )
}

export default Toast
