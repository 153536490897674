import React from 'react'

interface IProps {
  size?: number
  color?: string
}

const ConnectionIcon = ({ size = 15, color = '#000' }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.799635 12.5094L6.39705 15.6881C6.64017 15.8276 6.91595 15.901 7.19668 15.901C7.47741 15.901 7.75319 15.8276 7.99631 15.6881L13.5937 12.5094C13.8374 12.3696 14.0396 12.1683 14.18 11.926C14.3204 11.6837 14.394 11.4089 14.3934 11.1293V4.77178C14.394 4.49216 14.3204 4.21733 14.18 3.97501C14.0396 3.7327 13.8374 3.53148 13.5937 3.39167L7.99631 0.212935C7.75319 0.073439 7.47741 0 7.19668 0C6.91595 0 6.64017 0.073439 6.39705 0.212935L0.799635 3.39167C0.555974 3.53148 0.353738 3.7327 0.213341 3.97501C0.0729437 4.21733 -0.000644661 4.49216 4.25481e-06 4.77178V11.1293C-0.000644661 11.4089 0.0729437 11.6837 0.213341 11.926C0.353738 12.1683 0.555974 12.3696 0.799635 12.5094V12.5094ZM1.59927 4.77178L7.19668 1.59304L12.7941 4.77178V11.1293L7.19668 14.308L1.59927 11.1293V4.77178Z"
        fill={color}
      />
      <path
        d="M9.64 8C9.64 9.19885 8.59053 10.25 7.195 10.25C5.79947 10.25 4.75 9.19885 4.75 8C4.75 6.80115 5.79947 5.75 7.195 5.75C8.59053 5.75 9.64 6.80115 9.64 8Z"
        fill={color}
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  )
}
export default ConnectionIcon
