/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KeriaAgentDto
 */
export interface KeriaAgentDto {
    /**
     * 
     * @type {string}
     * @memberof KeriaAgentDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof KeriaAgentDto
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof KeriaAgentDto
     */
    providerName?: string;
    /**
     * 
     * @type {string}
     * @memberof KeriaAgentDto
     */
    agentURL?: string;
    /**
     * 
     * @type {string}
     * @memberof KeriaAgentDto
     */
    agentBootURL?: string;
}

/**
 * Check if a given object implements the KeriaAgentDto interface.
 */
export function instanceOfKeriaAgentDto(value: object): boolean {
    return true;
}

export function KeriaAgentDtoFromJSON(json: any): KeriaAgentDto {
    return KeriaAgentDtoFromJSONTyped(json, false);
}

export function KeriaAgentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeriaAgentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
        'providerName': json['providerName'] == null ? undefined : json['providerName'],
        'agentURL': json['agentURL'] == null ? undefined : json['agentURL'],
        'agentBootURL': json['agentBootURL'] == null ? undefined : json['agentBootURL'],
    };
}

export function KeriaAgentDtoToJSON(value?: KeriaAgentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'displayName': value['displayName'],
        'providerName': value['providerName'],
        'agentURL': value['agentURL'],
        'agentBootURL': value['agentBootURL'],
    };
}

