import { getUserDetails } from '@/services/user'
import useStore from '@/state/store'
import { useEffect, useState } from 'react'

const useUserDetails = () => {
  const { setLoginUser, killSession, clearSignify, authentication } = useStore()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    ;(async () => {
      try {
        const userDetails = await getUserDetails()
        userDetails.grants = userDetails.grants.map((g) => g.toUpperCase())
        setLoginUser(userDetails)
      } catch (ex) {
        killSession()
        clearSignify()
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return { loading, authentication }
}

export default useUserDetails
