/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QarOorVerificationRequestDto
 */
export interface QarOorVerificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof QarOorVerificationRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof QarOorVerificationRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {boolean}
     * @memberof QarOorVerificationRequestDto
     */
    approval: boolean;
}

/**
 * Check if a given object implements the QarOorVerificationRequestDto interface.
 */
export function instanceOfQarOorVerificationRequestDto(value: object): boolean {
    if (!('orgId' in value)) return false;
    if (!('workflowInstanceId' in value)) return false;
    if (!('approval' in value)) return false;
    return true;
}

export function QarOorVerificationRequestDtoFromJSON(json: any): QarOorVerificationRequestDto {
    return QarOorVerificationRequestDtoFromJSONTyped(json, false);
}

export function QarOorVerificationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QarOorVerificationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'orgId': json['orgId'],
        'workflowInstanceId': json['workflowInstanceId'],
        'approval': json['approval'],
    };
}

export function QarOorVerificationRequestDtoToJSON(value?: QarOorVerificationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orgId': value['orgId'],
        'workflowInstanceId': value['workflowInstanceId'],
        'approval': value['approval'],
    };
}

