import constants from '@/config/constants'
import { get } from '@/utils/http'

export type MeetingDetails = {
  title: string
  token: string
  roomName: string
  startTime: string
  endTime: string
  lei: string
}

export const getMeetingByID = async (
  meetId: string
): Promise<MeetingDetails> => {
  return await get(`/${meetId}`, {
    BASE_URL: constants.WEBMEET_URL
  })
}
