/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserPhoneNumberDto,
} from '../models/index';
import {
    UserPhoneNumberDtoFromJSON,
    UserPhoneNumberDtoToJSON,
} from '../models/index';

export interface DeleteRequest {
    userId: string;
    countryCode: string;
    value: string;
}

export interface GetUserPhoneNumbersRequest {
    userId: string;
}

export interface NewPhoneNumberRequest {
    userPhoneNumberDto: UserPhoneNumberDto;
}

/**
 * 
 */
export class PhoneNumberControllerApi extends runtime.BaseAPI {

    /**
     */
    async _deleteRaw(requestParameters: DeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPhoneNumberDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling _delete().'
            );
        }

        if (requestParameters['countryCode'] == null) {
            throw new runtime.RequiredError(
                'countryCode',
                'Required parameter "countryCode" was null or undefined when calling _delete().'
            );
        }

        if (requestParameters['value'] == null) {
            throw new runtime.RequiredError(
                'value',
                'Required parameter "value" was null or undefined when calling _delete().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/phone-number/{user_id}/{country_code}/{value}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"country_code"}}`, encodeURIComponent(String(requestParameters['countryCode']))).replace(`{${"value"}}`, encodeURIComponent(String(requestParameters['value']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPhoneNumberDtoFromJSON(jsonValue));
    }

    /**
     */
    async _delete(requestParameters: DeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPhoneNumberDto> {
        const response = await this._deleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserPhoneNumbersRaw(requestParameters: GetUserPhoneNumbersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserPhoneNumberDto>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getUserPhoneNumbers().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/phone-number/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserPhoneNumberDtoFromJSON));
    }

    /**
     */
    async getUserPhoneNumbers(requestParameters: GetUserPhoneNumbersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserPhoneNumberDto>> {
        const response = await this.getUserPhoneNumbersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async newPhoneNumberRaw(requestParameters: NewPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPhoneNumberDto>> {
        if (requestParameters['userPhoneNumberDto'] == null) {
            throw new runtime.RequiredError(
                'userPhoneNumberDto',
                'Required parameter "userPhoneNumberDto" was null or undefined when calling newPhoneNumber().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/phone-number/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPhoneNumberDtoToJSON(requestParameters['userPhoneNumberDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPhoneNumberDtoFromJSON(jsonValue));
    }

    /**
     */
    async newPhoneNumber(requestParameters: NewPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPhoneNumberDto> {
        const response = await this.newPhoneNumberRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
