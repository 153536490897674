/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrgResearchRequestDto,
} from '../models/index';
import {
    OrgResearchRequestDtoFromJSON,
    OrgResearchRequestDtoToJSON,
} from '../models/index';

export interface BoundOrgResearchRequest {
    orgResearch: string;
    org: string;
}

export interface DeleteOrgResearchRequest {
    orgResearchId: string;
}

export interface FindById2Request {
    orgResearchId: string;
}

export interface FindByOrgId2Request {
    orgId: string;
}

export interface SaveByIdRequest {
    orgResearchId: string;
    orgResearchRequestDto: OrgResearchRequestDto;
}

/**
 * 
 */
export class OrgResearchControllerApi extends runtime.BaseAPI {

    /**
     * Bounds org-research with some registered org
     */
    async boundOrgResearchRaw(requestParameters: BoundOrgResearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['orgResearch'] == null) {
            throw new runtime.RequiredError(
                'orgResearch',
                'Required parameter "orgResearch" was null or undefined when calling boundOrgResearch().'
            );
        }

        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling boundOrgResearch().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['orgResearch'] != null) {
            queryParameters['org-research'] = requestParameters['orgResearch'];
        }

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/researched-org/linkage`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bounds org-research with some registered org
     */
    async boundOrgResearch(requestParameters: BoundOrgResearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.boundOrgResearchRaw(requestParameters, initOverrides);
    }

    /**
     * Delete org research parameters for org_research_id
     */
    async deleteOrgResearchRaw(requestParameters: DeleteOrgResearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['orgResearchId'] == null) {
            throw new runtime.RequiredError(
                'orgResearchId',
                'Required parameter "orgResearchId" was null or undefined when calling deleteOrgResearch().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/researched-org/{org_research_id}`.replace(`{${"org_research_id"}}`, encodeURIComponent(String(requestParameters['orgResearchId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Delete org research parameters for org_research_id
     */
    async deleteOrgResearch(requestParameters: DeleteOrgResearchRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.deleteOrgResearchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get org research parameters for org_research_id
     */
    async findById2Raw(requestParameters: FindById2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgResearchRequestDto>> {
        if (requestParameters['orgResearchId'] == null) {
            throw new runtime.RequiredError(
                'orgResearchId',
                'Required parameter "orgResearchId" was null or undefined when calling findById2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/researched-org/{org_research_id}`.replace(`{${"org_research_id"}}`, encodeURIComponent(String(requestParameters['orgResearchId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgResearchRequestDtoFromJSON(jsonValue));
    }

    /**
     * Get org research parameters for org_research_id
     */
    async findById2(requestParameters: FindById2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgResearchRequestDto> {
        const response = await this.findById2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get org research that is associated with the org_id
     */
    async findByOrgId2Raw(requestParameters: FindByOrgId2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgResearchRequestDto>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling findByOrgId2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/researched-org/org/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgResearchRequestDtoFromJSON(jsonValue));
    }

    /**
     * Get org research that is associated with the org_id
     */
    async findByOrgId2(requestParameters: FindByOrgId2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgResearchRequestDto> {
        const response = await this.findByOrgId2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Save org research parameters for org_research_id
     */
    async saveByIdRaw(requestParameters: SaveByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['orgResearchId'] == null) {
            throw new runtime.RequiredError(
                'orgResearchId',
                'Required parameter "orgResearchId" was null or undefined when calling saveById().'
            );
        }

        if (requestParameters['orgResearchRequestDto'] == null) {
            throw new runtime.RequiredError(
                'orgResearchRequestDto',
                'Required parameter "orgResearchRequestDto" was null or undefined when calling saveById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/researched-org/{org_research_id}`.replace(`{${"org_research_id"}}`, encodeURIComponent(String(requestParameters['orgResearchId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrgResearchRequestDtoToJSON(requestParameters['orgResearchRequestDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Save org research parameters for org_research_id
     */
    async saveById(requestParameters: SaveByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveByIdRaw(requestParameters, initOverrides);
    }

}
