import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '@/components/base/Button'
import RecipientTextInput from './recipient-text-input'
import { useOorWorkflowStore } from '../store'
import { OORRecipient } from '../types'
import { OorTitle } from '../api'
import { useState } from 'react'
import OorWorkflowTask from '@/types/oor-workflow-task'
import useStore from '@/state/store'
import { Spinner } from 'phosphor-react'

interface IProps {
  recipient: OORRecipient
  index: number
  isReadOnlyMode: boolean
  oorTitles: OorTitle[]
  updateRecipients: Function
}

const RecipientCard = ({
  recipient,
  index,
  isReadOnlyMode,
  oorTitles,
  updateRecipients,
  ...props
}: IProps) => {
  const { formatMessage } = useIntl()
  const [isEditable, setIsEditable] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {
    authentication: { userInfo }
  } = useStore()

  const { workflow } = useOorWorkflowStore()
  const { deleteRecipient, updateRecipient } = useOorWorkflowStore()

  const handleInputChange =
    (field: keyof OORRecipient) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      updateRecipient(index, { ...recipient, [field]: event.target.value })
    }

  const handleRemoveRecipient = () => {
    deleteRecipient(index)
  }

  const isQARVerified = () => {
    const qarVerificationTask = workflow.findTaskByType(
      OorWorkflowTask.QAR_ORG_VERIFICATION
    )
    return qarVerificationTask?.isTaskCompleted()
  }

  const isCurrentUser = () => {
    return userInfo?.emails?.some((email) => email.value === recipient?.email)
  }

  const handleEditRecipient = () => {
    setIsEditable(true)
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      await updateRecipients()
      setIsEditable(false)
    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className="representative-box" {...props}>
        {/* Input fields ... */}
        <div className="mt-6 border rounded-lg shadow-md p-4 bg-grey125">
          <div className="flex justify-between  border-b border-grey200">
            <h3 className="text-lg font-medium">
              {index + 1}
              {'. '}
              {formatMessage({
                id: 'oor.task.recipient.proposedOOR.title'
              })}
            </h3>

            <div className="flex gap-2 mb-1">
              <Button
                mode="danger"
                size="small"
                disabled={isReadOnlyMode}
                label={<FormattedMessage id="cta.delete" />}
                onClick={handleRemoveRecipient}
              />
              {!isQARVerified() && isReadOnlyMode && (
                <Button
                  mode="tertiary"
                  size="small"
                  label={<FormattedMessage id="cta.edit" />}
                  onClick={handleEditRecipient}
                />
              )}
            </div>
          </div>
          <form className="grid gap-4 md:grid-cols-2 mt-2">
            {/* Row 1 */}
            <RecipientTextInput
              id="input.defineRepresentatives.firstName"
              htmlFor="firstName"
              placeholder={formatMessage({
                id: 'oor.task.recipient.input.firstName.placeholder'
              })}
              value={recipient.firstName}
              disabled={(isReadOnlyMode || isCurrentUser()) && !isEditable}
              onChange={handleInputChange('firstName')}
              error={
                recipient.error?.firstName ? recipient.error?.firstName : ''
              }
            />
            <RecipientTextInput
              id="input.defineRepresentatives.lastName"
              htmlFor="lastName"
              placeholder={formatMessage({
                id: 'oor.task.recipient.input.lastName.placeholder'
              })}
              value={recipient.lastName}
              disabled={(isReadOnlyMode || isCurrentUser()) && !isEditable}
              onChange={handleInputChange('lastName')}
              error={recipient.error?.lastName ? recipient.error?.lastName : ''}
            />

            {/* Row 2 */}
            <RecipientTextInput
              id="input.defineRepresentatives.email"
              htmlFor="email"
              placeholder={formatMessage({
                id: 'oor.task.recipient.input.email.placeholder'
              })}
              value={recipient.email}
              disabled={(isReadOnlyMode || isCurrentUser()) && !isEditable}
              onChange={handleInputChange('email')}
              error={recipient.error?.email ? recipient.error?.email : ''}
            />

            <RecipientTextInput
              id="input.defineRepresentatives.companyName"
              htmlFor="orgName"
              placeholder={formatMessage({
                id: 'oor.task.recipient.input.orgName.placeholder'
              })}
              value={recipient.orgName}
              disabled={(isReadOnlyMode || isCurrentUser()) && !isEditable}
              onChange={handleInputChange('orgName')}
              error={recipient.error?.orgName ? recipient.error?.orgName : ''}
            />

            <RecipientTextInput
              id="oor.task.recipient.input.oor.label"
              htmlFor="role"
              placeholder={formatMessage({
                id: 'oor.task.recipient.input.oorTitle.placeholder'
              })}
              value={recipient.oorTitle}
              disabled={isReadOnlyMode}
              onChange={handleInputChange('oorTitle')}
              error={recipient.error?.oorTitle ? recipient.error?.oorTitle : ''}
            />
          </form>
          {isEditable && (
            <div className="flex justify-end">
              <div className="flex gap-2">
                <Button
                  mode="tertiary"
                  size="small"
                  label={<FormattedMessage id="cta.cancel" />}
                  onClick={() => {
                    setIsEditable(false)
                  }}
                />
                <Button
                  mode="primary"
                  size="small"
                  label={<FormattedMessage id="cta.save" />}
                  iconLeft={isLoading && <Spinner />}
                  onClick={handleSave}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default RecipientCard
