import { StateCreator } from 'zustand'
import { IStore } from '@/state/store'
import { Identifier } from '@/state/signify'

export interface IIdentifierStore {
  identifiers: Identifier[]
  setIdentifiers: (identifiers: Identifier[]) => void
  selectedIdentifier?: Identifier
  setSelectedIdentifier: (identifier: Identifier) => void
  resetIdentifierStore: () => void
}

export enum Actions {
  SET_IDENTIFIERS,
  SELECT_IDENTIFIER
}

export type Payload = Identifier[] | Identifier

type dispatchProps = {
  action: Actions
  payload: Payload
}

// // // https://github.com/pmndrs/zustand/tree/2b29d736841dc7b3fd7dec8cbfea50fee7295974#cant-live-without-redux-like-reducers-and-action-types
const reducer = (
  _state: IIdentifierStore,
  { action, payload }: dispatchProps
) => {
  switch (action) {
    case Actions.SET_IDENTIFIERS:
      return { identifiers: payload as Identifier[] }
    case Actions.SELECT_IDENTIFIER:
      return { selectedIdentifier: payload as Identifier }
  }
}

const initialState = {
  identifiers: []
}

const identifierStore: StateCreator<IStore, [], [], IIdentifierStore> = (
  set
) => ({
  ...initialState,
  //   Actions
  setIdentifiers: (identifiers: Identifier[]) => {
    set(() => ({ identifiers }))
  },
  setSelectedIdentifier: (selectedIdentifier: Identifier) => {
    set(() => ({ selectedIdentifier }))
  },
  dispatch: (args: dispatchProps) => set((state) => reducer(state, args)),
  resetIdentifierStore: () => {
    set(initialState)
  }
})

export default identifierStore
