/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OorRemovalRequestDto
 */
export interface OorRemovalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof OorRemovalRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof OorRemovalRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof OorRemovalRequestDto
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof OorRemovalRequestDto
     */
    oorTitle: string;
}

/**
 * Check if a given object implements the OorRemovalRequestDto interface.
 */
export function instanceOfOorRemovalRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('orgId' in value)) return false;
    if (!('userId' in value)) return false;
    if (!('oorTitle' in value)) return false;
    return true;
}

export function OorRemovalRequestDtoFromJSON(json: any): OorRemovalRequestDto {
    return OorRemovalRequestDtoFromJSONTyped(json, false);
}

export function OorRemovalRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OorRemovalRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'orgId': json['orgId'],
        'userId': json['userId'],
        'oorTitle': json['oorTitle'],
    };
}

export function OorRemovalRequestDtoToJSON(value?: OorRemovalRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'orgId': value['orgId'],
        'userId': value['userId'],
        'oorTitle': value['oorTitle'],
    };
}

