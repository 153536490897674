/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IdVerificationWebhookForm,
} from '../models/index';
import {
    IdVerificationWebhookFormFromJSON,
    IdVerificationWebhookFormToJSON,
} from '../models/index';

export interface WebhookFormRequest {
    idVerificationWebhookForm: IdVerificationWebhookForm;
}

/**
 * 
 */
export class WebhookControllerApi extends runtime.BaseAPI {

    /**
     */
    async webhookFormRaw(requestParameters: WebhookFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['idVerificationWebhookForm'] == null) {
            throw new runtime.RequiredError(
                'idVerificationWebhookForm',
                'Required parameter "idVerificationWebhookForm" was null or undefined when calling webhookForm().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['idVerificationWebhookForm'] != null) {
            queryParameters['idVerificationWebhookForm'] = requestParameters['idVerificationWebhookForm'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/id-verification/webhook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async webhookForm(requestParameters: WebhookFormRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.webhookFormRaw(requestParameters, initOverrides);
    }

}
