import React, { Component } from 'react'

export class Provenant extends Component {
  render() {
    return (
      <>
        <svg
          width="113"
          height="18"
          viewBox="0 0 112 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.2632 14.4765V3.51991H23.5014C24.0351 3.51991 24.5113 3.60363 24.9299 3.77106C25.3484 3.92803 25.699 4.15826 25.9816 4.46174C26.2746 4.76521 26.4943 5.13148 26.6408 5.56054C26.7873 5.98959 26.8606 6.47097 26.8606 7.00467C26.8606 7.53838 26.7873 8.01976 26.6408 8.44881C26.4943 8.87787 26.2746 9.24413 25.9816 9.54761C25.699 9.85109 25.3484 10.0865 24.9299 10.254C24.5113 10.411 24.0351 10.4894 23.5014 10.4894H21.6335V14.4765H19.2632ZM21.6335 8.59008H22.9049C23.4596 8.59008 23.8415 8.48544 24.0508 8.27614C24.2706 8.06685 24.3805 7.72151 24.3805 7.24013V6.76922C24.3805 6.28784 24.2706 5.9425 24.0508 5.73321C23.8415 5.52391 23.4596 5.41926 22.9049 5.41926H21.6335V8.59008Z"
            fill="white"
          />
          <path
            d="M32.1267 14.4765H29.8036V3.51991H34.0418C34.5755 3.51991 35.0516 3.60363 35.4702 3.77106C35.8888 3.92803 36.2394 4.15826 36.5219 4.46174C36.815 4.76521 37.0347 5.13148 37.1812 5.56054C37.3277 5.98959 37.401 6.47097 37.401 7.00467C37.401 7.77907 37.2335 8.44358 36.8987 8.99821C36.5638 9.55284 36.0877 9.91911 35.4702 10.097L37.6207 14.4765H35.0464L33.2098 10.411H32.1267V14.4765ZM33.4767 8.59008C34.0313 8.59008 34.4133 8.48544 34.6226 8.27614C34.8424 8.06685 34.9522 7.72151 34.9522 7.24013V6.76922C34.9522 6.28784 34.8424 5.9425 34.6226 5.73321C34.4133 5.52391 34.0313 5.41926 33.4767 5.41926H32.1267V8.59008H33.4767Z"
            fill="white"
          />
          <path
            d="M43.8601 14.6649C42.395 14.6649 41.3119 14.1678 40.6108 13.1736C39.9201 12.1795 39.5748 10.7877 39.5748 8.99821C39.5748 7.20874 39.9201 5.81692 40.6108 4.82277C41.3119 3.82862 42.395 3.33154 43.8601 3.33154C45.3252 3.33154 46.403 3.82862 47.0937 4.82277C47.7949 5.81692 48.1454 7.20874 48.1454 8.99821C48.1454 10.7877 47.7949 12.1795 47.0937 13.1736C46.403 14.1678 45.3252 14.6649 43.8601 14.6649ZM43.8601 12.7655C44.5299 12.7655 44.9903 12.5196 45.2415 12.0278C45.5031 11.5254 45.6339 10.8295 45.6339 9.94004V8.04068C45.6339 7.16164 45.5031 6.4762 45.2415 5.98436C44.9903 5.48205 44.5299 5.2309 43.8601 5.2309C43.1904 5.2309 42.7247 5.48205 42.4631 5.98436C42.2119 6.4762 42.0863 7.16164 42.0863 8.04068V9.95573C42.0863 10.8348 42.2119 11.5254 42.4631 12.0278C42.7247 12.5196 43.1904 12.7655 43.8601 12.7655Z"
            fill="white"
          />
          <path
            d="M52.7366 14.4765L49.8326 3.51991H52.454L53.7412 9.09239L54.3848 12.2475H54.5104L55.1697 9.09239L56.4725 3.51991H58.9684L56.0644 14.4765H52.7366Z"
            fill="white"
          />
          <path
            d="M61.4875 14.4765V3.51991H68.4884V5.45066H63.8421V7.9622H68.3315V9.89295H63.8421V12.5458H68.4884V14.4765H61.4875Z"
            fill="white"
          />
          <path
            d="M73.9116 7.19304H73.7389V14.4765H71.7296V3.51991H74.5551L77.051 10.8034H77.2236V3.51991H79.2329V14.4765H76.4074L73.9116 7.19304Z"
            fill="white"
          />
          <path
            d="M88.1408 14.4765L87.4501 11.8394H84.4519L83.777 14.4765H81.391L84.3577 3.51991H87.6855L90.6523 14.4765H88.1408ZM86.0217 5.79599H85.8961L84.8287 9.94004H87.0891L86.0217 5.79599Z"
            fill="white"
          />
          <path
            d="M94.9923 7.19304H94.8197V14.4765H92.8104V3.51991H95.6359L98.1318 10.8034H98.3044V3.51991H100.314V14.4765H97.4882L94.9923 7.19304Z"
            fill="white"
          />
          <path
            d="M108.28 5.45066V14.4765H105.925V5.45066H102.77V3.51991H111.435V5.45066H108.28Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 2.33333C5.3181 2.33333 2.33333 5.3181 2.33333 9C2.33333 12.6819 5.3181 15.6667 9 15.6667V18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0V2.33333Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.80183 8.92708L10.5696 7.15932L8.91968 5.5094L5.50199 8.92708L8.91968 12.3448L10.5696 10.6949L8.80183 8.92708Z"
            fill="white"
          />
        </svg>
      </>
    )
  }
}

export default Provenant
