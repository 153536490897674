/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListOfLocalizedTextsResponseDto
 */
export interface ListOfLocalizedTextsResponseDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ListOfLocalizedTextsResponseDto
     */
    symbolicTextIdToLocalizedText?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ListOfLocalizedTextsResponseDto
     */
    lang?: string;
}

/**
 * Check if a given object implements the ListOfLocalizedTextsResponseDto interface.
 */
export function instanceOfListOfLocalizedTextsResponseDto(value: object): boolean {
    return true;
}

export function ListOfLocalizedTextsResponseDtoFromJSON(json: any): ListOfLocalizedTextsResponseDto {
    return ListOfLocalizedTextsResponseDtoFromJSONTyped(json, false);
}

export function ListOfLocalizedTextsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListOfLocalizedTextsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'symbolicTextIdToLocalizedText': json['symbolicTextIdToLocalizedText'] == null ? undefined : json['symbolicTextIdToLocalizedText'],
        'lang': json['lang'] == null ? undefined : json['lang'],
    };
}

export function ListOfLocalizedTextsResponseDtoToJSON(value?: ListOfLocalizedTextsResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'symbolicTextIdToLocalizedText': value['symbolicTextIdToLocalizedText'],
        'lang': value['lang'],
    };
}

