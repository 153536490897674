/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PossibleOorRecipientDto } from './PossibleOorRecipientDto';
import {
    PossibleOorRecipientDtoFromJSON,
    PossibleOorRecipientDtoFromJSONTyped,
    PossibleOorRecipientDtoToJSON,
} from './PossibleOorRecipientDto';

/**
 * 
 * @export
 * @interface PossibleOorRecipientsResponseDto
 */
export interface PossibleOorRecipientsResponseDto {
    /**
     * 
     * @type {Array<PossibleOorRecipientDto>}
     * @memberof PossibleOorRecipientsResponseDto
     */
    recipients?: Array<PossibleOorRecipientDto>;
}

/**
 * Check if a given object implements the PossibleOorRecipientsResponseDto interface.
 */
export function instanceOfPossibleOorRecipientsResponseDto(value: object): boolean {
    return true;
}

export function PossibleOorRecipientsResponseDtoFromJSON(json: any): PossibleOorRecipientsResponseDto {
    return PossibleOorRecipientsResponseDtoFromJSONTyped(json, false);
}

export function PossibleOorRecipientsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PossibleOorRecipientsResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'recipients': json['recipients'] == null ? undefined : ((json['recipients'] as Array<any>).map(PossibleOorRecipientDtoFromJSON)),
    };
}

export function PossibleOorRecipientsResponseDtoToJSON(value?: PossibleOorRecipientsResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'recipients': value['recipients'] == null ? undefined : ((value['recipients'] as Array<any>).map(PossibleOorRecipientDtoToJSON)),
    };
}

