/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfirmPassword
 */
export interface ConfirmPassword {
    /**
     * 
     * @type {string}
     * @memberof ConfirmPassword
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPassword
     */
    confirmationCode: string;
    /**
     * 
     * @type {string}
     * @memberof ConfirmPassword
     */
    password?: string;
}

/**
 * Check if a given object implements the ConfirmPassword interface.
 */
export function instanceOfConfirmPassword(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('confirmationCode' in value)) return false;
    return true;
}

export function ConfirmPasswordFromJSON(json: any): ConfirmPassword {
    return ConfirmPasswordFromJSONTyped(json, false);
}

export function ConfirmPasswordFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfirmPassword {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'confirmationCode': json['confirmation_code'],
        'password': json['password'] == null ? undefined : json['password'],
    };
}

export function ConfirmPasswordToJSON(value?: ConfirmPassword | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'confirmation_code': value['confirmationCode'],
        'password': value['password'],
    };
}

