/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddClientToServiceProviderRequest,
  OrgDto,
} from '../models/index';
import {
    AddClientToServiceProviderRequestFromJSON,
    AddClientToServiceProviderRequestToJSON,
    OrgDtoFromJSON,
    OrgDtoToJSON,
} from '../models/index';

export interface AddClientToServiceProviderOperationRequest {
    addClientToServiceProviderRequest: AddClientToServiceProviderRequest;
}

export interface FindClientsForServiceProviderRequest {
    org: string;
    serviceType: string;
}

export interface RemoveClientFromServiceProviderRequest {
    org: string;
    serviceProviderOrg: string;
    serviceType: string;
}

/**
 * 
 */
export class ClientControllerApi extends runtime.BaseAPI {

    /**
     * Adds a client to the service provider.
     */
    async addClientToServiceProviderRaw(requestParameters: AddClientToServiceProviderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['addClientToServiceProviderRequest'] == null) {
            throw new runtime.RequiredError(
                'addClientToServiceProviderRequest',
                'Required parameter "addClientToServiceProviderRequest" was null or undefined when calling addClientToServiceProvider().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/client/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddClientToServiceProviderRequestToJSON(requestParameters['addClientToServiceProviderRequest']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Adds a client to the service provider.
     */
    async addClientToServiceProvider(requestParameters: AddClientToServiceProviderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.addClientToServiceProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find clients by service provider\'s orgId and serviceType
     */
    async findClientsForServiceProviderRaw(requestParameters: FindClientsForServiceProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrgDto>>> {
        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling findClientsForServiceProvider().'
            );
        }

        if (requestParameters['serviceType'] == null) {
            throw new runtime.RequiredError(
                'serviceType',
                'Required parameter "serviceType" was null or undefined when calling findClientsForServiceProvider().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['serviceType'] != null) {
            queryParameters['service-type'] = requestParameters['serviceType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/client/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrgDtoFromJSON));
    }

    /**
     * Find clients by service provider\'s orgId and serviceType
     */
    async findClientsForServiceProvider(requestParameters: FindClientsForServiceProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrgDto> | null | undefined > {
        const response = await this.findClientsForServiceProviderRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Removes a client from the service provider.
     */
    async removeClientFromServiceProviderRaw(requestParameters: RemoveClientFromServiceProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling removeClientFromServiceProvider().'
            );
        }

        if (requestParameters['serviceProviderOrg'] == null) {
            throw new runtime.RequiredError(
                'serviceProviderOrg',
                'Required parameter "serviceProviderOrg" was null or undefined when calling removeClientFromServiceProvider().'
            );
        }

        if (requestParameters['serviceType'] == null) {
            throw new runtime.RequiredError(
                'serviceType',
                'Required parameter "serviceType" was null or undefined when calling removeClientFromServiceProvider().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        if (requestParameters['serviceProviderOrg'] != null) {
            queryParameters['service-provider-org'] = requestParameters['serviceProviderOrg'];
        }

        if (requestParameters['serviceType'] != null) {
            queryParameters['service-type'] = requestParameters['serviceType'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/client`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Removes a client from the service provider.
     */
    async removeClientFromServiceProvider(requestParameters: RemoveClientFromServiceProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.removeClientFromServiceProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
