/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetAllUserIdsByOrgRequest {
    org: string;
}

/**
 * 
 */
export class UserIdControllerApi extends runtime.BaseAPI {

    /**
     */
    async getAllUserIdsByOrgRaw(requestParameters: GetAllUserIdsByOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['org'] == null) {
            throw new runtime.RequiredError(
                'org',
                'Required parameter "org" was null or undefined when calling getAllUserIdsByOrg().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['org'] != null) {
            queryParameters['org'] = requestParameters['org'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/user-id/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAllUserIdsByOrg(requestParameters: GetAllUserIdsByOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAllUserIdsByOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
