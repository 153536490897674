/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OorTitleDto
 */
export interface OorTitleDto {
    /**
     * 
     * @type {string}
     * @memberof OorTitleDto
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof OorTitleDto
     */
    shortTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof OorTitleDto
     */
    elfCode?: string;
    /**
     * 
     * @type {string}
     * @memberof OorTitleDto
     */
    country?: string;
}

/**
 * Check if a given object implements the OorTitleDto interface.
 */
export function instanceOfOorTitleDto(value: object): boolean {
    return true;
}

export function OorTitleDtoFromJSON(json: any): OorTitleDto {
    return OorTitleDtoFromJSONTyped(json, false);
}

export function OorTitleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OorTitleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'] == null ? undefined : json['title'],
        'shortTitle': json['shortTitle'] == null ? undefined : json['shortTitle'],
        'elfCode': json['elfCode'] == null ? undefined : json['elfCode'],
        'country': json['country'] == null ? undefined : json['country'],
    };
}

export function OorTitleDtoToJSON(value?: OorTitleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'shortTitle': value['shortTitle'],
        'elfCode': value['elfCode'],
        'country': value['country'],
    };
}

