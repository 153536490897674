import constants from '@/config/constants'
import {
  ICampaignCredentials,
  ITNCredential,
  IVettingCredential,
  VLEICredential
} from '@/state/credentials'
import { EnterpriseIdentifiers } from '@/state/enterprise'
import { generateAvatar } from '@/utils/avatar'
import { ICredentials } from '@/types/SignifyTypes'
import { getSchemaName } from '@/services/wallet'

const { format } = require('date-fns')

const prepareChainData = (schema: any, allIdentifiers: any[]) => {
  const chains: any[] = []
  if (schema.chains) {
    for (const chain of schema.chains) {
      // const issuedBy = constants.AID_NAMES.find(
      //   (aid_name) => aid_name.aid === chain?.sad?.i
      // )?.name;
      const issuedBy = allIdentifiers.find(
        (aid) => aid.aid === chain?.sad?.i
      )?.alias
      const issuedTo = allIdentifiers.find(
        (aid) => aid.aid === chain?.sad?.a?.i
      )?.alias
      let schema = chain.sad.s
      if (chain.sad.s === constants.CREDENTIAL_TYPES.LEI) {
        schema = 'Legal Entity vLEI Credential'
      } else if (chain.sad.s === constants.CREDENTIAL_TYPES.QVI) {
        schema = 'Qualified vLEI Issuer Credential'
      }

      chains.push(
        {
          LEI: chain.sad?.a?.LEI,
          schemaType: schema,
          issuedDate: format(
            new Date(chain?.sad?.a?.dt),
            constants.DATE_FORMAT
          ),
          issuedBy,
          issuedTo
        },
        ...prepareChainData(chain, allIdentifiers)
      )
    }
  }
  return chains
}

const prepareChannelForTN = (channel: string) => {
  try {
    const parsedChannel = JSON.parse(channel)
    const preparedChannel = Object.keys(parsedChannel)
      .map((key) => parsedChannel[key] && key)
      .filter((item) => item)
      .join('|')
    return preparedChannel
  } catch (ex) {
    return ''
  }
}

export const prepareVettingCredentials = (
  schemas: any[],
  allIdentifiers: EnterpriseIdentifiers[]
) => {
  const transformedData = schemas
    .filter((schema) => schema.sad.s === constants.CREDENTIAL_TYPES.VETTING)
    .map((schema) => {
      const aid = schema?.sad?.a?.i
      const companyName = schema?.sad?.a?.legalCompanyName || 'No Name'
      const LEI = schema?.sad?.a?.legalCompanyName
      const legalCompanyAddress = schema?.sad?.a?.legalCompanyAddress
      const vettingScore = schema?.sad?.a?.vettingScore
      const taxId = schema?.sad?.a?.taxId
      const issuedDate = format(
        new Date(schema?.sad?.a?.dt),
        constants.DATE_FORMAT
      )
      const issuedBy = allIdentifiers.find((aid) => aid.aid === schema?.sad?.i)
      const chainData = prepareChainData(schema, allIdentifiers)

      return {
        aid,
        logo: generateAvatar(companyName),
        companyName,
        LEI,
        legalCompanyAddress,
        vettingScore,
        taxId,
        issuedDate,
        issuedBy: issuedBy?.alias || 'N/A',
        credentialType: 'Vetting',
        chainData,
        credential: schema
      } as IVettingCredential
    })
  return transformedData
}

export const prepareCampaignCredential = (
  schemas: any[],
  allIdentifiers: EnterpriseIdentifiers[]
) => {
  const transformedData = schemas
    .filter((schema) => schema.sad.s === constants.CREDENTIAL_TYPES.CAMPAIGN)
    .map((schema) => {
      const aid = schema?.sad?.a?.i
      const brandId = schema?.sad?.a?.brandId
      const campaignType = schema?.sad?.a?.campaignType
      const campaignId = schema?.sad?.a?.campaignId
      const telephoneNumbers = schema?.sad?.a?.telephoneNumbers
      const authorizedServiceProvider =
        schema?.sad?.a?.authorizedServiceProvider
      const campaignAttributes = schema?.sad?.a?.campaignAttributes
      const startDate = format(
        new Date(schema?.sad?.a?.startDate),
        constants.DATE_FORMAT
      )
      const expirationDate = format(
        new Date(schema?.sad?.a?.expirationDate),
        constants.DATE_FORMAT
      )

      const issuedDate = format(
        new Date(schema?.sad?.a?.dt),
        constants.DATE_FORMAT
      )

      const issuedBy = allIdentifiers.find((aid) => aid.aid === schema?.sad?.i)
      const chainData = prepareChainData(schema, allIdentifiers)

      return {
        aid,
        logo: generateAvatar(brandId),
        brandId,
        campaignType,
        campaignId,
        telephoneNumbers,
        authorizedServiceProvider,
        issuedDate,
        startDate,
        expirationDate,
        issuedBy: issuedBy?.alias || 'N/A',
        credentialType: 'Campaign',
        campaignAttributes,
        chainData,
        credential: schema
      } as ICampaignCredentials
    })
  return transformedData
}

export const prepareTNCredentials = (
  schemas: any[],
  allIdentifiers: EnterpriseIdentifiers[]
) => {
  return schemas
    .filter((schema) => schema.sad.s === constants.CREDENTIAL_TYPES.TN)
    .map((schema) => {
      const startDate = format(
        new Date(schema?.sad?.a?.startDate),
        constants.DATE_FORMAT
      )
      const expirationDate = format(
        new Date(schema?.sad?.a?.expirationDate),
        constants.DATE_FORMAT
      )
      const issuedBy = allIdentifiers.find((aid) => aid.aid === schema?.sad?.i)
      const channel = prepareChannelForTN(schema?.sad?.a?.channel)
      const chainData = prepareChainData(schema, allIdentifiers)
      const issuedDate = format(
        new Date(schema?.sad?.a?.dt),
        constants.DATE_FORMAT
      )
      const numbers = (schema?.sad?.a?.numbers || [])
        .map((num: { [key: string]: string }) => num?.tn)
        .join(',')
      const numberType =
        ((schema?.sad?.a?.doNotOriginate as string) || '').toUpperCase() ===
        'FALSE'
          ? '-'
          : 'Do not originate'
      return {
        issuedDate,
        startDate,
        expirationDate,
        channel,
        issuedBy: issuedBy?.alias || 'N/A',
        chainData,
        numbers,
        numberType,
        credential: schema
      } as ITNCredential
    })
}

export const prepareLEICredentials = (
  schemas: ICredentials[],
  allIdentifiers: any[]
) => {
  if (!schemas) return []
  return schemas
    .filter(
      (schema) =>
        schema.sad.s === constants.CREDENTIAL_TYPES.LEI ||
        schema.sad.s === constants.CREDENTIAL_TYPES.QVI ||
        schema.sad.s === constants.CREDENTIAL_TYPES.OOR ||
        schema.sad.s === constants.CREDENTIAL_TYPES.OOR_AUTH ||
        schema.sad.s === constants.CREDENTIAL_TYPES.ECR
    )
    .map((schema) => {
      const chainData = prepareChainData(schema, allIdentifiers)
      const issuedBy = allIdentifiers.find((aid) => aid.id === schema?.sad?.i)
      const issuedDate = format(
        new Date(schema?.sad?.a?.dt),
        constants.DATE_FORMAT
      )
      const LEI = schema?.sad?.a?.LEI
      const schemaName = getSchemaName(schema.sad.s).schemaName

      return {
        issuedBy: issuedBy?.name || 'N/A',
        credential: schema,
        issuedDate,
        chainData,
        LEI,
        schemaName
      } as VLEICredential
    })
}
