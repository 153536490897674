export enum KeriEventType {
  icp = 'icp',
  rot = 'rot',
  ixn = 'ixn',
  dip = 'dip',
  drt = 'drt',
  rct = 'rct',
  qry = 'qry',
  rpy = 'rpy',
  exn = 'exn',
  pro = 'pro',
  bar = 'bar',
  vcp = 'vcp',
  vrt = 'vrt',
  iss = 'iss',
  rev = 'rev',
  bis = 'bis',
  brv = 'brv'
}

export enum ExnMessageType {
  ICP = '/multisig/icp',
  IXN = '/multisig/ixn',
  ROT = '/multisig/rot',
  RPY = '/multisig/rpy',
  VCP = '/multisig/vcp',
  EXN = '/multisig/exn',
  ISS = '/multisig/iss',
  GRANT = '/exn/ipex/grant',
  ADMIT = '/exn/ipex/admit',
  REV = '/multisig/rev'
}

export enum OobiDataType {
  SCHEMA = 'schema',
  CONTACT = 'contact'
}
