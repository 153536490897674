/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrgDto,
} from '../models/index';
import {
    OrgDtoFromJSON,
    OrgDtoToJSON,
} from '../models/index';

export interface FindByDomain1Request {
    domain: string;
}

export interface FindCreatedBy1Request {
    userId: string;
}

export interface GetName1Request {
    orgId: string;
}

export interface GetOrg1Request {
    orgId: string;
}

export interface NewOrg1Request {
    orgDto: OrgDto;
}

export interface SetCompleteStatus1Request {
    orgId: string;
}

export interface Update1Request {
    orgId: string;
    body: object;
}

/**
 * 
 */
export class OrgControllerInternalApi extends runtime.BaseAPI {

    /**
     * Retrieves info about an org\'s profile by org\'s domain
     * Find By domain
     */
    async findByDomain1Raw(requestParameters: FindByDomain1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['domain'] == null) {
            throw new runtime.RequiredError(
                'domain',
                'Required parameter "domain" was null or undefined when calling findByDomain1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/.internal/domain/{domain}`.replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters['domain']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves info about an org\'s profile by org\'s domain
     * Find By domain
     */
    async findByDomain1(requestParameters: FindByDomain1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto | null | undefined > {
        const response = await this.findByDomain1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get org parameters created by the user
     */
    async findCreatedBy1Raw(requestParameters: FindCreatedBy1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrgDto>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling findCreatedBy1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/.internal/created-by/{user-id}`.replace(`{${"user-id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrgDtoFromJSON));
    }

    /**
     * Get org parameters created by the user
     */
    async findCreatedBy1(requestParameters: FindCreatedBy1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrgDto> | null | undefined > {
        const response = await this.findCreatedBy1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find an organization name if the organization is present. This endpoint is public.
     * Get org name
     */
    async getName1Raw(requestParameters: GetName1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling getName1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/.internal/name/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * Find an organization name if the organization is present. This endpoint is public.
     * Get org name
     */
    async getName1(requestParameters: GetName1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto | null | undefined > {
        const response = await this.getName1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Retrieves info about an org\'s profile on our platform. These are configuration choices that the org makes.
     * Get org parameters
     */
    async getOrg1Raw(requestParameters: GetOrg1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling getOrg1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/.internal/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves info about an org\'s profile on our platform. These are configuration choices that the org makes.
     * Get org parameters
     */
    async getOrg1(requestParameters: GetOrg1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto | null | undefined > {
        const response = await this.getOrg1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Creates a new org and returns a Location header that tells us the id of the new org in the DB
     * Creates a new org, using the request body (JSON object) as the metadata to store about it
     */
    async newOrg1Raw(requestParameters: NewOrg1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['orgDto'] == null) {
            throw new runtime.RequiredError(
                'orgDto',
                'Required parameter "orgDto" was null or undefined when calling newOrg1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/.internal/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrgDtoToJSON(requestParameters['orgDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new org and returns a Location header that tells us the id of the new org in the DB
     * Creates a new org, using the request body (JSON object) as the metadata to store about it
     */
    async newOrg1(requestParameters: NewOrg1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto> {
        const response = await this.newOrg1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates org status to COMPLETE
     */
    async setCompleteStatus1Raw(requestParameters: SetCompleteStatus1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling setCompleteStatus1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/.internal/initial-onboarding/{org-id}`.replace(`{${"org-id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates org status to COMPLETE
     */
    async setCompleteStatus1(requestParameters: SetCompleteStatus1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.setCompleteStatus1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The request body is a JSON Patch object that explains what we want to replace, insert, or delete compared to what we already have.
     * Updates our own records for a specific org 
     */
    async update1Raw(requestParameters: Update1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling update1().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling update1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/.internal/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * The request body is a JSON Patch object that explains what we want to replace, insert, or delete compared to what we already have.
     * Updates our own records for a specific org 
     */
    async update1(requestParameters: Update1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto> {
        const response = await this.update1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
