/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OorTitleDto,
} from '../models/index';
import {
    OorTitleDtoFromJSON,
    OorTitleDtoToJSON,
} from '../models/index';

export interface FindTitlesRequest {
    country?: string;
    elfCode?: string;
}

export interface PutTitleRequest {
    oorTitleDto: OorTitleDto;
}

/**
 * 
 */
export class OorTitleControllerApi extends runtime.BaseAPI {

    /**
     */
    async findTitlesRaw(requestParameters: FindTitlesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OorTitleDto>>> {
        const queryParameters: any = {};

        if (requestParameters['country'] != null) {
            queryParameters['country'] = requestParameters['country'];
        }

        if (requestParameters['elfCode'] != null) {
            queryParameters['elf-code'] = requestParameters['elfCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/user-mgmt/oor-title`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OorTitleDtoFromJSON));
    }

    /**
     */
    async findTitles(requestParameters: FindTitlesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OorTitleDto>> {
        const response = await this.findTitlesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async putTitleRaw(requestParameters: PutTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['oorTitleDto'] == null) {
            throw new runtime.RequiredError(
                'oorTitleDto',
                'Required parameter "oorTitleDto" was null or undefined when calling putTitle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/user-mgmt/oor-title`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OorTitleDtoToJSON(requestParameters['oorTitleDto']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async putTitle(requestParameters: PutTitleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.putTitleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
