import { SignifyClient } from 'signify-ts'
import { NotificationList, Notification } from './types'
import { consoleLog } from '@/utils/console-logger'

/**
 * Retrieves a list of notifications from the client.
 *
 * @param client - The SignifyClient instance used to retrieve the notifications.
 * @param start - The start index of the notifications to retrieve. Defaults to 0.
 * @param end - The end index of the notifications to retrieve. Defaults to 100.
 * @returns A Promise that resolves to a NotificationList object containing the retrieved notifications.
 */
export const getNotifications = async (
  client: SignifyClient,
  start: number = 0,
  end: number = 100
): Promise<NotificationList> => {
  const notificationList: NotificationList = await client
    .notifications()
    .list(start, end)
  return notificationList
}

/**
 * Retrieves all notifications from the client.
 *
 * @param client - The SignifyClient instance.
 * @param sort - Optional. Specifies whether to sort the notifications by date in descending order. Default is true.
 * @param batchSize - Optional. The number of notifications to retrieve in each batch. Default is 200.
 * @returns A Promise that resolves to a NotificationList object containing all notifications.
 */
export const getAllNotifications = async (
  client: SignifyClient,
  sort: boolean = true,
  batchSize: number = 200
): Promise<NotificationList> => {
  let startIndex = 0
  let allNotifications: NotificationList = { notes: [] }

  while (true) {
    const endIndex = startIndex + batchSize

    consoleLog(
      `Fetching notifications from index ${startIndex} to ${endIndex}...`
    )
    const notificationList: NotificationList = await client
      .notifications()
      .list(startIndex, endIndex)
    allNotifications.notes.push(...notificationList.notes)
    // If the number of returned notifications is less than the batch size, stop fetching
    if (notificationList.notes.length < batchSize) {
      break
    }

    startIndex = endIndex
  }

  if (sort === true)
    allNotifications = sortNotificationsByDateDesc(allNotifications)

  return allNotifications
}

/**
 * Retrieves all unread notifications of a specific type within a given time range.
 *
 * @param client - The SignifyClient instance used to retrieve notifications.
 * @param type - The type of notifications to retrieve.
 * @param timeRangeInMinutes - The time range in minutes to filter the notifications. If 0, all notifications of the specified type will be retrieved.
 * @param sort - Optional. Specifies whether to sort the notifications by date in descending order. Default is true.
 * @param batchSize - Optional. The number of notifications to retrieve in each batch. Default is 200.
 * @returns A Promise that resolves to a NotificationList object containing the filtered and sorted notifications.
 */
export const getAllNotificationsByType = async (
  client: SignifyClient,
  type: string,
  timeRangeInMinutes: number = 0,
  sort: boolean = true,
  batchSize: number = 200
): Promise<NotificationList> => {
  let startIndex = 0
  let filteredNotifications: Notification[] = []

  const currentTimeUTC = Date.now()
  while (true) {
    const endIndex = startIndex + batchSize

    const notificationList: NotificationList = await client
      .notifications()
      .list(startIndex, endIndex)

    consoleLog(
      `Notifications count from ${startIndex} to ${endIndex}: `,
      notificationList.total
    )

    const trInMin = isNaN(Number(timeRangeInMinutes)) ? 60 : timeRangeInMinutes
    if (trInMin > 0) {
      const cutoffTime = currentTimeUTC - trInMin * 60 * 1000

      const filteredBatch = notificationList.notes.filter(
        (note) =>
          note.a.r === type &&
          note.r == false &&
          new Date(note.dt).getTime() >= cutoffTime
      )
      console.log(
        `Unread Notifications count of type ${type}: `,
        filteredBatch.length
      )
      filteredNotifications.push(...filteredBatch)
    } else {
      const filteredBatch = notificationList.notes.filter(
        (note) => note.a.r === type && note.r == false
      )
      console.log(
        `Unread Notifications count of type ${type}: `,
        filteredBatch.length
      )
      filteredNotifications.push(...filteredBatch)
    }

    if (notificationList.notes.length < batchSize) {
      break
    }

    startIndex = endIndex
  }

  let result: NotificationList = { notes: filteredNotifications }

  if (sort === true) result = sortNotificationsByDateDesc(result)

  return result
}

/**
 * Sorts the given notification list by date in descending order.
 * @param notificationList - The notification list to be sorted.
 * @returns The sorted notification list.
 */
export const sortNotificationsByDateDesc = (
  notificationList: NotificationList
): NotificationList => {
  notificationList.notes.sort((a, b) => {
    return new Date(b.dt).getTime() - new Date(a.dt).getTime()
  })
  return notificationList
}

/**
 * Filters the given notification list based on the specified number of minutes ago.
 * Only notifications that occurred within the specified time range will be included in the filtered list.
 *
 * @param notificationList - The list of notifications to filter.
 * @param minutesAgo - The number of minutes ago to use as the time range. Defaults to 15 minutes.
 * @returns The filtered notification list.
 */
export const filterNotificationsByMinutesAgo = (
  notificationList: NotificationList,
  minutesAgo: number = 15
): NotificationList => {
  const currentTimeUTC = Date.now()
  const cutoffTime = currentTimeUTC - minutesAgo * 60 * 1000

  const filteredNotifications: Notification[] = notificationList.notes.filter(
    (note) => {
      const notificationTimeUTC = new Date(note.dt).getTime()
      return notificationTimeUTC >= cutoffTime
    }
  )

  return { notes: filteredNotifications }
}

/**
 * Checks if a single notification occurred within the specified number of minutes ago.
 *
 * @param notification - The notification to check.
 * @param minutesAgo - The number of minutes ago to use as the time range. Defaults to 15 minutes.
 * @returns True if the notification occurred within the specified time range, false otherwise.
 */
export const checkNotificationByMinutesAgo = (
  notification: Notification,
  minutesAgo: number = 15
): boolean => {
  const currentTimeUTC = Date.now()
  const cutoffTime = currentTimeUTC - minutesAgo * 60 * 1000
  const notificationTimeUTC = new Date(notification.dt).getTime()
  return notificationTimeUTC >= cutoffTime
}
