import Navbar, { INavbarProps } from '@/components/base/Navbar'
import { useIntl } from 'react-intl'
import DropdownComponent from '@/components/base/Dropdown'
import AvatarIcon from '@/components/icons/AvatarIcon'
import useStore from '@/state/store'
import { useRouter } from 'next/router'

const TopNavbar = () => {
  const { killSession, clearSignify } = useStore()
  const router = useRouter()
  const intl = useIntl()

  const {
    authentication: { userInfo },
    resetAllStores
  } = useStore()

  const UserMenuItem = [
    {
      id: 'logout',
      label: intl.formatMessage({ id: 'topNavBar.menuItem.logout' }),
      onClick: () => {
        resetAllStores()
        killSession()
        clearSignify()
        router.push('/')
      }
    }
  ]

  const mainNavLinks: INavbarProps = {
    applicationName: intl.formatMessage({ id: 'application.name' }),
    links: [
      {
        href: '',
        label: (
          <div className="flex items-center gap-2">
            <AvatarIcon />
            <DropdownComponent
              placement="bottom"
              inline={true}
              label={
                <>
                  {userInfo?.firstName} {userInfo?.lastName}
                </>
              }
              menuItems={UserMenuItem}
            />
          </div>
        )
      }
    ]
  }
  return (
    <Navbar
      links={mainNavLinks.links}
      applicationName={mainNavLinks.applicationName}
    />
  )
}
export default TopNavbar
