import type { CustomFlowbiteTheme } from 'flowbite-react'

const customTheme: CustomFlowbiteTheme = {
  label: {
    colors: {
      failure: 'text-SecondaryRed900'
    }
  },
  textInput: {
    field: {
      input: {
        base: 'block w-full h-[44px] border px-3 py-2.5 rounded-md font-light text-sm hover:border-neutralsAdditional800 visited:border-neutralsAdditional800 focus-visible:outline-none focus-visible:border-royalBlue disabled:text-neutralsAdditional500 disabled:border-neutralsAdditional400 disabled:bg-grey200',
        colors: {
          gray: 'border-neutralsAdditional400',
          failure: 'border-ErrorBorder text-SecondaryRed900'
        }
      }
    }
  },
  textarea: {
    base: 'rounded-md block w-full border disabled:cursor-not-allowed disabled:opacity-50 border-neutralsAdditional400 disabled:text-neutralsAdditional500 disabled:border-neutralsAdditional400 disabled:bg-grey200',
    colors: {
      gray: 'border-neutralsAdditional400',
      failure: 'border-ErrorBorder text-SecondaryRed900'
    }
  },
  spinner: {
    color: {
      failure: 'fill-SecondaryRed900',
      success: 'fill-secondaryGreen900',
      warning: 'fill-SecondaryOrange900',
      info: 'fill-royalBlue'
    }
  }
}

export default customTheme
