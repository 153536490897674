import { useStorage } from '@/hooks/useStorage'
import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import * as yup from 'yup'

export function useServiceProvider() {
  const searchParams = useSearchParams()

  const [serviceProvider, setServiceProvider] = useStorage<string | undefined>(
    'serviceProvider',
    'session',
    () => getServiceProviderId(searchParams)
  )

  useEffect(() => {
    const serviceProviderId = getServiceProviderId(searchParams)
    if (serviceProviderId) {
      // we don't want to set service provider to null or undefined
      // if search params are changed via router.
      // Once a user is redirected to origin portal via a service provider
      // we want to keep that value stored in session storage.
      // However, if user comes back to origin portal with a different service provider
      // then, we want to update the service provider value
      setServiceProvider(serviceProviderId)
    }
  }, [searchParams])

  return [serviceProvider, setServiceProvider] as const
}

function getServiceProviderId(
  searchParams: ReturnType<typeof useSearchParams>
) {
  const serviceProviderIdInParams =
    searchParams.get('sp') ||
    searchParams.get('spid') ||
    searchParams.get('spId')

  if (yup.string().uuid().isValidSync(serviceProviderIdInParams)) {
    return serviceProviderIdInParams
  }

  return undefined
}
