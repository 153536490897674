import { Slide, toast } from 'react-toastify'
export enum ToastMode {
  DEFAULT = 'DEFAULT',
  INFO = 'INFO',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

export enum ToastPosition {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_CENTER = 'bottom-center',
  BOTTOM_RIGHT = 'bottom-right'
}

export enum ToastTheme {
  LIGHT = 'light',
  DARK = 'dark',
  COLORED = 'colored'
}

export interface IToastProps {
  position?: ToastPosition
  autoClose?: number
  hideProgressBar?: boolean
  closeOnClick?: boolean
  pauseOnHover?: boolean
  draggable?: boolean
  progress?: number | undefined
  theme?: ToastTheme
  transition?: typeof Slide
}

export const defaultToastProps: IToastProps = {
  position: ToastPosition.TOP_RIGHT,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: ToastTheme.LIGHT,
  transition: Slide
}

const CustomToast = (
  message: string,
  mode = ToastMode.INFO,
  options: IToastProps = defaultToastProps
) => {
  if (mode === ToastMode.SUCCESS) {
    toast.success(message, options)
  } else if (mode === ToastMode.ERROR) {
    toast.error(message, options)
  } else if (mode === ToastMode.INFO) {
    toast.info(message, options)
  } else if (mode == ToastMode.WARNING) {
    toast.warn(message, options)
  } else {
    toast(message, options)
  }
}

export default CustomToast
