import {
  CognitoAttribute,
  cognitoAttrToUserInfo,
  getAttributes,
  getSession,
  logout
} from '@/utils/auth'
import { produce } from 'immer'
import { StateCreator } from 'zustand'
import { UserInfoDto } from '@/api/origin-user-mgmt-svc'
import { TitleAndGrantStatus } from '@/api/origin-workflow-svc'
import { IStore } from '@/state/store'
import { getUserDetails } from '@/services/user'

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut
}
export enum AccountStatus {
  INVITED = 'INVITED',
  ONBOARDING_STARTED = 'ONBOARDING_STARTED',
  PASSCODE_PENDING = 'PASSCODE_PENDING',
  VERIFICATION_PENDING = 'VERIFICATION_PENDING',
  ONBOARDING_COMPLETE = 'ONBOARDING_COMPLETE',
  GET_STARTED_WORKFLOW_COMPLETE = 'GET_STARTED_WORKFLOW_COMPLETE'
}

export type UserInfo = {
  id: string
  sub?: string
  firstName?: string
  lastName?: string
  emails: {
    value: string
  }[]
  phoneNumbers?: {
    value: string
    countryCode: string
  }[]
  orgs?: {
    id: string
    name: string
    domain: string
  }[]
  grants?: string[]
}

export type Authentication = {
  authStatus: AuthStatus
  userInfo: UserInfo | null
}

export type AuthenticationStore = {
  authentication: Authentication
  setSession: () => void
  killSession: () => void
  setLoginUser: (userDetails: UserInfoDto | null) => void
  replaceGrantWithShortTitle: (titleAndGrant: TitleAndGrantStatus) => void
  resetAuthenticationStore: () => void
  updateUserDetails: () => Promise<void>
}

const initialState = {
  authentication: {
    authStatus: AuthStatus.Loading,
    userInfo: null
  }
}

const authenticationStore: StateCreator<IStore, [], [], AuthenticationStore> = (
  set,
  get
) => ({
  ...initialState,

  killSession: () => {
    logout()
    set(() => ({
      authentication: {
        authStatus: AuthStatus.SignedOut,
        userInfo: null
      }
    }))
  },
  setSession: async () => {
    try {
      await getSession()
      const attr: any = await getAttributes()
      const userInfo = cognitoAttrToUserInfo(attr as CognitoAttribute[])
      set(() => ({
        authentication: {
          authStatus: AuthStatus.SignedIn,
          userInfo
        }
      }))
    } catch (ex) {
      set(() => ({
        authentication: {
          authStatus: AuthStatus.SignedOut,
          userInfo: null
        }
      }))
    }
  },
  setLoginUser: (newUserInfo: UserInfo | null) => {
    set((state) => ({
      authentication: {
        authStatus:
          newUserInfo !== null ? AuthStatus.SignedIn : AuthStatus.SignedOut,
        userInfo: {
          ...state.authentication.userInfo,
          ...newUserInfo
        }
      }
    }))
  },
  replaceGrantWithShortTitle: (titleAndGrant) => {
    set(
      produce((state: AuthenticationStore) => {
        const index = get().authentication.userInfo.grants.findIndex(
          (grant) => grant.toUpperCase() === titleAndGrant.title.toUpperCase()
        )
        if (index > -1)
          state.authentication.userInfo.grants[index] =
            titleAndGrant.shortTitle || titleAndGrant.title
      })
    )
  },
  updateUserDetails: async () => {
    const userDetails = await getUserDetails()
    userDetails.grants = userDetails.grants.map((g) => g.toUpperCase())
    get().setLoginUser(userDetails)
  },
  resetAuthenticationStore: () => {
    set(initialState)
  }
})

export default authenticationStore
