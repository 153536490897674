import {
  AIDVerificationString,
  CredentialSaid,
  ICredentialIssuanceStatusChange,
  LEvLEIIssuanceError
} from '@/state/meet'

export enum JitsiMessagesEnum {
  AID_VERIFICATION_GENERATE_LARS_TODOS = '[Ceremony AID Verification] Generate Lars Todos',
  AID_VERIFICATION_I_Said_It = '[Ceremony AID Verification] I Said It',
  AID_VERIFICATION_I_Heard_It = '[Ceremony AID Verification] I Heard It',
  SET_CREDENTIAL_SAID = '[Credential Issuance] Set Credential Said',
  CREDENTIAL_ISSUANCE_STATUS_CHANGE = '[Credential Issuance] Status change',
  LEvLEI_ISSUANCE_ERROR = '[Ceremony] LEvLEI Issuance Failed'
}

export type MessagePayloadTypes = {
  [JitsiMessagesEnum.AID_VERIFICATION_GENERATE_LARS_TODOS]: AIDVerificationString[]
  [JitsiMessagesEnum.AID_VERIFICATION_I_Said_It]: AIDVerificationString
  [JitsiMessagesEnum.AID_VERIFICATION_I_Heard_It]: AIDVerificationString
  [JitsiMessagesEnum.SET_CREDENTIAL_SAID]: CredentialSaid
  [JitsiMessagesEnum.CREDENTIAL_ISSUANCE_STATUS_CHANGE]: ICredentialIssuanceStatusChange
  [JitsiMessagesEnum.LEvLEI_ISSUANCE_ERROR]: LEvLEIIssuanceError
}

export interface GeneralJitsiMessage {
  generalJitsiMessage: JitsiMessagesEnum
  generalJitsiPayload: any
}

export class JitsiMessageCreator<T extends JitsiMessagesEnum> {
  generalJitsiMessage: T
  generalJitsiPayload: MessagePayloadTypes[T]

  constructor(
    generalJitsiMessage: T,
    generalJitsiPayload: MessagePayloadTypes[T]
  ) {
    this.generalJitsiMessage = generalJitsiMessage
    this.generalJitsiPayload = generalJitsiPayload
  }
}
