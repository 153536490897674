import constants from '@/config/constants'

export interface IErrorResp {
  code: string
  title: string
  type: string
  detail: string
  instance: string
}

export class CustomError extends Error {
  public errorResponse: IErrorResp

  constructor(errorResponse: IErrorResp) {
    super(errorResponse.title)
    this.errorResponse = errorResponse
    this.name = errorResponse.code
  }
}

export async function prepareError(ex) {
  if (ex instanceof CustomError) return ex
  else
    return new CustomError({
      code:
        ex.name === 'TypeError'
          ? constants.ERROR_CODES.CONNECTIVITY
          : constants.ERROR_CODES.UNKNOWN,
      title: '',
      type: '',
      detail: '',
      instance: ''
    } as IErrorResp)
}
