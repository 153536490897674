/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotificationUpdateDto
 */
export interface NotificationUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof NotificationUpdateDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationUpdateDto
     */
    isRead: boolean;
}

/**
 * Check if a given object implements the NotificationUpdateDto interface.
 */
export function instanceOfNotificationUpdateDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('isRead' in value)) return false;
    return true;
}

export function NotificationUpdateDtoFromJSON(json: any): NotificationUpdateDto {
    return NotificationUpdateDtoFromJSONTyped(json, false);
}

export function NotificationUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotificationUpdateDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'isRead': json['isRead'],
    };
}

export function NotificationUpdateDtoToJSON(value?: NotificationUpdateDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'isRead': value['isRead'],
    };
}

