/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResendConfirmationCodeResponse
 */
export interface ResendConfirmationCodeResponse {
    /**
     * 
     * @type {string}
     * @memberof ResendConfirmationCodeResponse
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ResendConfirmationCodeResponse
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResendConfirmationCodeResponse
     */
    join?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ResendConfirmationCodeResponse
     */
    successCode?: ResendConfirmationCodeResponseSuccessCodeEnum;
}


/**
 * @export
 */
export const ResendConfirmationCodeResponseSuccessCodeEnum = {
    UserSignedUp: 'USER_SIGNED_UP',
    UserConfirmed: 'USER_CONFIRMED',
    UserComplete: 'USER_COMPLETE',
    WalletSaved: 'WALLET_SAVED',
    AlreadyConfirmed: 'ALREADY_CONFIRMED',
    AlreadyOnboarded: 'ALREADY_ONBOARDED',
    ResentConfirmationCode: 'RESENT_CONFIRMATION_CODE',
    Ok: 'OK'
} as const;
export type ResendConfirmationCodeResponseSuccessCodeEnum = typeof ResendConfirmationCodeResponseSuccessCodeEnum[keyof typeof ResendConfirmationCodeResponseSuccessCodeEnum];


/**
 * Check if a given object implements the ResendConfirmationCodeResponse interface.
 */
export function instanceOfResendConfirmationCodeResponse(value: object): boolean {
    return true;
}

export function ResendConfirmationCodeResponseFromJSON(json: any): ResendConfirmationCodeResponse {
    return ResendConfirmationCodeResponseFromJSONTyped(json, false);
}

export function ResendConfirmationCodeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResendConfirmationCodeResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'email': json['email'] == null ? undefined : json['email'],
        'join': json['join'] == null ? undefined : json['join'],
        'successCode': json['successCode'] == null ? undefined : json['successCode'],
    };
}

export function ResendConfirmationCodeResponseToJSON(value?: ResendConfirmationCodeResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'email': value['email'],
        'join': value['join'],
        'successCode': value['successCode'],
    };
}

