/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPhoneNumberDto
 */
export interface UserPhoneNumberDto {
    /**
     * 
     * @type {string}
     * @memberof UserPhoneNumberDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPhoneNumberDto
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPhoneNumberDto
     */
    value?: string;
}

/**
 * Check if a given object implements the UserPhoneNumberDto interface.
 */
export function instanceOfUserPhoneNumberDto(value: object): boolean {
    return true;
}

export function UserPhoneNumberDtoFromJSON(json: any): UserPhoneNumberDto {
    return UserPhoneNumberDtoFromJSONTyped(json, false);
}

export function UserPhoneNumberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPhoneNumberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'] == null ? undefined : json['userId'],
        'countryCode': json['countryCode'] == null ? undefined : json['countryCode'],
        'value': json['value'] == null ? undefined : json['value'],
    };
}

export function UserPhoneNumberDtoToJSON(value?: UserPhoneNumberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'countryCode': value['countryCode'],
        'value': value['value'],
    };
}

