import React from 'react'
import Link, { LinkProps } from 'next/link'

interface IProps extends LinkProps {
  children: React.ReactNode
  className?: string
  color?: 'info' | 'grey'
}

function CLink({ children, className = '', color = 'info', ...props }: IProps) {
  let eventClasses = `text-royalBlue border-b-royalBlue visited:border-b-primarySupportBluePressed visited:text-primarySupportBluePressed focus:border-b-neutralsProductInkNormal900 focus:text-neutralsProductInkNormal900 hover:border-b-primarySupportBluePressed hover:text-primarySupportBluePressed`
  if (color === 'grey')
    eventClasses = `text-neutralsAdditional800 border-b-neutralsAdditional800 visited:border-b-neutralsProductInkNormal900 visited:text-neutralsProductInkNormal900 focus:border-b-neutralsProductInkNormal900 focus:text-neutralsProductInkNormal900 hover:border-b-neutralsProductInkNormal900 hover:text-neutralsProductInkNormal900`
  return (
    <Link
      className={`underline decoration-dashed underline-offset-[5px] decoration-1 tansition-all duration-700 ${eventClasses} ${className} hover:text-blue-600 active:text-royalBlue `}
      {...props}
    >
      {children}
    </Link>
  )
}

export default CLink
