/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QarInfo } from './QarInfo';
import {
    QarInfoFromJSON,
    QarInfoFromJSONTyped,
    QarInfoToJSON,
} from './QarInfo';
import type { TimeSlotDto } from './TimeSlotDto';
import {
    TimeSlotDtoFromJSON,
    TimeSlotDtoFromJSONTyped,
    TimeSlotDtoToJSON,
} from './TimeSlotDto';

/**
 * 
 * @export
 * @interface FindQarInfoForTimeslotResponseDao
 */
export interface FindQarInfoForTimeslotResponseDao {
    /**
     * 
     * @type {TimeSlotDto}
     * @memberof FindQarInfoForTimeslotResponseDao
     */
    timeSlot?: TimeSlotDto;
    /**
     * 
     * @type {Array<QarInfo>}
     * @memberof FindQarInfoForTimeslotResponseDao
     */
    participants?: Array<QarInfo>;
}

/**
 * Check if a given object implements the FindQarInfoForTimeslotResponseDao interface.
 */
export function instanceOfFindQarInfoForTimeslotResponseDao(value: object): boolean {
    return true;
}

export function FindQarInfoForTimeslotResponseDaoFromJSON(json: any): FindQarInfoForTimeslotResponseDao {
    return FindQarInfoForTimeslotResponseDaoFromJSONTyped(json, false);
}

export function FindQarInfoForTimeslotResponseDaoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindQarInfoForTimeslotResponseDao {
    if (json == null) {
        return json;
    }
    return {
        
        'timeSlot': json['timeSlot'] == null ? undefined : TimeSlotDtoFromJSON(json['timeSlot']),
        'participants': json['participants'] == null ? undefined : ((json['participants'] as Array<any>).map(QarInfoFromJSON)),
    };
}

export function FindQarInfoForTimeslotResponseDaoToJSON(value?: FindQarInfoForTimeslotResponseDao | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timeSlot': TimeSlotDtoToJSON(value['timeSlot']),
        'participants': value['participants'] == null ? undefined : ((value['participants'] as Array<any>).map(QarInfoToJSON)),
    };
}

