/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleAcceptanceResponseDto
 */
export interface RoleAcceptanceResponseDto {
    /**
     * 
     * @type {string}
     * @memberof RoleAcceptanceResponseDto
     */
    workflowInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleAcceptanceResponseDto
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleAcceptanceResponseDto
     */
    accountStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleAcceptanceResponseDto
     */
    status?: RoleAcceptanceResponseDtoStatusEnum;
}


/**
 * @export
 */
export const RoleAcceptanceResponseDtoStatusEnum = {
    SuccessfullyRejected: 'SUCCESSFULLY_REJECTED',
    SuccessfullyAccepted: 'SUCCESSFULLY_ACCEPTED',
    GrantIsAlreadyRejected: 'GRANT_IS_ALREADY_REJECTED',
    GrantIsAlreadyAccepted: 'GRANT_IS_ALREADY_ACCEPTED'
} as const;
export type RoleAcceptanceResponseDtoStatusEnum = typeof RoleAcceptanceResponseDtoStatusEnum[keyof typeof RoleAcceptanceResponseDtoStatusEnum];


/**
 * Check if a given object implements the RoleAcceptanceResponseDto interface.
 */
export function instanceOfRoleAcceptanceResponseDto(value: object): boolean {
    return true;
}

export function RoleAcceptanceResponseDtoFromJSON(json: any): RoleAcceptanceResponseDto {
    return RoleAcceptanceResponseDtoFromJSONTyped(json, false);
}

export function RoleAcceptanceResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleAcceptanceResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'accountStatus': json['accountStatus'] == null ? undefined : json['accountStatus'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function RoleAcceptanceResponseDtoToJSON(value?: RoleAcceptanceResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'userId': value['userId'],
        'accountStatus': value['accountStatus'],
        'status': value['status'],
    };
}

