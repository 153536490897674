export const generateAvatar = (
  name: string,
  size: number = 50,
  bgColor: string = '#f0f0f0'
) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D

  canvas.width = size
  canvas.height = size

  // set the background color
  ctx.fillStyle = bgColor
  ctx.fillRect(0, 0, canvas.width, canvas.height)

  // set the font and text color
  ctx.font = `${size / 2}px __IBM_Plex_Sans_cca4a2`
  ctx.fillStyle = '#333'

  // calculate the position of the text to center it in the canvas
  const initials = name
    .replace(/[^a-zA-Z]+/, ' ')
    .trim()
    .split(' ')
    .map((word, key) => {
      if (key > 1) return ''
      return word[0].toUpperCase()
    })
    .join('')
  const textWidth = ctx.measureText(initials).width
  const x = (canvas.width - textWidth) / 2
  const y = canvas.height / 2 + size / 4

  // draw the text
  ctx.fillText(initials, x, y - 5)

  // convert the canvas to a data URL
  const dataURL = canvas.toDataURL('image/png')

  return dataURL
}
