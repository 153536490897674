import { Circle } from 'phosphor-react'

interface IProps {
  size?: number
  color?: string
  weight?: 'thin' | 'light' | 'bold' | 'fill' | 'duotone'
  className?: string
}

const CircleIcon = ({
  size = 16,
  color = '#000',
  weight,
  className = ''
}: IProps) => {
  return (
    <Circle size={size} weight={weight} color={color} className={className} />
  )
}
export default CircleIcon
