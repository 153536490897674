import React from 'react'
import Spinner from './Spinner'

export type MODE =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'danger-light'
  | 'danger-dark'
  | 'link'
  | 'green'

export interface ButtonProps {
  /**
   * Is this the principal call to action on the page?
   */
  mode?: MODE
  /**
   * What background color to use
   */
  backgroundColor?: string
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium'
  /**
   * Button contents
   */
  label: React.ReactNode | string
  /**
   * Button right icon
   */
  iconRight?: React.ReactNode
  /**
   * Button loading spinner state
   */
  loading?: boolean
  /**
   * Button left icon
   */
  iconLeft?: React.ReactNode
  /**
   * Optional click handler
   */
  onClick?: () => void

  type?: 'button' | 'submit' | 'reset'

  disabled?: boolean

  className?: string

  form?: string

  dataTestId?: string
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  mode = 'primary',
  size = 'medium',
  backgroundColor,
  label,
  type = 'button',
  disabled = false,
  className = '',
  iconLeft,
  iconRight,
  loading = false,
  ...props
}: ButtonProps) => {
  const spinnerColor =
    mode === 'primary'
      ? 'info'
      : mode === 'secondary'
      ? 'info'
      : mode === 'green'
      ? 'success'
      : ['danger', 'danger-light', 'danger-dark'].includes(mode)
      ? 'warning'
      : 'gray'

  const modeColor =
    disabled && mode == 'primary'
      ? 'bg-SecondaryBlue50 border-SecondaryBlue100 text-SecondaryBlue100'
      : disabled && mode == 'secondary'
      ? 'bg-SecondaryBlue50 border-SecondaryBlue100 text-SecondaryBlue100'
      : disabled && mode == 'danger'
      ? 'bg-red-100 border-red-200 text-red-300'
      : disabled && mode == 'danger-light'
      ? 'bg-red-grey125 border border-SecondaryRed50 text-SecondaryRed50'
      : disabled && mode == 'danger-dark'
      ? 'bg-SecondaryRed200 border-SecondaryRed200 text-white'
      : disabled && mode == 'tertiary'
      ? 'bg-grey125 border border-grey200 text-grey200'
      : disabled && mode == 'green'
      ? 'bg-alternateGreen900 transition-all duration-500 text-white border-alternateGreen900 hover:bg-alternateGreen900 opacity-50'
      : mode === 'primary'
      ? 'bg-royalBlue transition-all duration-500 focus:bg-royalBlue hover:bg-hoverBlue active:bg-pressedBlue border border-royalBlue text-white'
      : mode === 'secondary'
      ? 'bg-SecondaryBlue50 transition-all duration-500 focus:bg-SecondaryBlue50 hover:bg-SecondaryBlue100 active:bg-SecondaryBlue200 border border-SecondaryBlue100 text-royalBlue'
      : mode === 'tertiary'
      ? 'bg-grey transition-all duration-500 focus:bg-gray hover:bg-grey100 active:bg-grey200 border border-grey200 text-neutralsAdditional800'
      : mode === 'link'
      ? 'bg-transparent transition-all duration-500 px-0 hover:text-royalBlue active:text-pressedBlue'
      : mode === 'green'
      ? 'bg-alternateGreen900 transition-all duration-500 text-white border-alternateGreen900 hover:bg-alternateGreen900'
      : mode === 'danger-light'
      ? 'bg-grey transition-all duration-500 focus:bg-SecondaryRed200 hover:bg-SecondaryRed50 active:bg-SecondaryRed200 border border-SecondaryRed200 text-SecondaryRed950'
      : mode === 'danger-dark'
      ? 'bg-SecondaryRed900 transition-all duration-500 focus:bg-PressedRed hover:bg-hoverRed active:bg-pressedRed border border-SecondaryRed200 text-white'
      : 'bg-red-100 border border-red-100 text-red-600'

  const sizeClass = size === 'small' ? 'text-sm' : ''
  return (
    <button
      data-testid={props.dataTestId}
      type={type}
      disabled={disabled || loading}
      className={[
        modeColor,
        sizeClass,
        `rounded px-4 py-2 flex items-center font-[500] relative ${
          loading
            ? 'justify-center'
            : iconLeft || iconRight
            ? 'justify-between'
            : 'justify-center'
        }`,
        className
      ].join(' ')}
      style={{ backgroundColor }}
      {...props}
    >
      {iconLeft && <div className="mr-2">{iconLeft}</div>}
      {loading ? (
        <div className="absolute">
          <Spinner color={spinnerColor} />
        </div>
      ) : (
        ''
      )}
      <div className={`flex items-center ${loading ? 'invisible' : 'visible'}`}>
        {label}
      </div>
      {iconRight && <div className="ml-2">{iconRight}</div>}
    </button>
  )
}
