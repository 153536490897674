import {
  AddPublicRecordsRequest,
  type AddPublicRecordsRequestDto,
  FindCeremonyParticipantsRequest,
  FindScheduledMeetingRequest,
  OorPrerequisitesRequestDto,
  OorProposalRequest,
  type OorProposalRequestDto,
  OorRemovalRequest,
  type OorRemovalRequestDto,
  type OorResentNotificationRequestDto,
  ProposedOORsRequest,
  type QarOorVerificationRequestDto,
  QarVerifiesOorRequest,
  ResendNotificationToOorRecipientRequest,
  ScheduleCeremonyRequest,
  StartRequest,
  UpdatePublicRecordsRequest,
  type UpdatePublicRecordsRequestDto
} from '@/api/origin-workflow-svc'
import {
  commonWorkflowService,
  oorPrerequisitesService,
  oorTitleService
} from '@/utils/apis'

export async function startWorkflow(
  oorPrerequisitesRequestDto: OorPrerequisitesRequestDto
) {
  return await oorPrerequisitesService.start({
    oorPrerequisitesRequestDto
  } as StartRequest)
  // return await post('/oor-prereq/start', data, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function proposeRecipients(
  oorProposalRequestDto: OorProposalRequestDto
) {
  return await oorPrerequisitesService.oorProposal({
    oorProposalRequestDto
  } as OorProposalRequest)
  // return await post(`/oor-prereq/oor-proposal`, payload, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function getProposedOorRecipientsByWorkflowInstanceId(
  workflow: string
) {
  return await oorPrerequisitesService.proposedOORs({
    workflow
  } as ProposedOORsRequest)
  // return await get(`/oor-prereq/proposed-recipients/${workflowInstanceId}`, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function getProposedOorRecipientsByMeetingId(meeting: string) {
  return await oorPrerequisitesService.proposedOORs({
    meeting
  } as ProposedOORsRequest)
  // return await get(`/oor-prereq/proposed-recipients/meeting/${meetingId}`, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function addPublicRecords(
  addPublicRecordsRequestDto: AddPublicRecordsRequestDto
) {
  return await oorPrerequisitesService.addPublicRecords({
    addPublicRecordsRequestDto
  } as AddPublicRecordsRequest)
  // return await post(`/oor-prereq/additional-public-records`, payload, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function qarApproveRecipients(
  qarOorVerificationRequestDto: QarOorVerificationRequestDto
) {
  return await oorPrerequisitesService.qarVerifiesOor({
    qarOorVerificationRequestDto
  } as QarVerifiesOorRequest)
  // return await post(`/oor-prereq/oor-verification`, payload, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function scheduleCeremony(
  scheduleCeremonyRequestDto: ScheduleCeremonyRequest
) {
  return oorPrerequisitesService.scheduleCeremony(scheduleCeremonyRequestDto)
}

export async function getCeremonyParticipants(workflow: string) {
  return await oorPrerequisitesService.findCeremonyParticipants({
    workflow
  } as FindCeremonyParticipantsRequest)
  // return await get(`/oor-prereq/ceremony-participants/${workflowInstanceId}`, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function getCeremonyTime(workflow: string) {
  return commonWorkflowService.findScheduledMeeting({
    workflow
  } as FindScheduledMeetingRequest)
  // return await get(`/common/scheduled-meeting/${workflowInstanceId}`, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function getOorTitles(country: string) {
  return await oorTitleService.findTitles({ country, elfCode: '0000' })
  // return await get(`/oor-title/titles?country=${country}&elfCode=0000`, {
  //   BASE_URL: constants.AUTH_URL
  // })
}

export async function updatePublicRecords(
  updatePublicRecordsRequestDto: UpdatePublicRecordsRequestDto
) {
  return oorPrerequisitesService.updatePublicRecords({
    updatePublicRecordsRequestDto
  } as UpdatePublicRecordsRequest)
  // return await post(`/oor-prereq/updated-public-records`, payload, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function removeOORRecipient(
  oorRemovalRequestDto: OorRemovalRequestDto
) {
  return await oorPrerequisitesService.oorRemoval({
    oorRemovalRequestDto
  } as OorRemovalRequest)
  // return await post(`/oor-prereq/oor-removal`, payload, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}

export async function resendOORInvitation(
  oorResentNotificationRequestDto: OorResentNotificationRequestDto
) {
  return await oorPrerequisitesService.resendNotificationToOorRecipient({
    oorResentNotificationRequestDto
  } as ResendNotificationToOorRecipientRequest)
  // return await post(`/oor-prereq/resent-notification`, payload, {
  //   BASE_URL: constants.WORKFLOW_URL
  // })
}
