/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IdVerificationRequestDto
 */
export interface IdVerificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof IdVerificationRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {string}
     * @memberof IdVerificationRequestDto
     */
    workflowInstanceAssignedTaskId?: string;
    /**
     * 
     * @type {string}
     * @memberof IdVerificationRequestDto
     */
    orgId: string;
    /**
     * 
     * @type {string}
     * @memberof IdVerificationRequestDto
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof IdVerificationRequestDto
     */
    phoneNumber: string;
}

/**
 * Check if a given object implements the IdVerificationRequestDto interface.
 */
export function instanceOfIdVerificationRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    if (!('orgId' in value)) return false;
    if (!('countryCode' in value)) return false;
    if (!('phoneNumber' in value)) return false;
    return true;
}

export function IdVerificationRequestDtoFromJSON(json: any): IdVerificationRequestDto {
    return IdVerificationRequestDtoFromJSONTyped(json, false);
}

export function IdVerificationRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): IdVerificationRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': json['workflowInstanceAssignedTaskId'] == null ? undefined : json['workflowInstanceAssignedTaskId'],
        'orgId': json['orgId'],
        'countryCode': json['countryCode'],
        'phoneNumber': json['phoneNumber'],
    };
}

export function IdVerificationRequestDtoToJSON(value?: IdVerificationRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'workflowInstanceAssignedTaskId': value['workflowInstanceAssignedTaskId'],
        'orgId': value['orgId'],
        'countryCode': value['countryCode'],
        'phoneNumber': value['phoneNumber'],
    };
}

