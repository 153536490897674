/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  L10nBatchRequestDto,
  L10nTextBatchResponseDto,
  L10nTextResponseDto,
} from '../models/index';
import {
    L10nBatchRequestDtoFromJSON,
    L10nBatchRequestDtoToJSON,
    L10nTextBatchResponseDtoFromJSON,
    L10nTextBatchResponseDtoToJSON,
    L10nTextResponseDtoFromJSON,
    L10nTextResponseDtoToJSON,
} from '../models/index';

export interface GetLocalizedText1Request {
    symbolicTextId: string;
    lang: string;
}

export interface GetLocalizedTextBatchForUser1Request {
    l10nBatchRequestDto: L10nBatchRequestDto;
    user?: string;
    l10nId?: string;
}

export interface GetLocalizedTextForUser1Request {
    symbolicTextId: string;
    user?: string;
    l10nId?: string;
}

/**
 * 
 */
export class L10nControllerInternalApi extends runtime.BaseAPI {

    /**
     * Get localized text by symbolicTextId and its lang
     */
    async getLocalizedText1Raw(requestParameters: GetLocalizedText1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<L10nTextResponseDto>> {
        if (requestParameters['symbolicTextId'] == null) {
            throw new runtime.RequiredError(
                'symbolicTextId',
                'Required parameter "symbolicTextId" was null or undefined when calling getLocalizedText1().'
            );
        }

        if (requestParameters['lang'] == null) {
            throw new runtime.RequiredError(
                'lang',
                'Required parameter "lang" was null or undefined when calling getLocalizedText1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['symbolicTextId'] != null) {
            queryParameters['symbolic-text-id'] = requestParameters['symbolicTextId'];
        }

        if (requestParameters['lang'] != null) {
            queryParameters['lang'] = requestParameters['lang'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/l10n/localized-text/.internal`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => L10nTextResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get localized text by symbolicTextId and its lang
     */
    async getLocalizedText1(requestParameters: GetLocalizedText1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<L10nTextResponseDto> {
        const response = await this.getLocalizedText1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all localized texts by symbolicTextIds and their lang
     */
    async getLocalizedTextBatchForUser1Raw(requestParameters: GetLocalizedTextBatchForUser1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<L10nTextBatchResponseDto>> {
        if (requestParameters['l10nBatchRequestDto'] == null) {
            throw new runtime.RequiredError(
                'l10nBatchRequestDto',
                'Required parameter "l10nBatchRequestDto" was null or undefined when calling getLocalizedTextBatchForUser1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['l10nId'] != null) {
            queryParameters['l10nId'] = requestParameters['l10nId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/l10n/localized-text/.internal/user/list`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: L10nBatchRequestDtoToJSON(requestParameters['l10nBatchRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => L10nTextBatchResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get all localized texts by symbolicTextIds and their lang
     */
    async getLocalizedTextBatchForUser1(requestParameters: GetLocalizedTextBatchForUser1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<L10nTextBatchResponseDto> {
        const response = await this.getLocalizedTextBatchForUser1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get localized text by symbolicTextId and its lang
     */
    async getLocalizedTextForUser1Raw(requestParameters: GetLocalizedTextForUser1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<L10nTextResponseDto>> {
        if (requestParameters['symbolicTextId'] == null) {
            throw new runtime.RequiredError(
                'symbolicTextId',
                'Required parameter "symbolicTextId" was null or undefined when calling getLocalizedTextForUser1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['symbolicTextId'] != null) {
            queryParameters['symbolic-text-id'] = requestParameters['symbolicTextId'];
        }

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        if (requestParameters['l10nId'] != null) {
            queryParameters['l10nId'] = requestParameters['l10nId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/l10n/localized-text/.internal/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => L10nTextResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get localized text by symbolicTextId and its lang
     */
    async getLocalizedTextForUser1(requestParameters: GetLocalizedTextForUser1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<L10nTextResponseDto> {
        const response = await this.getLocalizedTextForUser1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
