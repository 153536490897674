/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QVIWorkflowDto
 */
export interface QVIWorkflowDto {
    /**
     * 
     * @type {string}
     * @memberof QVIWorkflowDto
     */
    workflowInstanceId?: string;
    /**
     * 
     * @type {string}
     * @memberof QVIWorkflowDto
     */
    dateRequested?: string;
    /**
     * 
     * @type {number}
     * @memberof QVIWorkflowDto
     */
    inProgress?: number;
    /**
     * 
     * @type {string}
     * @memberof QVIWorkflowDto
     */
    credentials?: string;
    /**
     * 
     * @type {string}
     * @memberof QVIWorkflowDto
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof QVIWorkflowDto
     */
    requestor?: string;
    /**
     * 
     * @type {string}
     * @memberof QVIWorkflowDto
     */
    status?: QVIWorkflowDtoStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof QVIWorkflowDto
     */
    assignedTasks?: Array<string>;
}


/**
 * @export
 */
export const QVIWorkflowDtoStatusEnum = {
    Todo: 'TODO',
    InProgress: 'IN_PROGRESS',
    Completed: 'COMPLETED',
    Terminated: 'TERMINATED',
    Canceled: 'CANCELED'
} as const;
export type QVIWorkflowDtoStatusEnum = typeof QVIWorkflowDtoStatusEnum[keyof typeof QVIWorkflowDtoStatusEnum];


/**
 * Check if a given object implements the QVIWorkflowDto interface.
 */
export function instanceOfQVIWorkflowDto(value: object): boolean {
    return true;
}

export function QVIWorkflowDtoFromJSON(json: any): QVIWorkflowDto {
    return QVIWorkflowDtoFromJSONTyped(json, false);
}

export function QVIWorkflowDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QVIWorkflowDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
        'dateRequested': json['dateRequested'] == null ? undefined : json['dateRequested'],
        'inProgress': json['inProgress'] == null ? undefined : json['inProgress'],
        'credentials': json['credentials'] == null ? undefined : json['credentials'],
        'orgId': json['orgId'] == null ? undefined : json['orgId'],
        'requestor': json['requestor'] == null ? undefined : json['requestor'],
        'status': json['status'] == null ? undefined : json['status'],
        'assignedTasks': json['assignedTasks'] == null ? undefined : json['assignedTasks'],
    };
}

export function QVIWorkflowDtoToJSON(value?: QVIWorkflowDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'dateRequested': value['dateRequested'],
        'inProgress': value['inProgress'],
        'credentials': value['credentials'],
        'orgId': value['orgId'],
        'requestor': value['requestor'],
        'status': value['status'],
        'assignedTasks': value['assignedTasks'],
    };
}

