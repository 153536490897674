interface Props {
  size?: string
  color?: string
}
const SquareBox = ({ size = '14', color = 'currentColor' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect opacity="0.2" width={size} height={size} rx="2" fill={color} />
    </svg>
  )
}

export default SquareBox
