/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FindMeetingOrgParametersResponseDto,
  FindQarInfoForTimeslotResponseDao,
  FindScheduledMeetingResponseDto,
  IdVerificationRequestDto,
  ReplaceQarRequest,
  ResponseDto,
  UserPhoneNumberDto,
} from '../models/index';
import {
    FindMeetingOrgParametersResponseDtoFromJSON,
    FindMeetingOrgParametersResponseDtoToJSON,
    FindQarInfoForTimeslotResponseDaoFromJSON,
    FindQarInfoForTimeslotResponseDaoToJSON,
    FindScheduledMeetingResponseDtoFromJSON,
    FindScheduledMeetingResponseDtoToJSON,
    IdVerificationRequestDtoFromJSON,
    IdVerificationRequestDtoToJSON,
    ReplaceQarRequestFromJSON,
    ReplaceQarRequestToJSON,
    ResponseDtoFromJSON,
    ResponseDtoToJSON,
    UserPhoneNumberDtoFromJSON,
    UserPhoneNumberDtoToJSON,
} from '../models/index';

export interface FindAvailableQarsRequest {
    workflowInstanceId: string;
    start: string;
    end: string;
}

export interface FindMeetingIdRequest {
    workflow: string;
}

export interface FindMeetingOrgParametersRequest {
    meeting: string;
}

export interface FindScheduledMeetingRequest {
    workflow: string;
}

export interface IdVerificationRequest {
    idVerificationRequestDto: IdVerificationRequestDto;
    mocked?: boolean;
}

export interface IdVerificationPhoneNumberRequest {
    workflow: string;
    user: string;
}

export interface IdVerificationStatusRefreshRequest {
    body: string;
}

export interface ReplaceQarOperationRequest {
    replaceQarRequest: ReplaceQarRequest;
}

/**
 * 
 */
export class CommonWorkflowControllerApi extends runtime.BaseAPI {

    /**
     */
    async findAvailableQarsRaw(requestParameters: FindAvailableQarsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindQarInfoForTimeslotResponseDao>> {
        if (requestParameters['workflowInstanceId'] == null) {
            throw new runtime.RequiredError(
                'workflowInstanceId',
                'Required parameter "workflowInstanceId" was null or undefined when calling findAvailableQars().'
            );
        }

        if (requestParameters['start'] == null) {
            throw new runtime.RequiredError(
                'start',
                'Required parameter "start" was null or undefined when calling findAvailableQars().'
            );
        }

        if (requestParameters['end'] == null) {
            throw new runtime.RequiredError(
                'end',
                'Required parameter "end" was null or undefined when calling findAvailableQars().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflowInstanceId'] != null) {
            queryParameters['workflow-instance-id'] = requestParameters['workflowInstanceId'];
        }

        if (requestParameters['start'] != null) {
            queryParameters['start'] = requestParameters['start'];
        }

        if (requestParameters['end'] != null) {
            queryParameters['end'] = requestParameters['end'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/common/available-qars`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindQarInfoForTimeslotResponseDaoFromJSON(jsonValue));
    }

    /**
     */
    async findAvailableQars(requestParameters: FindAvailableQarsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindQarInfoForTimeslotResponseDao | null | undefined > {
        const response = await this.findAvailableQarsRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Finds meeting id
     */
    async findMeetingIdRaw(requestParameters: FindMeetingIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling findMeetingId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/common/meeting-id`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Finds meeting id
     */
    async findMeetingId(requestParameters: FindMeetingIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.findMeetingIdRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     */
    async findMeetingOrgParametersRaw(requestParameters: FindMeetingOrgParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindMeetingOrgParametersResponseDto>> {
        if (requestParameters['meeting'] == null) {
            throw new runtime.RequiredError(
                'meeting',
                'Required parameter "meeting" was null or undefined when calling findMeetingOrgParameters().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['meeting'] != null) {
            queryParameters['meeting'] = requestParameters['meeting'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/common/org-parameters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindMeetingOrgParametersResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async findMeetingOrgParameters(requestParameters: FindMeetingOrgParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindMeetingOrgParametersResponseDto | null | undefined > {
        const response = await this.findMeetingOrgParametersRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Scheduled meeting info
     */
    async findScheduledMeetingRaw(requestParameters: FindScheduledMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FindScheduledMeetingResponseDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling findScheduledMeeting().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/common/scheduled-meeting`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FindScheduledMeetingResponseDtoFromJSON(jsonValue));
    }

    /**
     * Scheduled meeting info
     */
    async findScheduledMeeting(requestParameters: FindScheduledMeetingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FindScheduledMeetingResponseDto | null | undefined > {
        const response = await this.findScheduledMeetingRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Initiate ID verification for user
     */
    async idVerificationRaw(requestParameters: IdVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdVerificationRequestDto>> {
        if (requestParameters['idVerificationRequestDto'] == null) {
            throw new runtime.RequiredError(
                'idVerificationRequestDto',
                'Required parameter "idVerificationRequestDto" was null or undefined when calling idVerification().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mocked'] != null) {
            queryParameters['mocked'] = requestParameters['mocked'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/common/id-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IdVerificationRequestDtoToJSON(requestParameters['idVerificationRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdVerificationRequestDtoFromJSON(jsonValue));
    }

    /**
     * Initiate ID verification for user
     */
    async idVerification(requestParameters: IdVerificationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdVerificationRequestDto> {
        const response = await this.idVerificationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns phone number used for id verification
     */
    async idVerificationPhoneNumberRaw(requestParameters: IdVerificationPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPhoneNumberDto>> {
        if (requestParameters['workflow'] == null) {
            throw new runtime.RequiredError(
                'workflow',
                'Required parameter "workflow" was null or undefined when calling idVerificationPhoneNumber().'
            );
        }

        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling idVerificationPhoneNumber().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['workflow'] != null) {
            queryParameters['workflow'] = requestParameters['workflow'];
        }

        if (requestParameters['user'] != null) {
            queryParameters['user'] = requestParameters['user'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/workflow/common/id-verification/phone-number`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPhoneNumberDtoFromJSON(jsonValue));
    }

    /**
     * Returns phone number used for id verification
     */
    async idVerificationPhoneNumber(requestParameters: IdVerificationPhoneNumberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPhoneNumberDto | null | undefined > {
        const response = await this.idVerificationPhoneNumberRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Refresh status of ID verification for user
     */
    async idVerificationStatusRefreshRaw(requestParameters: IdVerificationStatusRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdVerificationRequestDto>> {
        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling idVerificationStatusRefresh().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/common/id-verification-status-refresh`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdVerificationRequestDtoFromJSON(jsonValue));
    }

    /**
     * Refresh status of ID verification for user
     */
    async idVerificationStatusRefresh(requestParameters: IdVerificationStatusRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdVerificationRequestDto> {
        const response = await this.idVerificationStatusRefreshRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Replace QAR member of ceremony event
     */
    async replaceQarRaw(requestParameters: ReplaceQarOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResponseDto>> {
        if (requestParameters['replaceQarRequest'] == null) {
            throw new runtime.RequiredError(
                'replaceQarRequest',
                'Required parameter "replaceQarRequest" was null or undefined when calling replaceQar().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/workflow/common/replace-qar-in-ceremony`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReplaceQarRequestToJSON(requestParameters['replaceQarRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResponseDtoFromJSON(jsonValue));
    }

    /**
     * Replace QAR member of ceremony event
     */
    async replaceQar(requestParameters: ReplaceQarOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResponseDto> {
        const response = await this.replaceQarRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
