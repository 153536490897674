import React from 'react'

interface IProps {
  direction?: 'UP' | 'DOWN' | 'LEFT' | 'RIGHT'
  color?: string
  size?: number
}

const Arrow = ({
  direction = 'UP',
  color = 'currentColor',
  size = 14
}: IProps) => {
  if (direction === 'DOWN')
    return (
      <>
        <svg
          width={size}
          height={size}
          viewBox="0 0 14 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.327334 0.260067C0.735992 -0.11144 1.36844 -0.0813234 1.73995 0.327334L6.9091 6.0134L12.0782 0.327334C12.4498 -0.0813234 13.0822 -0.11144 13.4909 0.260067C13.8995 0.631574 13.9296 1.26402 13.5581 1.67268L7.64904 8.17268C7.45952 8.38115 7.19084 8.50001 6.9091 8.50001C6.62735 8.50001 6.35868 8.38115 6.16916 8.17268L0.260067 1.67268C-0.11144 1.26402 -0.0813234 0.631574 0.327334 0.260067Z"
            fill={color}
          />
        </svg>
      </>
    )
  else if (direction === 'LEFT')
    return (
      <>
        <svg
          width={size}
          height={size}
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.40887 5.96852L13.0002 5.96852C13.2807 5.96852 13.5175 6.06791 13.7105 6.26668C13.9035 6.46545 14 6.70932 14 6.99829C14 7.28726 13.9035 7.53113 13.7105 7.7299C13.5175 7.92867 13.2807 8.02805 13.0001 8.02805L3.40887 8.02805L7.51089 12.2529C7.71121 12.4592 7.80858 12.7011 7.80302 12.9786C7.79744 13.2561 7.69449 13.498 7.49417 13.7043C7.29385 13.8955 7.05898 13.994 6.78956 13.9998C6.52014 14.0055 6.28528 13.9052 6.08496 13.6989L0.283755 7.72399C0.18534 7.62264 0.11344 7.51014 0.0680567 7.38647C0.0226866 7.26283 6.00294e-07 7.13343 6.12109e-07 6.99829C6.23924e-07 6.86314 0.0226866 6.73375 0.0680568 6.6101C0.11344 6.48644 0.18534 6.37393 0.283755 6.27259L6.09021 0.292272C6.27938 0.0974238 6.51059 -6.54745e-07 6.78383 -6.30858e-07C7.05707 -6.0697e-07 7.29385 0.0974239 7.49417 0.292272C7.69449 0.498588 7.79465 0.743356 7.79465 1.02658C7.79465 1.30981 7.69449 1.55458 7.49417 1.7609L3.40887 5.96852Z"
            fill={color}
          />
        </svg>
      </>
    )
  else if (direction === 'RIGHT')
    return (
      <>
        <svg
          width={size}
          height={size}
          viewBox="0 0 10 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.23897 5.83189H0.807497C0.583155 5.83189 0.392484 5.75076 0.235485 5.58849C0.078495 5.42622 0 5.22916 0 4.99729C0 4.76542 0.078495 4.56836 0.235485 4.40609C0.392484 4.24382 0.583155 4.16269 0.807497 4.16269H7.23897L4.58863 1.42345C4.42655 1.25594 4.34662 1.05853 4.34883 0.831227C4.35103 0.603916 4.43318 0.406508 4.59525 0.239004C4.75733 0.081987 4.94833 0.00233439 5.16826 4.58455e-05C5.38818 -0.0022322 5.57918 0.0803861 5.74126 0.247901L9.76351 4.40507C9.84168 4.48586 9.90064 4.57783 9.94038 4.68098C9.98013 4.78414 10 4.88957 10 4.99729C10 5.10501 9.98013 5.21045 9.94038 5.3136C9.90064 5.41675 9.84168 5.50872 9.76351 5.58951L5.73265 9.75558C5.57499 9.91853 5.38653 10 5.16727 10C4.948 10 4.75733 9.91853 4.59525 9.75558C4.43318 9.58807 4.35214 9.38953 4.35214 9.15994C4.35214 8.93035 4.43318 8.7318 4.59525 8.56428L7.23897 5.83189Z"
            fill={color}
          />
        </svg>
      </>
    )
  else
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 14 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.491 8.23993C13.0824 8.61144 12.4499 8.58132 12.0784 8.17267L6.90926 2.4866L1.74011 8.17266C1.3686 8.58132 0.736156 8.61144 0.327498 8.23993C-0.0811595 7.86842 -0.111277 7.23598 0.260231 6.82732L6.16932 0.32732C6.35884 0.118846 6.62752 -7.30436e-06 6.90926 -7.27973e-06C7.19101 -7.2551e-06 7.45968 0.118846 7.6492 0.327321L13.5583 6.82732C13.9298 7.23598 13.8997 7.86843 13.491 8.23993Z"
          fill={color}
        />
      </svg>
    )
}

export default Arrow
