import { rules } from './rules'

export function formatQviCredData(lei: string): {
  attributes: any
  rules: any
} {
  const attributes = {
    LEI: lei
  }

  return { attributes, rules }
}
