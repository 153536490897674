import { RetryException } from '@/exceptions/retry-exception'
import { IContact } from '@/state/signify'
import { consoleError } from '@/utils/console-logger'
import { retryIf, RetryOptions } from '@/utils/retry'
import { SignifyClient } from 'signify-ts'

export const getContacts = async (
  client: SignifyClient,
  groupBy?: string,
  filterField?: string,
  filterValue?: string
): Promise<IContact[]> => {
  return await client.contacts().list(groupBy, filterField, filterValue)
}

export const getContact = async (
  client: SignifyClient,
  aid: string
): Promise<IContact> => {
  return await client.contacts().get(aid)
}

export const addContact = async (
  client: SignifyClient,
  aid: string,
  info: any
) => {
  return await client.contacts().add(aid, info)
}

export const updateContact = async (
  client: SignifyClient,
  aid: string,
  info: any
) => {
  return await client.contacts().update(aid, info)
}

export const deleteContact = async (client: SignifyClient, aid: string) => {
  return await client.contacts().delete(aid)
}

export const checkIsContactCreated = async (
  client: SignifyClient,
  oobi: string,
  name: string,
  maxRetries: number = 50
): Promise<{ isCreated: boolean; error: Error }> => {
  const options: RetryOptions = {
    maxRetries: maxRetries,
    timeout: 60000
  }

  const condition = (data: IContact[]): boolean => {
    if (data.length < 1) return false
    return !!data.find((x) => x.oobi === oobi && x.alias === name)
  }

  try {
    const data = await retryIf(
      () => getContacts(client, undefined, 'alias', name),
      condition,
      options
    )

    let contact = data.find((x) => x.oobi === oobi && x.alias === name)
    if (contact) {
      return { isCreated: true, error: null }
    } else {
      return { isCreated: false, error: null }
    }
  } catch (error) {
    if (error instanceof RetryException) {
      if (error.isTimeout || error.isMaxRetries) {
        consoleError('Failed due to timeout:', error.message)
      } else {
        consoleError('Failed due to error:', error.message)
        consoleError('Cause:', error.cause)
      }
    } else {
      consoleError('An unexpected error occurred:', error)
    }
    return { isCreated: false, error: error }
  }
}
