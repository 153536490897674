/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: 'false',
  content: [
    './src/**/*.{js,ts,jsx,tsx}',
    './node_modules/flowbite-react/**/*.{js,jsx,ts,tsx}',
    './public/**/*.html',
    './node_modules/tailwind-datepicker-react/dist/**/*.js'
  ],
  theme: {
    aspectRatio: {
      auto: 'auto',
      square: '1 / 1',
      video: '16 / 9',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
      13: '13',
      14: '14',
      15: '15',
      16: '16'
    },
    screens: {
      sm: '576px',
      // => @media (min-width: 576px) { ... }

      md: '768px',
      // => @media (min-width: 768px) { ... }

      lg: '1200px',
      // => @media (min-width: 1200px) { ... }

      xl: '1440px',
      // => @media (min-width: 1440px) { ... }

      '2xl': '1920px'
      // => @media (min-width: 1920px) { ... }
    },
    extend: {
      colors: {
        bg: '#f6f6f6',
        black: '#000',
        white: '#FFF',
        grey: '#fbfbfb',
        grey10: '#F9F9FA',
        grey100: '#f2f2f2',
        grey125: '#FBFBFB',
        grey150: '#edeff0',
        grey200: '#e8e8e8',
        grey300: '#f7f8f8',
        royalBlue: '#004cb5',
        hoverBlue: '#00429D',
        pressedBlue: '#023780',
        successGreen: '#4cc800',
        SecondaryBlue10: '#F7FBFF',
        SecondaryBlue50: '#E4F3FE',
        SecondaryBlue75: '#66BEFB',
        SecondaryBlue100: '#BEE2FD',
        SecondaryBlue200: '#94D1FC',
        SecondaryBlue800: '#004CB5',
        SecondaryOrange50: '#FFF4E8',
        SecondaryOrange100: '#F4DCAE',
        SecondaryOrange200: '#FFE9C0',
        SecondaryOrange900: '#D96800',
        SecondaryRed50: '#FEE4E8',
        SecondaryRed200: '#F08F8F',
        SecondaryRed900: '#B80312',
        hoverRed: '#B80312',
        pressedRed: '#9E000D',
        SecondaryRed950: '#B80312',
        pressedMenuItem: '#e4f3fe',
        primaryAdditional100: '#BEE2FD',
        primaryAdditional200: '#94D1FC',
        primarySupportBluePressed: '#023780',
        secondaryGreen50: '#F3FAE7',
        secondaryGreen100: '#E1F3C5',
        secondaryGreen900: '#1F7C19',
        alternateGreen900: '#0A6104',
        neutral50: '#F9F9FA',
        neutralsAdditional400: '#C6C6C6',
        neutralsAdditional500: '#A7A7A7',
        neutralsAdditional700: '#696969',
        neutralsAdditional800: '#4A4A4A',
        neutralsProductInkNormal900: '#202020',
        neutralsProductInkNormal600: '#7E7E7E',
        ErrorBorder: '#DA0000',
        redColor: 'rgb(184, 3, 18)',
        red10: '#FFF2F4',

        //Badges colors
        successGreen50: '#E9F5E8',
        TextGreen: '#0A6104',
        SecondaryOrange: '#FFECD6'
      }
    }
  },
  corePlugins: {
    aspectRatio: false
  },
  plugins: [require('@tailwindcss/aspect-ratio'), require('flowbite/plugin')]
}
