import { Dropdown } from 'flowbite-react'
import React, { FC, SVGProps } from 'react'

import styleCss from '@/styles/dropdown.module.scss'

export type Alignment = 'start' | 'end'
export type Side = 'top' | 'right' | 'bottom' | 'left'
export type AlignedPlacement = `${Side}-${Alignment}`
export type Placement = Side | AlignedPlacement

type MenuItemVariant = 'danger' | 'normal'

export interface IMenuItems {
  label: React.ReactNode
  icon?: FC<SVGProps<SVGSVGElement>>
  onClick?: () => void
  isDivider?: boolean
  isSelected?: boolean
  variant?: MenuItemVariant
  [key: string]: any
}

export interface DropdownComponentProps {
  label: React.ReactNode
  inline?: boolean
  header?: React.ReactNode
  menuItems?: IMenuItems[]
  size?: 'xs' | 'sm' | 'lg' | 'xl'
  placement?: 'auto' | Placement
  boxClasses?: string
  hasItemClasses?: boolean
}

const DropdownComponent = ({
  label,
  inline = false,
  header,
  menuItems = [],
  size = 'sm',
  placement = 'auto',
  boxClasses = '',
  hasItemClasses = true
}: DropdownComponentProps) => {
  const variantCssClasses = new Map<MenuItemVariant, string>([
    ['danger', 'text-red-800 hover:bg-red-100']
  ])
  const getItemClasses = (menuItem: IMenuItems) =>
    hasItemClasses
      ? `w-full font-medium min-w-[200px] border-l-[3px] border-white py-3 ${
          styleCss['dropdown-menu-item']
        } ${menuItem.isSelected ? 'border-l-royalBlue bg-gray-200' : ''}`
      : `font-medium ${variantCssClasses.get(menuItem.variant)}`

  if (React.isValidElement(label)) {
    inline = true
    label = <div data-custom>{label}</div>
  }
  return (
    <>
      {React.isValidElement(label) && (
        <style>
          {`
            div[data-custom] + svg{
              display: none
            }            
          `}
        </style>
      )}
      {!React.isValidElement(label) && (
        <style>
          {`
            [data-testid=flowbite-tooltip-target] button {
              border-radius: 0.25rem
            }
          `}
        </style>
      )}

      <Dropdown
        id="dropdown-"
        label={label}
        size={size}
        placement={placement}
        inline={inline}
        className={`${boxClasses} ${styleCss['dropdown-menu']}`}
      >
        {header && <Dropdown.Header>{header}</Dropdown.Header>}
        {header && menuItems.length && <Dropdown.Divider />}
        {menuItems.map((menuItem, key) => {
          return menuItem.isDivider ? (
            <Dropdown.Divider key={key} />
          ) : (
            <Dropdown.Item
              key={key}
              icon={menuItem?.icon}
              onClick={() => menuItem.onClick && menuItem.onClick()}
              className={getItemClasses(menuItem)}
            >
              {menuItem.label}
            </Dropdown.Item>
          )
        })}
      </Dropdown>
    </>
  )
}
export default DropdownComponent
