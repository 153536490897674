import { CredentialType, FETCHING_STATUS } from '@/config/constants'
import {
  prepareCampaignCredential,
  prepareLEICredentials,
  prepareTNCredentials,
  prepareVettingCredentials
} from '@/utils/transformers'
import { StateCreator } from 'zustand'
import { IEnterpriseStore } from './enterprise'
import { IStore } from './store'
import { produce } from 'immer'
import { AuthStatus } from '@/state/authentication'

export interface IChainData {
  LEI: string
  issuedBy: string
  issuedDate: string
  issuedTo: string
  schemaType: string
}

export interface ITelephoneNumbers {
  tn: string
}

export interface ICampaignAttr {
  subscriberHelp: string
  subscriberOptIn: string
  subscriberOptOut: string
  embeddedLink: string
  affiliateMarketing: string
}

export interface IVettingCredential {
  aid: string
  logo: string
  companyName: string
  LEI: string
  legalCompanyAddress: string
  vettingScore: string
  taxId: string
  issuedDate: string
  issuedBy: string
  credentialType: string
  chainData: IChainData[]
  credential: any
}

export interface ICampaignCredentials {
  aid: string
  logo: string
  brandId: string
  campaignType: string
  campaignId: string
  telephoneNumbers: ITelephoneNumbers[]
  authorizedServiceProvider: string
  issuedDate: string
  startDate: string
  expirationDate: string
  issuedBy: string
  credentialType: string
  campaignAttributes: ICampaignAttr
  chainData: IChainData[]
  credential: any
}

export interface ITNCredential {
  issuedBy: string
  issuedDate: string
  startDate: string
  expirationDate: string
  channel: string
  chainData: IChainData[]
  numbers: string
  numberType: string
  credential: any
}

export interface VLEICredential {
  issuedBy: string
  issuedDate: string
  chainData: IChainData[]
  LEI: string
  credential: any
  schemaName: string
}

export enum Actions {
  CREDENTIAL_ISSUED
}

export type Payload = boolean

export interface ICredential {
  newCredentialIssued: boolean
  setNewCredentialIssued: (isNewCredentialIssued: boolean) => void
  credentials: {
    fetchingStatus: FETCHING_STATUS
    vettingCredentials: IVettingCredential[]
    campaignCredentials: ICampaignCredentials[]
    tnCredentials: ITNCredential[]
    vleiCredentials: VLEICredential[]
  }
  fetchCredentials: (
    enterpriseID: string,
    identifierID: string,
    type: CredentialType
  ) => void
  resetCredentialsStore: () => void
}

const initialState = {
  newCredentialIssued: false,
  credentials: {
    fetchingStatus: FETCHING_STATUS.NOT_INITIATED,
    vettingCredentials: [],
    campaignCredentials: [],
    tnCredentials: [],
    vleiCredentials: []
  }
}

const credentialsStore: StateCreator<IStore, [], [], ICredential> = (
  set,
  get
) => ({
  ...initialState,
  setNewCredentialIssued: (newCredentialIssued: boolean) =>
    set(() => ({ newCredentialIssued })),
  fetchCredentials: async (_enterpriseID, _identifierID, _type) => {
    try {
      set(
        produce((state) => {
          state.credentials.fetchingStatus = FETCHING_STATUS.FETCHING
        })
      )

      ////use signify store to ge credentials
      const credentials = null //await getCredentials(enterpriseID, identifierID, type)
      const { allIdentifiers } = get()
      const vettingCredentials = prepareVettingCredentials(
        credentials,
        allIdentifiers
      )
      const campaignCredentials = prepareCampaignCredential(
        credentials,
        allIdentifiers
      )
      const tnCredentials = prepareTNCredentials(credentials, allIdentifiers)
      const vleiCredentials = prepareLEICredentials(credentials, allIdentifiers)

      set(() => ({
        credentials: {
          fetchingStatus: FETCHING_STATUS.COMPLETE,
          vettingCredentials,
          campaignCredentials,
          tnCredentials,
          vleiCredentials
        }
      }))
    } catch (ex) {
      set(
        produce((state) => {
          state.credentials.fetchingStatus = FETCHING_STATUS.FAILED
        })
      )
    }
  },
  resetCredentialsStore: () => {
    set(initialState)
  }
})

export default credentialsStore
