import { SignifyClient } from 'signify-ts'

export const getOobi = async (
  client: SignifyClient,
  name: string,
  role: string = 'agent'
): Promise<any> => {
  const result = await client.oobis().get(name, role)
  return result
}
