import { IssueCredentialResult, Saider, SignifyClient } from 'signify-ts'
import { waitOperationWithRetries } from '@/services/wallet'
import constants from '@/config/constants'
import { OorAuthCredPayload, OorCredPayload } from '@/services/wallet'
import { Notification } from '@/services/wallet'
import { markNotification } from '@/services/signify'
import { sendCredIssueExnMessage } from '@/services/wallet'
import { rules } from './rules'

export async function initiateOorIssuance(
  client: SignifyClient,
  alias: string,
  credData: OorCredPayload,
  reg: any,
  recipient: any
) {
  let { attributes, edge, rules } = formatOorCredData(credData)

  const time = new Date().toISOString().replace('Z', '000+00:00')
  const issueResult: IssueCredentialResult = await client.credentials().issue({
    issuerName: alias,
    registryId: reg,
    schemaId: constants.CREDENTIAL_TYPES.OOR,
    recipient: recipient,
    data: attributes,
    rules: rules && Saider.saidify({ d: '', rules })[1],
    source: edge && Saider.saidify({ d: '', edge })[1],
    datetime: time
  })

  await sendCredIssueExnMessage(client, alias, issueResult)

  await waitOperationWithRetries(client, issueResult.op, 500)
}

export async function autoJoinOorIssuance(
  client: SignifyClient,
  notification: Notification,
  exnMsg: any
) {
  let alias = exnMsg?.groupName
  let registries = await client.registries().list(alias)
  let reg = registries?.[0]?.regk

  const vcdata = {
    LEI: exnMsg.exn.e.acdc.a.LEI,
    personLegalName: exnMsg.exn.e.acdc.a.personLegalName,
    officialRole: exnMsg.exn.e.acdc.a.officialRole
  }

  const issueResult = await client.credentials().issue({
    issuerName: alias,
    registryId: reg,
    schemaId: constants.CREDENTIAL_TYPES.OOR,
    data: vcdata,
    datetime: exnMsg.exn.e.acdc.a.dt,
    recipient: exnMsg.exn.e.acdc.a.i,
    rules: exnMsg.exn.e.acdc.r,
    source: exnMsg.exn.e.acdc.e
  })

  await sendCredIssueExnMessage(client, alias, issueResult)
  await markNotification(client, notification.i)

  await waitOperationWithRetries(client, issueResult.op, 500)
}

export async function initiateOorAuthIssuance(
  client: SignifyClient,
  alias: string,
  credData: OorAuthCredPayload,
  registry: string,
  recipient: string
) {
  let { attributes, edge, rules } = formatOorAuthCredData(credData)

  const time = new Date().toISOString().replace('Z', '000+00:00')
  const issueResult: IssueCredentialResult = await client.credentials().issue({
    issuerName: alias,
    registryId: registry,
    schemaId: constants.CREDENTIAL_TYPES.OOR_AUTH,
    recipient: recipient,
    data: attributes,
    rules: rules && Saider.saidify({ d: '', rules })[1],
    source: edge && Saider.saidify({ d: '', edge })[1],
    datetime: time
  })

  await sendCredIssueExnMessage(client, alias, issueResult)

  await waitOperationWithRetries(client, issueResult.op, 500)
}

export async function joinOorAuth(
  client: SignifyClient,
  alias: string,
  exnMsg: any
) {
  let registries = await client.registries().list(alias)
  let reg = registries?.[0]?.regk

  const vcdata = {
    AID: exnMsg.exn.e.acdc.a.AID,
    LEI: exnMsg.exn.e.acdc.a.LEI,
    personLegalName: exnMsg.exn.e.acdc.a.personLegalName,
    officialRole: exnMsg.exn.e.acdc.a.officialRole
  }

  const issueResult = await client.credentials().issue({
    issuerName: alias,
    registryId: reg,
    schemaId: constants.CREDENTIAL_TYPES.OOR_AUTH,
    data: vcdata,
    datetime: exnMsg.exn.e.acdc.a.dt,
    recipient: exnMsg.exn.e.acdc.a.i,
    rules: exnMsg.exn.e.acdc.r,
    source: exnMsg.exn.e.acdc.e
  })

  await sendCredIssueExnMessage(client, alias, issueResult)

  await waitOperationWithRetries(client, issueResult.op, 500)
}

export async function autoJoinOorAuth(
  client: SignifyClient,
  notification: Notification,
  exnMsg: any
) {
  let alias = exnMsg?.groupName
  let registries = await client.registries().list(alias)
  let reg = registries?.[0]?.regk

  const vcdata = {
    AID: exnMsg.exn.e.acdc.a.AID,
    LEI: exnMsg.exn.e.acdc.a.LEI,
    personLegalName: exnMsg.exn.e.acdc.a.personLegalName,
    officialRole: exnMsg.exn.e.acdc.a.officialRole
  }

  const issueResult = await client.credentials().issue({
    issuerName: alias,
    registryId: reg,
    schemaId: constants.CREDENTIAL_TYPES.OOR_AUTH,
    data: vcdata,
    datetime: exnMsg.exn.e.acdc.a.dt,
    recipient: exnMsg.exn.e.acdc.a.i,
    rules: exnMsg.exn.e.acdc.r,
    source: exnMsg.exn.e.acdc.e
  })

  await sendCredIssueExnMessage(client, alias, issueResult)
  await markNotification(client, notification.i)

  await waitOperationWithRetries(client, issueResult.op, 500)
}

export function formatOorCredData(payload: OorCredPayload): {
  attributes: any
  edge: any
  rules: any
} {
  const attributes = {
    LEI: payload.lei,
    personLegalName: payload.personLegalName,
    officialRole: payload.officialRole
  }

  const edge = {
    auth: {
      n: payload.oorAuthCredSaid,
      s: constants.CREDENTIAL_TYPES.OOR_AUTH,
      o: 'I2I'
    }
  }
  return { attributes, edge, rules }
}

export function formatOorAuthCredData(payload: OorAuthCredPayload): {
  attributes: any
  edge: any
  rules: any
} {
  const attributes = {
    AID: payload.oorRecipientAid,
    LEI: payload.lei,
    personLegalName: payload.personLegalName,
    officialRole: payload.officialRole
  }

  const edge = {
    le: {
      n: payload.leCredSaid,
      s: constants.CREDENTIAL_TYPES.LEI
    }
  }

  return { attributes, edge, rules }
}
