import {
  NotificationUpdateDto,
  UpdateNewUINotificationRequest
} from '@/api/origin-notification-svc'
import { uiNotificationService } from '@/utils/apis'

export async function getUserNotifications(
  userId: string,
  isRead: boolean = false
) {
  return await uiNotificationService.getUINotificationsByUserIdAndIsRead({
    userId,
    isRead
  })
  // return await get(`/ui-notification/user/${userId}?is_read=${isRead}`, {
  //   BASE_URL: constants.NOTIFICATION_URL
  // })
}

export async function markUiNotificationAsRead(
  notification: NotificationUpdateDto
) {
  return await uiNotificationService.updateNewUINotification({
    notificationUpdateDto: notification
  } as UpdateNewUINotificationRequest)
}
