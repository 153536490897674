import Loading from '@/components/common/Loading'
import ErrorRound from '@/components/icons/ErrorRound'
import VerifyRoundIcon from '@/components/icons/VerifyRoundIcon'
import { FETCHING_STATUS } from '@/config/constants'
import { IBMPlexSans } from '@/config/font'
import { selectedLocale } from '@/config/lang'
import customTheme from '@/config/theme'
import { useServiceProvider } from '@/features/onboarding/hooks/useServiceProvider'
import AuthenticatedPageLayout from '@/layouts/AuthenticatedPageLayout'
import PublicPage from '@/layouts/PublicPage'
import { AuthStatus } from '@/state/authentication'
import useStore from '@/state/store'
import '@/styles/globals.scss'
import { isPublicPrivateRoute, isPublicRoute } from '@/utils/auth'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Flowbite } from 'flowbite-react'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

const queryClient = new QueryClient()

export default function App(appProps: AppProps) {
  const router = useRouter()
  const isPublicPage = isPublicRoute(router.route)
  const isPublicPrivatePage = isPublicPrivateRoute(router.route)

  useServiceProvider()

  const { setSession, getMessages, authentication, localization } = useStore()

  useEffect(() => {
    localStorage.getItem('mockIdVerification') ||
      localStorage.setItem('mockIdVerification', 'false')

    ////TODO: refractor this
    if (!isPublicPrivatePage) {
      if (!isPublicPage && authentication.authStatus === AuthStatus.SignedOut) {
        router.push('/auth/signin')
      } else if (
        isPublicPage &&
        authentication.authStatus === AuthStatus.SignedIn
      ) {
        if (!router.route.match(/\/documents\/\[id\]\/guest-viewer/)) {
          router.push('/')
        }
      }
    }
  }, [router.route, authentication])

  useEffect(() => {
    ;(async () => {
      await getMessages(selectedLocale)
      await setSession()
    })()
  }, [])

  const Application = (appProps: AppProps) => {
    ////TODO: refractor this
    if (authentication.authStatus === AuthStatus.Loading) {
      return <Loading />
    } else if (authentication.authStatus === AuthStatus.SignedIn) {
      if (isPublicPrivatePage || !isPublicPage) {
        return <AuthenticatedPageLayout appProps={appProps} />
      } else if (
        isPublicPage &&
        router.route.match(/\/documents\/\[id\]\/guest-viewer/)
      ) {
        return <appProps.Component {...appProps.pageProps} />
      }
    } else {
      if (isPublicPrivatePage || isPublicPage) {
        // return <PublicPage appProps={appProps} />
        if (router.route.match(/\/documents\/\[id\]\/guest-viewer/)) {
          return <appProps.Component {...appProps.pageProps} />
        } else {
          return <PublicPage appProps={appProps} />
        }
      }
    }
  }

  return (
    <Flowbite theme={{ theme: customTheme }}>
      <QueryClientProvider client={queryClient}>
        <IntlProvider
          locale={selectedLocale}
          messages={localization.symbolicTextIdToLocalizedText}
        >
          <main className={IBMPlexSans.className}>
            {[FETCHING_STATUS.FETCHING, FETCHING_STATUS.NOT_INITIATED].includes(
              localization.fetchingStatus
            ) ? (
              <Loading />
            ) : (
              Application(appProps)
            )}
          </main>
          <ToastContainer
            position="top-center"
            transition={Slide}
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            draggable={false}
            theme="light"
            icon={({ type }) => {
              if (type === 'success') return <VerifyRoundIcon mode="success" />
              if (type === 'error') return <ErrorRound weight="bold" />
            }}
          />
        </IntlProvider>
      </QueryClientProvider>
    </Flowbite>
  )
}
