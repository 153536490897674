/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuccessResponseObject
 */
export interface SuccessResponseObject {
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponseObject
     */
    status?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseObject
     */
    timeStamp?: string;
    /**
     * 
     * @type {string}
     * @memberof SuccessResponseObject
     */
    message?: SuccessResponseObjectMessageEnum;
    /**
     * 
     * @type {object}
     * @memberof SuccessResponseObject
     */
    data?: object;
}


/**
 * @export
 */
export const SuccessResponseObjectMessageEnum = {
    UserSignedUp: 'USER_SIGNED_UP',
    UserConfirmed: 'USER_CONFIRMED',
    UserComplete: 'USER_COMPLETE',
    WalletSaved: 'WALLET_SAVED',
    AlreadyConfirmed: 'ALREADY_CONFIRMED',
    AlreadyOnboarded: 'ALREADY_ONBOARDED',
    ResentConfirmationCode: 'RESENT_CONFIRMATION_CODE',
    Ok: 'OK'
} as const;
export type SuccessResponseObjectMessageEnum = typeof SuccessResponseObjectMessageEnum[keyof typeof SuccessResponseObjectMessageEnum];


/**
 * Check if a given object implements the SuccessResponseObject interface.
 */
export function instanceOfSuccessResponseObject(value: object): boolean {
    return true;
}

export function SuccessResponseObjectFromJSON(json: any): SuccessResponseObject {
    return SuccessResponseObjectFromJSONTyped(json, false);
}

export function SuccessResponseObjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuccessResponseObject {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : json['status'],
        'timeStamp': json['timeStamp'] == null ? undefined : json['timeStamp'],
        'message': json['message'] == null ? undefined : json['message'],
        'data': json['data'] == null ? undefined : json['data'],
    };
}

export function SuccessResponseObjectToJSON(value?: SuccessResponseObject | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'timeStamp': value['timeStamp'],
        'message': value['message'],
        'data': value['data'],
    };
}

