/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  IdVerificationInfoDto,
  NewIdVerificationRequest,
  NewIdVerificationResponse,
} from '../models/index';
import {
    IdVerificationInfoDtoFromJSON,
    IdVerificationInfoDtoToJSON,
    NewIdVerificationRequestFromJSON,
    NewIdVerificationRequestToJSON,
    NewIdVerificationResponseFromJSON,
    NewIdVerificationResponseToJSON,
} from '../models/index';

export interface VerificationResultRequest {
    userId: string;
}

export interface VerifyIdRequest {
    newIdVerificationRequest: NewIdVerificationRequest;
    mocked?: boolean;
}

/**
 * 
 */
export class IdVerificationControllerApi extends runtime.BaseAPI {

    /**
     */
    async verificationResultRaw(requestParameters: VerificationResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdVerificationInfoDto>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling verificationResult().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/id-verification/{user-id}`.replace(`{${"user-id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdVerificationInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async verificationResult(requestParameters: VerificationResultRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdVerificationInfoDto> {
        const response = await this.verificationResultRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async verifyIdRaw(requestParameters: VerifyIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewIdVerificationResponse>> {
        if (requestParameters['newIdVerificationRequest'] == null) {
            throw new runtime.RequiredError(
                'newIdVerificationRequest',
                'Required parameter "newIdVerificationRequest" was null or undefined when calling verifyId().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mocked'] != null) {
            queryParameters['mocked'] = requestParameters['mocked'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/id-verification/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewIdVerificationRequestToJSON(requestParameters['newIdVerificationRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewIdVerificationResponseFromJSON(jsonValue));
    }

    /**
     */
    async verifyId(requestParameters: VerifyIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewIdVerificationResponse> {
        const response = await this.verifyIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
