import LeftSidebar from '@/components/common/LeftSidebar'
import Loading from '@/components/common/Loading'
import SignifyView from '@/components/common/SignifyView'
import TopNavbar from '@/components/common/TopNavBar'
import HelpAndSupport from '@/features/common/HelpAndSupport'
import useUserDetails from '@/hooks/useUserDetails'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import NextNProgress from 'nextjs-progressbar'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import tailwindConfig from 'tailwind.config'
import useNotificationProcessor from '@/hooks/useNotificationProcessor'

interface IProps {
  appProps: AppProps
}

const AuthenticatedPageLayout = ({
  appProps: { Component, pageProps }
}: IProps) => {
  const { formatMessage } = useIntl()
  const { loading } = useUserDetails()
  const [showHelpOptions, setShowHelpOptions] = useState(false)

  useNotificationProcessor()

  // @ts-ignore
  const getLayout = Component.getLayout

  if (loading) return <Loading />
  else if (undefined !== getLayout)
    return getLayout(<Component {...pageProps} />)
  else
    return (
      <>
        <Head>
          <title>{formatMessage({ id: 'app.title' })}</title>
        </Head>

        <NextNProgress
          color={tailwindConfig.theme.extend.colors.white}
          options={{ showSpinner: false }}
        />
        <TopNavbar />
        <SignifyView />
        <div
          className="flex mt-[55px] min-h-[calc(100vh-55px)]"
          onClick={() => showHelpOptions && setShowHelpOptions(false)}
        >
          <aside className="hidden sm:w-2/12 sm:block bg-grey border-gray-200 border-r-[1px]">
            <LeftSidebar />
            <div className="fixed w-[inherit] bottom-0">
              <HelpAndSupport
                showHelpOptions={showHelpOptions}
                onClick={() => setShowHelpOptions(!showHelpOptions)}
              />
              <p className="p-5 text-sm text-center border-t opacity-50">
                {formatMessage({ id: 'app.orgName' })}
              </p>
            </div>
          </aside>
          <main className={`w-full sm:w-10/12`}>
            <Component {...pageProps} />
          </main>
        </div>
      </>
    )
}

export default AuthenticatedPageLayout
