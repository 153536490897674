/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailDto } from './EmailDto';
import {
    EmailDtoFromJSON,
    EmailDtoFromJSONTyped,
    EmailDtoToJSON,
} from './EmailDto';
import type { GrantInfo } from './GrantInfo';
import {
    GrantInfoFromJSON,
    GrantInfoFromJSONTyped,
    GrantInfoToJSON,
} from './GrantInfo';
import type { OrgDto } from './OrgDto';
import {
    OrgDtoFromJSON,
    OrgDtoFromJSONTyped,
    OrgDtoToJSON,
} from './OrgDto';
import type { PhoneNumberDto } from './PhoneNumberDto';
import {
    PhoneNumberDtoFromJSON,
    PhoneNumberDtoFromJSONTyped,
    PhoneNumberDtoToJSON,
} from './PhoneNumberDto';
import type { UserPrefDto } from './UserPrefDto';
import {
    UserPrefDtoFromJSON,
    UserPrefDtoFromJSONTyped,
    UserPrefDtoToJSON,
} from './UserPrefDto';

/**
 * 
 * @export
 * @interface UserInfoDto
 */
export interface UserInfoDto {
    /**
     * 
     * @type {string}
     * @memberof UserInfoDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDto
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDto
     */
    legalPersonalNames?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDto
     */
    legalFamilyNames?: string;
    /**
     * 
     * @type {Array<EmailDto>}
     * @memberof UserInfoDto
     */
    emails?: Array<EmailDto>;
    /**
     * 
     * @type {Array<PhoneNumberDto>}
     * @memberof UserInfoDto
     */
    phoneNumbers?: Array<PhoneNumberDto>;
    /**
     * 
     * @type {Array<OrgDto>}
     * @memberof UserInfoDto
     */
    orgs?: Array<OrgDto>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserInfoDto
     * @deprecated
     */
    grants?: Array<string>;
    /**
     * 
     * @type {Array<GrantInfo>}
     * @memberof UserInfoDto
     */
    grantInfos?: Array<GrantInfo>;
    /**
     * 
     * @type {UserPrefDto}
     * @memberof UserInfoDto
     */
    userPref?: UserPrefDto;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDto
     */
    accountStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDto
     */
    sessionCss?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfoDto
     */
    keriaAgentUrl?: string;
}

/**
 * Check if a given object implements the UserInfoDto interface.
 */
export function instanceOfUserInfoDto(value: object): boolean {
    return true;
}

export function UserInfoDtoFromJSON(json: any): UserInfoDto {
    return UserInfoDtoFromJSONTyped(json, false);
}

export function UserInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'legalPersonalNames': json['legalPersonalNames'] == null ? undefined : json['legalPersonalNames'],
        'legalFamilyNames': json['legalFamilyNames'] == null ? undefined : json['legalFamilyNames'],
        'emails': json['emails'] == null ? undefined : ((json['emails'] as Array<any>).map(EmailDtoFromJSON)),
        'phoneNumbers': json['phoneNumbers'] == null ? undefined : ((json['phoneNumbers'] as Array<any>).map(PhoneNumberDtoFromJSON)),
        'orgs': json['orgs'] == null ? undefined : ((json['orgs'] as Array<any>).map(OrgDtoFromJSON)),
        'grants': json['grants'] == null ? undefined : json['grants'],
        'grantInfos': json['grantInfos'] == null ? undefined : ((json['grantInfos'] as Array<any>).map(GrantInfoFromJSON)),
        'userPref': json['userPref'] == null ? undefined : UserPrefDtoFromJSON(json['userPref']),
        'accountStatus': json['accountStatus'] == null ? undefined : json['accountStatus'],
        'sessionCss': json['sessionCss'] == null ? undefined : json['sessionCss'],
        'keriaAgentUrl': json['keriaAgentUrl'] == null ? undefined : json['keriaAgentUrl'],
    };
}

export function UserInfoDtoToJSON(value?: UserInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'legalPersonalNames': value['legalPersonalNames'],
        'legalFamilyNames': value['legalFamilyNames'],
        'emails': value['emails'] == null ? undefined : ((value['emails'] as Array<any>).map(EmailDtoToJSON)),
        'phoneNumbers': value['phoneNumbers'] == null ? undefined : ((value['phoneNumbers'] as Array<any>).map(PhoneNumberDtoToJSON)),
        'orgs': value['orgs'] == null ? undefined : ((value['orgs'] as Array<any>).map(OrgDtoToJSON)),
        'grants': value['grants'],
        'grantInfos': value['grantInfos'] == null ? undefined : ((value['grantInfos'] as Array<any>).map(GrantInfoToJSON)),
        'userPref': UserPrefDtoToJSON(value['userPref']),
        'accountStatus': value['accountStatus'],
        'sessionCss': value['sessionCss'],
        'keriaAgentUrl': value['keriaAgentUrl'],
    };
}

