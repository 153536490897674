/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResponseDto
 */
export interface ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ResponseDto
     */
    workflowInstanceId?: string;
}

/**
 * Check if a given object implements the ResponseDto interface.
 */
export function instanceOfResponseDto(value: object): boolean {
    return true;
}

export function ResponseDtoFromJSON(json: any): ResponseDto {
    return ResponseDtoFromJSONTyped(json, false);
}

export function ResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'] == null ? undefined : json['workflowInstanceId'],
    };
}

export function ResponseDtoToJSON(value?: ResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
    };
}

