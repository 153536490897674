import { FormattedMessage } from 'react-intl'
import SidebarNav, { ILink } from '@/components/base/SidebarNav'
import ConnectionIcon from '@/components/icons/ConnectionIcon'
import HoldIcon from '@/components/icons/HoldIcon'
import IssueIcon from '@/components/icons/IssueIcon'
import SettingsIcon from '@/components/icons/SettingsIcon'
import VerifyIcon from '@/components/icons/VerifyIcon'
import { useRouter } from 'next/router'
import QviSidebarNav from './QviSidebarNav'
import SquareBox from '../icons/SquareBox'

const links: ILink[] = [
  {
    href: '/',
    label: <FormattedMessage id="sideBar.link.myWork" />,
    icon: <ConnectionIcon />
  },
  {
    href: '/identifiers',
    label: <FormattedMessage id="sideBar.link.identifiers" />,
    icon: <HoldIcon />
  },
  {
    href: '/issue',
    label: <FormattedMessage id="sideBar.link.issue" />,
    icon: <IssueIcon />
  },
  {
    href: '/hold',
    label: <FormattedMessage id="sideBar.link.hold" />,
    icon: <HoldIcon />
  },
  {
    href: '/verify',
    label: <FormattedMessage id="sideBar.link.verify" />,
    icon: <VerifyIcon />
  },
  {
    href: '/connections',
    label: <FormattedMessage id="sideBar.link.connections" />,
    icon: <ConnectionIcon />
  },
  {
    href: '/settings',
    label: <FormattedMessage id="sideBar.link.settings" />,
    icon: <SettingsIcon />
  },
  {
    href: '/profile',
    label: <FormattedMessage id="sideBar.link.profile" />,
    icon: <SettingsIcon />
  },
  {
    href: '/apps',
    label: <FormattedMessage id="sideBar.link.applications" />,
    icon: <ConnectionIcon />
  }
]

const qviToolkitLinks: ILink[] = [
  {
    href: '/apps/qvi-toolkit',
    label: <FormattedMessage id="sideBar.qviToolkit.link.dashboard" />,
    icon: <SquareBox />
  },
  {
    href: '/apps/qvi-toolkit/workflows',
    label: <FormattedMessage id="sideBar.qviToolkit.link.workflows" />,
    icon: <SquareBox />
  },
  {
    href: '/apps/qvi-toolkit/users',
    label: <FormattedMessage id="sideBar.qviToolkit.link.users" />,
    icon: <SquareBox />
  },
  {
    href: '/apps/qvi-toolkit/settings',
    label: <FormattedMessage id="sideBar.qviToolkit.link.settings" />,
    icon: <SquareBox />
  }
]

const LeftSidebar = () => {
  const router = useRouter()

  if (router.pathname.startsWith('/apps/qvi-toolkit')) {
    return <QviSidebarNav links={qviToolkitLinks} />
  }
  return <SidebarNav links={links} className="fixed w-[inherit] top-[55px]" />
}
export default LeftSidebar
