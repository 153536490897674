/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QVIWorkflowDto } from './QVIWorkflowDto';
import {
    QVIWorkflowDtoFromJSON,
    QVIWorkflowDtoFromJSONTyped,
    QVIWorkflowDtoToJSON,
} from './QVIWorkflowDto';

/**
 * 
 * @export
 * @interface QVIWorkflowResponseDto
 */
export interface QVIWorkflowResponseDto {
    /**
     * 
     * @type {Array<QVIWorkflowDto>}
     * @memberof QVIWorkflowResponseDto
     */
    workflows?: Array<QVIWorkflowDto>;
    /**
     * 
     * @type {number}
     * @memberof QVIWorkflowResponseDto
     */
    totalEntries?: number;
}

/**
 * Check if a given object implements the QVIWorkflowResponseDto interface.
 */
export function instanceOfQVIWorkflowResponseDto(value: object): boolean {
    return true;
}

export function QVIWorkflowResponseDtoFromJSON(json: any): QVIWorkflowResponseDto {
    return QVIWorkflowResponseDtoFromJSONTyped(json, false);
}

export function QVIWorkflowResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): QVIWorkflowResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflows': json['workflows'] == null ? undefined : ((json['workflows'] as Array<any>).map(QVIWorkflowDtoFromJSON)),
        'totalEntries': json['totalEntries'] == null ? undefined : json['totalEntries'],
    };
}

export function QVIWorkflowResponseDtoToJSON(value?: QVIWorkflowResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflows': value['workflows'] == null ? undefined : ((value['workflows'] as Array<any>).map(QVIWorkflowDtoToJSON)),
        'totalEntries': value['totalEntries'],
    };
}

