export class RetryException extends Error {
  retries: number
  maxAttempts: number
  isTimeout: boolean
  isMaxRetries: boolean
  cause: Error | null
  timeSpent: number

  constructor(
    message: string,
    retries: number,
    maxAttempts: number,
    isTimeout: boolean,
    isMaxRetries: boolean,
    cause: Error | null,
    timeSpent: number
  ) {
    super(message)
    this.retries = retries
    this.maxAttempts = maxAttempts
    this.isTimeout = isTimeout
    this.isMaxRetries = isMaxRetries
    this.cause = cause
    this.timeSpent = timeSpent
    this.name = 'RetryException'
  }
}
