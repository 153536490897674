/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LarsInfoDto
 */
export interface LarsInfoDto {
    /**
     * 
     * @type {string}
     * @memberof LarsInfoDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LarsInfoDto
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof LarsInfoDto
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LarsInfoDto
     */
    emails?: Array<string>;
}

/**
 * Check if a given object implements the LarsInfoDto interface.
 */
export function instanceOfLarsInfoDto(value: object): boolean {
    return true;
}

export function LarsInfoDtoFromJSON(json: any): LarsInfoDto {
    return LarsInfoDtoFromJSONTyped(json, false);
}

export function LarsInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LarsInfoDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'emails': json['emails'] == null ? undefined : json['emails'],
    };
}

export function LarsInfoDtoToJSON(value?: LarsInfoDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'emails': value['emails'],
    };
}

