interface IProps {
  fill?: string
  viewBox?: string
  className?: string
}

const CloseIcon = ({
  fill = 'currentColor',
  className = '',
  viewBox = '0 0 20 20'
}: IProps) => {
  return (
    <>
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        fill={fill}
        viewBox={viewBox}
      >
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
      </svg>
    </>
  )
}

export default CloseIcon
