import {
  EcrPrerequisitesRequestDtoWorkflowModeEnum,
  OrgDetailResponseDtoQarVerifiedEnum
} from '@/api/origin-workflow-svc'
import { EcrWorkflow } from '@/models/ecr-vlei-workflow'
import { formatISO, parse, set } from 'date-fns'
import { format } from 'date-fns-tz'
import { isEmpty } from 'lodash'

export const objToQuery = (obj: any) => {
  return new URLSearchParams(obj).toString()
}

export const queryToObj = (queryString: string) => {
  return new URLSearchParams(queryString)
}

export const bytesToSize = (bytes: number): string => {
  const sizes: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i: number = Math.floor(Math.log(bytes) / Math.log(1024))
  return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[i]
}

export const getJWTPayload = (token: string = '') => {
  const encodedPayload = token.split('.')[1] || ''
  const payload = Buffer.from(encodedPayload, 'base64').toString('ascii')
  return !isEmpty(payload) ? JSON.parse(payload) : null
}

export const UTCtoLocal = (utcDateString: string, frmt: string) => {
  const parsedTime = new Date(utcDateString)
  return format(parsedTime, frmt)
}

/**
 * Checks if a given object contains a specific key.
 *
 * @template T - The type of the object being checked.
 * @param {T} obj - The object to check if it has key. It can be of any type.
 * @param {keyof any} key - The key to check for in the object.
 * @returns {boolean} - Returns `true` if the object is neither `null` nor `undefined`,
 *                      is an actual object (not an array), and contains the specified key.
 *                      Otherwise, returns `false`.
 *
 */
export const hasKey = <T>(obj: T, key: keyof any): boolean => {
  if (obj === null || obj === undefined) {
    return false
  }

  if (typeof obj !== 'object' || Array.isArray(obj)) {
    return false
  }

  return key in obj
}

export const ceremonyTypeFromURL = () => {
  const urlObject = new URL(window.location.href)
  const pathSegments = urlObject.pathname.split('/')
  return pathSegments[1].toUpperCase()
}

export const isAllUpperCase = (str: string): boolean => {
  return /^[A-Z0-9]*$/.test(str)
}

export const delay = (timeout: number = 1000): Promise<void> => {
  return new Promise<void>((resolve) => setTimeout(resolve, timeout))
}

export const formatUrlPath = (base: string, path: string): string => {
  if (!base) return null
  const cleanedBase = base.replace(/\/+$/, '')
  const cleanedPath = path.replace(/^\/+/, '')
  return `${cleanedBase}/${cleanedPath}`
}

export const isOrganizationVerified = (
  qarVerified: OrgDetailResponseDtoQarVerifiedEnum
) => {
  return (
    qarVerified === OrgDetailResponseDtoQarVerifiedEnum.OrgVerified ||
    qarVerified === OrgDetailResponseDtoQarVerifiedEnum.RepresentativesVerified
  )
}

export const isRepresentativesApproved = (
  qarVerified: OrgDetailResponseDtoQarVerifiedEnum
) => {
  return (
    qarVerified === OrgDetailResponseDtoQarVerifiedEnum.RepresentativesVerified
  )
}

export const isSelfIssued = (workflow: EcrWorkflow) => {
  return (
    workflow.rawData.args.workflowMode ===
    EcrPrerequisitesRequestDtoWorkflowModeEnum.SelfIssued
  )
}

export const formatToISO8601 = (date: Date, time: string) => {
  time = time.length === 8 ? time : `${time}:00`
  const parsedTime = parse(time, 'HH:mm:ss', new Date())

  const mergedDateTime = set(date, {
    hours: parsedTime.getHours(),
    minutes: parsedTime.getMinutes(),
    seconds: parsedTime.getSeconds()
  })

  return formatISO(mergedDateTime)
}
