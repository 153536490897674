/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrgDto,
  OrgFillingDetailRequestDto,
} from '../models/index';
import {
    OrgDtoFromJSON,
    OrgDtoToJSON,
    OrgFillingDetailRequestDtoFromJSON,
    OrgFillingDetailRequestDtoToJSON,
} from '../models/index';

export interface FindByDomainRequest {
    domain: string;
}

export interface FindCreatedByRequest {
    userId: string;
}

export interface GetLeiStatusRequest {
    orgId: string;
}

export interface GetNameRequest {
    orgId: string;
}

export interface GetOrgRequest {
    orgId: string;
}

export interface NewOrgRequest {
    orgDto: OrgDto;
}

export interface OrgFillingDetailRequest {
    orgFillingDetailRequestDto: OrgFillingDetailRequestDto;
}

export interface SetCompleteStatusRequest {
    orgId: string;
}

export interface UpdateRequest {
    orgId: string;
    body: object;
}

/**
 * 
 */
export class OrgControllerApi extends runtime.BaseAPI {

    /**
     * Retrieves info about an org\'s profile by org\'s domain
     * Find By domain
     */
    async findByDomainRaw(requestParameters: FindByDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['domain'] == null) {
            throw new runtime.RequiredError(
                'domain',
                'Required parameter "domain" was null or undefined when calling findByDomain().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/domain/{domain}`.replace(`{${"domain"}}`, encodeURIComponent(String(requestParameters['domain']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves info about an org\'s profile by org\'s domain
     * Find By domain
     */
    async findByDomain(requestParameters: FindByDomainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto | null | undefined > {
        const response = await this.findByDomainRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get org parameters created by the user
     */
    async findCreatedByRaw(requestParameters: FindCreatedByRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrgDto>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling findCreatedBy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/created-by/{user-id}`.replace(`{${"user-id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrgDtoFromJSON));
    }

    /**
     * Get org parameters created by the user
     */
    async findCreatedBy(requestParameters: FindCreatedByRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrgDto> | null | undefined > {
        const response = await this.findCreatedByRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Refreshes LEI status in GLEIF and returns the updated status value.
     * Get LEI status
     */
    async getLeiStatusRaw(requestParameters: GetLeiStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling getLeiStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/lei-status/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Refreshes LEI status in GLEIF and returns the updated status value.
     * Get LEI status
     */
    async getLeiStatus(requestParameters: GetLeiStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getLeiStatusRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Find an organization name if the organization is present. This endpoint is public.
     * Get org name
     */
    async getNameRaw(requestParameters: GetNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling getName().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/name/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * Find an organization name if the organization is present. This endpoint is public.
     * Get org name
     */
    async getName(requestParameters: GetNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto | null | undefined > {
        const response = await this.getNameRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Retrieves info about an org\'s profile on our platform. These are configuration choices that the org makes.
     * Get org parameters
     */
    async getOrgRaw(requestParameters: GetOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling getOrg().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * Retrieves info about an org\'s profile on our platform. These are configuration choices that the org makes.
     * Get org parameters
     */
    async getOrg(requestParameters: GetOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto | null | undefined > {
        const response = await this.getOrgRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Creates a new org and returns a Location header that tells us the id of the new org in the DB
     * Creates a new org, using the request body (JSON object) as the metadata to store about it
     */
    async newOrgRaw(requestParameters: NewOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['orgDto'] == null) {
            throw new runtime.RequiredError(
                'orgDto',
                'Required parameter "orgDto" was null or undefined when calling newOrg().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrgDtoToJSON(requestParameters['orgDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new org and returns a Location header that tells us the id of the new org in the DB
     * Creates a new org, using the request body (JSON object) as the metadata to store about it
     */
    async newOrg(requestParameters: NewOrgRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto> {
        const response = await this.newOrgRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Org filing detail and public record links
     */
    async orgFillingDetailRaw(requestParameters: OrgFillingDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgFillingDetailRequestDto>> {
        if (requestParameters['orgFillingDetailRequestDto'] == null) {
            throw new runtime.RequiredError(
                'orgFillingDetailRequestDto',
                'Required parameter "orgFillingDetailRequestDto" was null or undefined when calling orgFillingDetail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/org-filing-detail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrgFillingDetailRequestDtoToJSON(requestParameters['orgFillingDetailRequestDto']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgFillingDetailRequestDtoFromJSON(jsonValue));
    }

    /**
     * Update Org filing detail and public record links
     */
    async orgFillingDetail(requestParameters: OrgFillingDetailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgFillingDetailRequestDto> {
        const response = await this.orgFillingDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates org status to COMPLETE
     */
    async setCompleteStatusRaw(requestParameters: SetCompleteStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling setCompleteStatus().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/initial-onboarding/{org-id}`.replace(`{${"org-id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates org status to COMPLETE
     */
    async setCompleteStatus(requestParameters: SetCompleteStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.setCompleteStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The request body is a JSON Patch object that explains what we want to replace, insert, or delete compared to what we already have.
     * Updates our own records for a specific org 
     */
    async updateRaw(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrgDto>> {
        if (requestParameters['orgId'] == null) {
            throw new runtime.RequiredError(
                'orgId',
                'Required parameter "orgId" was null or undefined when calling update().'
            );
        }

        if (requestParameters['body'] == null) {
            throw new runtime.RequiredError(
                'body',
                'Required parameter "body" was null or undefined when calling update().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v1/org/{org_id}`.replace(`{${"org_id"}}`, encodeURIComponent(String(requestParameters['orgId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrgDtoFromJSON(jsonValue));
    }

    /**
     * The request body is a JSON Patch object that explains what we want to replace, insert, or delete compared to what we already have.
     * Updates our own records for a specific org 
     */
    async update(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrgDto> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
