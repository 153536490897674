import constants from '@/config/constants'
import routeSettings from '@/config/routeSettings'
import { UserInfo } from '@/state/authentication'
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool
} from 'amazon-cognito-identity-js'
import { head, isEmpty } from 'lodash'
import { sha256Encode } from './crypto'
import type { PossibleOorRecipientDto } from '@/api/origin-workflow-svc'

export type CognitoAttribute = {
  Name: string
  Value: string
}

var poolData = {
  UserPoolId: constants.AWS_CLIENT_POOL_ID,
  ClientId: constants.AWS_CLIENT_ID
}

var userPool = new CognitoUserPool(poolData)
let currentUser: any = userPool.getCurrentUser()

export function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool
  }
  const cognitoUser = new CognitoUser(userData)

  return cognitoUser
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser()
  }

  return new Promise(function (resolve, reject) {
    if (currentUser)
      currentUser.getSession(function (err: any, session: any) {
        if (err) {
          reject(err)
        } else {
          resolve(session)
        }
      })
    else resolve(null)
  }).catch((err) => {
    throw err
  })
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    currentUser.getUserAttributes(function (err: any, attributes: any) {
      if (err) {
        reject(err)
      } else {
        resolve(attributes)
      }
    })
  }).catch((err) => {
    throw err
  })
}

export async function login(username: string, password: string) {
  const Password = await sha256Encode(password)
  const authenticationData = {
    Username: username.toLowerCase(),
    Password
  }
  const authenticationDetails = new AuthenticationDetails(authenticationData)
  currentUser = getCognitoUser(username)

  return new Promise((resolve, reject) => {
    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res: any) {
        resolve(res)
      },
      onFailure: function (err: any) {
        reject(err)
      }
    })
  })
}

export const logout = () => {
  if (currentUser) {
    currentUser.signOut()
  }
}

export const isAuthenticated = async () => {
  try {
    const session = await getSession()
    return session
  } catch (err) {
    throw err
  }
}

export const isPublicRoute = (path: string) => {
  let isPublic = false
  routeSettings.PUBLIC_URL.forEach((URL) => {
    if (isPublic) return
    isPublic = !isEmpty(path.match(URL))
  })
  return isPublic
}

export const isPublicPrivateRoute = (path: string) => {
  let isPublicPrivate = false
  routeSettings.PUBLIC_PRIVATE_URL.forEach((URL) => {
    if (isPublicPrivate) return
    isPublicPrivate = !isEmpty(path.match(URL))
  })
  return isPublicPrivate
}

export const cognitoAttrToUserInfo = (attributes: CognitoAttribute[]) => {
  const userInfo: any = {}
  const emailAttribute = attributes.find((item) => item.Name === 'email')
  const subAttribute = attributes.find((item) => item.Name === 'sub')

  if (emailAttribute) {
    userInfo.emails = [{ value: emailAttribute.Value }]
  }
  if (subAttribute) {
    userInfo.sub = subAttribute.Value
  }
  return userInfo as UserInfo
}

export const getJWTToken = () => {
  if (currentUser?.username) {
    const token = localStorage.getItem(
      `CognitoIdentityServiceProvider.${constants.AWS_CLIENT_ID}.${currentUser.username}.idToken`
    )
    if (!isEmpty(token)) return `Bearer ${token}`
  }
  return null
}

export const isDAR = (grants: string[] = []) => {
  return grants

    .map((grant) => grant.toUpperCase())
    .includes(constants.ROLES.DAR.toUpperCase())
}

export const isLAR = (grants: string[] = []) => {
  return grants
    .map((grant) => grant.toUpperCase())
    .includes(constants.ROLES.LAR.toUpperCase())
}

export const isOnlyLAR = (grants: string[] = []) => {
  const gts = grants
    .map((grant) => grant.toUpperCase())
    .filter((grant) => grant !== constants.ROLES.EMPLOYEE)

  return gts.length === 1 && gts.includes(constants.ROLES.LAR)
}

export const isQAR = (grants: string[] = []) => {
  return grants
    .map((grant) => grant.toUpperCase())
    .includes(constants.ROLES.QAR.toUpperCase())
}

export const isOOR = (
  userId: string,
  recipients: PossibleOorRecipientDto[]
) => {
  return recipients.some((recipient) => recipient.userId === userId)
}

const _titleShortTitleAsRecipient = (
  id: string,
  recipients: PossibleOorRecipientDto[]
) => {
  const recipient = recipients.find((recipient) => recipient.userId === id)
  if (isEmpty(recipient)) return

  const possibleTitleAndGrants = recipient.titleAndGrantStatusList.filter(
    (titleAndGrant) =>
      !Object.keys(constants.ROLES).includes(titleAndGrant.title.toUpperCase())
  )

  const shortTitle = head(possibleTitleAndGrants)?.shortTitle
  const title = head(possibleTitleAndGrants)?.title
  return { shortTitle, title }
}

export const shortTitleAsRecipient = (
  id: string,
  recipients: PossibleOorRecipientDto[]
) => {
  const result = _titleShortTitleAsRecipient(id, recipients)
  return result?.shortTitle
}

export const titleAsRecipient = (
  id: string,
  recipients: PossibleOorRecipientDto[]
) => {
  const result = _titleShortTitleAsRecipient(id, recipients)
  return result?.title
}

export const isLARAndOOR = (
  grants: string[] = [],
  oorRecipientRole: string = ''
) => {
  const upperCaseGrants = grants.map((grant) => grant.toUpperCase())
  return (
    upperCaseGrants.includes(constants.ROLES.LAR) &&
    upperCaseGrants.includes(oorRecipientRole.toUpperCase())
  )
}
