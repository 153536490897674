/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProposedUserDto } from './ProposedUserDto';
import {
    ProposedUserDtoFromJSON,
    ProposedUserDtoFromJSONTyped,
    ProposedUserDtoToJSON,
} from './ProposedUserDto';

/**
 * 
 * @export
 * @interface DarReplacementRequestDto
 */
export interface DarReplacementRequestDto {
    /**
     * 
     * @type {string}
     * @memberof DarReplacementRequestDto
     */
    workflowInstanceId: string;
    /**
     * 
     * @type {boolean}
     * @memberof DarReplacementRequestDto
     */
    isRequesterDAR?: boolean;
    /**
     * 
     * @type {ProposedUserDto}
     * @memberof DarReplacementRequestDto
     */
    proposedDAR?: ProposedUserDto;
}

/**
 * Check if a given object implements the DarReplacementRequestDto interface.
 */
export function instanceOfDarReplacementRequestDto(value: object): boolean {
    if (!('workflowInstanceId' in value)) return false;
    return true;
}

export function DarReplacementRequestDtoFromJSON(json: any): DarReplacementRequestDto {
    return DarReplacementRequestDtoFromJSONTyped(json, false);
}

export function DarReplacementRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DarReplacementRequestDto {
    if (json == null) {
        return json;
    }
    return {
        
        'workflowInstanceId': json['workflowInstanceId'],
        'isRequesterDAR': json['isRequesterDAR'] == null ? undefined : json['isRequesterDAR'],
        'proposedDAR': json['proposedDAR'] == null ? undefined : ProposedUserDtoFromJSON(json['proposedDAR']),
    };
}

export function DarReplacementRequestDtoToJSON(value?: DarReplacementRequestDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'workflowInstanceId': value['workflowInstanceId'],
        'isRequesterDAR': value['isRequesterDAR'],
        'proposedDAR': ProposedUserDtoToJSON(value['proposedDAR']),
    };
}

