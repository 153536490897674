import { useState, useEffect, useCallback } from 'react'

type StorageType = 'local' | 'session'

type Storage = {
  getItem: (key: string) => string | null
  setItem: (key: string, value: string) => void
  removeItem: (key: string) => void
}

const isBrowser = typeof window !== 'undefined'

// Create a dummy storage object for non-browser environments
const dummyStorage: Storage = {
  getItem: () => null,
  setItem: () => {},
  removeItem: () => {}
}

function getStorageObject(type: StorageType): Storage {
  if (!isBrowser) return dummyStorage

  try {
    const storageObj =
      type === 'local' ? window.localStorage : window.sessionStorage
    // Test if storage is accessible
    const testKey = `__storage_test__`
    storageObj.setItem(testKey, testKey)
    storageObj.removeItem(testKey)
    return storageObj
  } catch (e) {
    console.warn(
      `${type}Storage is not available. Falling back to dummy storage.`
    )
    return dummyStorage
  }
}

export function useStorage<T>(
  key: string,
  storageType: StorageType = 'local',
  defaultValue?: T | (() => T)
): [
  T | undefined,
  (value: T | undefined | ((prev: T | undefined) => T | undefined)) => void
] {
  const storage = getStorageObject(storageType)

  const [value, setValue] = useState<T | undefined>(() => {
    const storedValue = storage.getItem(key)
    if (storedValue !== null) {
      try {
        return JSON.parse(storedValue) as T
      } catch {
        return typeof defaultValue === 'function'
          ? (defaultValue as () => T)()
          : defaultValue
      }
    }
    return typeof defaultValue === 'function'
      ? (defaultValue as () => T)()
      : defaultValue
  })

  const updateValue = useCallback(
    (newValue: T | undefined | ((prev: T | undefined) => T | undefined)) => {
      setValue((prevValue: T | undefined) => {
        const nextValue =
          typeof newValue === 'function'
            ? (newValue as (prev: T | undefined) => T | undefined)(prevValue)
            : newValue

        if (nextValue === undefined) {
          storage.removeItem(key)
        } else {
          try {
            const serializedValue = JSON.stringify(nextValue)
            storage.setItem(key, serializedValue)
          } catch (error) {
            console.error('Failed to serialize the value', error)
          }
        }

        return nextValue
      })
    },
    [key, storage]
  )

  // Listen for changes in other components and tabs/windows
  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === key) {
        if (e.newValue === null) {
          updateValue(undefined)
        } else {
          try {
            updateValue(JSON.parse(e.newValue) as T)
          } catch {
            console.error('Failed to parse storage value')
          }
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key])

  return [value, updateValue]
}
