export async function generatePassCode(length: number = 21): Promise<string> {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const passCodeArray = new Uint8Array(length)

  try {
    await crypto.getRandomValues(passCodeArray)

    let passCode = Array.from(passCodeArray)
      .map((value) => characters[value % characters.length])
      .join('')

    const hasUppercase = /[A-Z]/.test(passCode)
    const hasLowercase = /[a-z]/.test(passCode)
    const hasDigit = /[0-9]/.test(passCode)

    if (!hasUppercase || !hasLowercase || !hasDigit) {
      return generatePassCode(length)
    }

    return passCode
  } catch (error) {
    throw error
  }
}

export const sha256Encode = async (value: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(value)
  const buffer = await crypto.subtle.digest('SHA-256', data)

  const hashArray = Array.from(new Uint8Array(buffer))
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')
  return hashHex
}
