import { captureException, Scope, withScope } from '@sentry/nextjs'

export enum SentryContextEnum {
  QARMultisigCompleteWorkflow = 'QAR Multistig Complete Workflow',
  LARMultisigStartWorkflow = 'LAR Multistig Start Workflow',
  LARAuthorization = 'LAR Authorization',
  JoinRevocationCredential = 'JoinRevocationCredential',
  SecondaryQARWorkflow = 'Secondary QAR Workflow',
  SecondaryQAROORWorkflow = 'Secondary QAR OOR Workflow',
  QARFinalOOR = 'QAR Final OOR',
  QARAuthorization = 'QAR Authorization',
  ParticipantLeft = 'Participant Left'
}

export enum SentryTagEnum {
  DEFAULT = 'default-error'
}

export enum SentryLevelsEnum {
  FATAL = 'fatal',
  ERROR = 'error',
  WARNING = 'warning',
  LOG = 'log',
  INFO = 'info',
  DEBUG = 'debug'
}

export function captureErrorWithScope(
  fn: (scope: Scope) => void,
  error: Error
) {
  withScope((scope) => {
    scope.setTag(SentryTagEnum.DEFAULT, 'custom')
    scope.setLevel(SentryLevelsEnum.ERROR)
    fn(scope)
    captureException(error)
  })
}
