import React from 'react'

interface IProps {
  size?: number
}

const IssueIcon = ({ size = 15 }: IProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.799635 12.5872L6.39705 15.7857C6.64017 15.9261 6.91595 16 7.19668 16C7.47741 16 7.75319 15.9261 7.99631 15.7857L13.5937 12.5872C13.8374 12.4465 14.0396 12.2441 14.18 12.0002C14.3204 11.7564 14.394 11.4799 14.3934 11.1985V4.80148C14.394 4.52012 14.3204 4.24358 14.18 3.99975C14.0396 3.75593 13.8374 3.55346 13.5937 3.41278L7.99631 0.214261C7.75319 0.0738961 7.47741 0 7.19668 0C6.91595 0 6.64017 0.0738961 6.39705 0.214261L0.799635 3.41278C0.555974 3.55346 0.353738 3.75593 0.213341 3.99975C0.0729437 4.24358 -0.000644661 4.52012 4.25481e-06 4.80148V11.1985C-0.000644661 11.4799 0.0729437 11.7564 0.213341 12.0002C0.353738 12.2441 0.555974 12.4465 0.799635 12.5872ZM1.59927 4.80148L7.19668 1.60295L12.7941 4.80148V11.1985L7.19668 14.397L1.59927 11.1985V4.80148Z"
        fill="black"
      />
      <path
        d="M8.7461 8.72843H3.42871V7.27153H8.7461L7.20639 5.60095L8.15528 4.57141L10.8408 7.48521C10.9666 7.6218 11.0372 7.80694 11.0372 7.99998C11.0372 8.19302 10.9666 8.37817 10.8408 8.51475L8.15528 11.4286L7.20639 10.399L8.7461 8.72843Z"
        fill="black"
      />
    </svg>
  )
}
export default IssueIcon
