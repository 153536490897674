import React from 'react'
import Heading from '@/components/typography/heading'
import { FormattedMessage } from 'react-intl'
import Alert, { MODE } from '@/components/base/Alert'

export const Introduction = () => {
  return (
    <>
      <div className="pt-4 pr-4 pb-6 pl-8 border-b border-b-grey200">
        <div className="max-w-[656px] ">
          <Heading className="mb-2">
            <FormattedMessage id="oor.task.intro.title" />
          </Heading>
          <p className="mb-5">
            <FormattedMessage id="page.generic.oorGuidance1" />
          </p>
          <p>
            <FormattedMessage id="cta.the" />{' '}
            <a className="underline">
              <FormattedMessage id="common.gleif.name" />
            </a>{' '}
            <FormattedMessage id="page.generic.oorGuidance2" />{' '}
          </p>
        </div>
      </div>
      <div className="pt-6 px-8 pb-8">
        <Alert
          className="max-w-[792px] mb-4 mt-0 border-SecondaryBlue50 bg-pressedMenuItem/25"
          mode={MODE.INFO}
        >
          <p className="mb-5 text-base text-hoverBlue">
            <FormattedMessage id="page.generic.oorVleiGuidance1" />
          </p>
          <p className="text-base text-hoverBlue">
            <FormattedMessage id="page.generic.oorVleiGuidance2" />
          </p>
        </Alert>

        <Alert className="max-w-[792px] mb-0" mode={MODE.WARNING}>
          <p>
            <FormattedMessage id="page.generic.oorRecommendation" />
          </p>
        </Alert>
      </div>
    </>
  )
}
