import constants from '@/config/constants'
import OorWorkflowTask from '@/types/oor-workflow-task'

const { ROLES } = constants

export const oorTaskPages: Record<
  string,
  {
    taskType: string
    label: string
    href: string
    grants?: string[]
    excludes?: string[]
    isPublic: boolean
  }
> = {
  [OorWorkflowTask.INTRODUCTION]: {
    taskType: OorWorkflowTask.INTRODUCTION,
    label: 'oor.menu.task.introduction',
    href: '/oor/introduction',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  },
  [OorWorkflowTask.PROPOSE_RECIPIENTS]: {
    taskType: OorWorkflowTask.PROPOSE_RECIPIENTS,
    label: 'oor.menu.task.proposeRecipient',
    href: '/oor/recipient',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  },
  [OorWorkflowTask.QAR_ORG_VERIFICATION]: {
    taskType: OorWorkflowTask.QAR_ORG_VERIFICATION,
    label: 'oor.menu.task.qarVerification',
    href: '/oor/qar-verification',
    grants: [ROLES.QAR],
    excludes: [],
    isPublic: true
  },
  [OorWorkflowTask.PROVIDE_ADDITIONAL_DOCUMENTS]: {
    taskType: OorWorkflowTask.PROVIDE_ADDITIONAL_DOCUMENTS,
    label: 'le.vlei.workflow.task.coordinator',
    href: '/oor/additional-documents',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: false
  },
  [OorWorkflowTask.IDENTITY_VERIFICATION]: {
    taskType: OorWorkflowTask.IDENTITY_VERIFICATION,
    label: 'oor.menu.task.idVerification',
    href: '/oor/id-verification',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  },
  [OorWorkflowTask.SCHEDULE_ISSUANCE_CEREMONY]: {
    taskType: OorWorkflowTask.SCHEDULE_ISSUANCE_CEREMONY,
    label: 'oor.menu.task.scheduleCeremony',
    href: '/oor/schedule',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  },
  [OorWorkflowTask.ISSUANCE_CEREMONY]: {
    taskType: OorWorkflowTask.ISSUANCE_CEREMONY,
    label: 'oor.menu.task.issuanceCeremony',
    href: '/oor/issuance-ceremony',
    grants: [ROLES.EMPLOYEE],
    excludes: [],
    isPublic: true
  }
}

export const oorTaskPageMapping: {
  taskType: string
  label: string
  href: string
  grants?: string[]
  excludes?: string[]
  isPublic: boolean
}[] = Object.values(oorTaskPages)
