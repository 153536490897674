import ErrorRound from '@/components/icons/ErrorRound'
import React from 'react'
import tailwindConfig from 'tailwind.config'
import ErrorSquare from '../icons/ErrorSquare'
import VerifyRoundIcon from '../icons/VerifyRoundIcon'

export enum MODE {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
  INFO_LIGHT = 'INFO_LIGHT'
}

interface IProps {
  mode: MODE
  children: React.ReactNode
  className?: string
  iconHidden?: string
  show?: boolean
}

const getClassNamesByMode = (mode: MODE) => {
  if (mode === MODE.SUCCESS)
    return 'bg-green-100 border-secondaryGreen100 text-green-800'
  else if (mode === MODE.ERROR)
    return 'bg-SecondaryRed50 border-SecondaryRed200 text-SecondaryRed900'
  else if (mode === MODE.WARNING)
    return 'border-SecondaryOrange100 bg-SecondaryOrange50 text-SecondaryOrange900'
  else if (mode === MODE.INFO)
    return 'border-primaryAdditional100 bg-pressedMenuItem text-royalBlue'
  else if (mode === MODE.INFO_LIGHT)
    return 'border-SecondaryBlue50 bg-SecondaryBlue10 text-hoverBlue'
}

const Icon = ({ mode }: { mode: MODE }) => {
  if (mode === MODE.SUCCESS) return <VerifyRoundIcon mode="success" size={16} />
  else if (mode === MODE.ERROR)
    return (
      <ErrorSquare color={tailwindConfig.theme.extend.colors.SecondaryRed900} />
    )
  else if (mode === MODE.WARNING)
    return (
      <ErrorSquare
        color={tailwindConfig.theme.extend.colors.SecondaryOrange900}
      />
    )
  else
    return (
      <ErrorRound
        weight="fill"
        color={tailwindConfig.theme.extend.colors.royalBlue}
      />
    )
}

function Alert({
  mode = MODE.INFO,
  children,
  className = '',
  iconHidden,
  show = true
}: IProps) {
  const classByMode = getClassNamesByMode(mode)

  return (
    <>
      {show && (
        <div
          className={`flex gap-2 rounded-[6px] border font-medium text-base py-2 pl-[14px] pr-4 my-3 ${classByMode} ${className}`}
        >
          <div className={`mt-[3px] mr-2 ${iconHidden}`}>
            <Icon mode={mode} />
          </div>
          <div className="w-full text-base font-normal">{children}</div>
        </div>
      )}
    </>
  )
}

export default Alert
