/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BrandDto,
  BrandEmailDto,
  BrandLinksResponse,
} from '../models/index';
import {
    BrandDtoFromJSON,
    BrandDtoToJSON,
    BrandEmailDtoFromJSON,
    BrandEmailDtoToJSON,
    BrandLinksResponseFromJSON,
    BrandLinksResponseToJSON,
} from '../models/index';

export interface FindBrandRequest {
    brandId: string;
}

export interface FindBrandEmailInfoRequest {
    brandId: string;
    emailTypeId: string;
}

export interface FindBrandEmailLinksRequest {
    brandId: string;
}

/**
 * 
 */
export class BrandControllerApi extends runtime.BaseAPI {

    /**
     */
    async findBrandRaw(requestParameters: FindBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandDto>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling findBrand().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/brand/{brandId}`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandDtoFromJSON(jsonValue));
    }

    /**
     */
    async findBrand(requestParameters: FindBrandRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandDto> {
        const response = await this.findBrandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBrandEmailInfoRaw(requestParameters: FindBrandEmailInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandEmailDto>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling findBrandEmailInfo().'
            );
        }

        if (requestParameters['emailTypeId'] == null) {
            throw new runtime.RequiredError(
                'emailTypeId',
                'Required parameter "emailTypeId" was null or undefined when calling findBrandEmailInfo().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/brand/{brandId}/email-info/{emailTypeId}`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))).replace(`{${"emailTypeId"}}`, encodeURIComponent(String(requestParameters['emailTypeId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandEmailDtoFromJSON(jsonValue));
    }

    /**
     */
    async findBrandEmailInfo(requestParameters: FindBrandEmailInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandEmailDto> {
        const response = await this.findBrandEmailInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBrandEmailLinksRaw(requestParameters: FindBrandEmailLinksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BrandLinksResponse>> {
        if (requestParameters['brandId'] == null) {
            throw new runtime.RequiredError(
                'brandId',
                'Required parameter "brandId" was null or undefined when calling findBrandEmailLinks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/org/brand/{brandId}/email-links`.replace(`{${"brandId"}}`, encodeURIComponent(String(requestParameters['brandId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BrandLinksResponseFromJSON(jsonValue));
    }

    /**
     */
    async findBrandEmailLinks(requestParameters: FindBrandEmailLinksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BrandLinksResponse> {
        const response = await this.findBrandEmailLinksRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
