import { RetryException } from '@/exceptions/retry-exception'
import { consoleLog } from './console-logger'

export interface RetryOptions {
  maxSleep?: number
  minSleep?: number
  maxRetries?: number
  timeout?: number
  signal?: AbortSignal
}

const DEFAULT_MAX_SLEEP = 1000
const DEFAULT_MIN_SLEEP = 10
const DEFAULT_TIMEOUT = 30000
const DEFAULT_MAX_RETRIES = 20
const INCREASE_FACTOR = 50

/**
 * Retries an asynchronous function until it succeeds, a timeout is reached, or the maximum number of retries is reached.
 * Uses exponential backoff for retry delays.
 *
 * @template T - The type of the result returned by the asynchronous function.
 *
 * @param {() => Promise<T>} fn - The asynchronous function to be executed.
 * @param {RetryOptions} [options={}] - An optional configuration object:
 *   @param {number} [options.maxSleep=DEFAULT_MAX_SLEEP] - Maximum delay between retries in milliseconds (default is 1000).
 *   @param {number} [options.minSleep=DEFAULT_MIN_SLEEP] - Minimum delay between retries in milliseconds (default is 10).
 *   @param {number} [options.maxRetries=DEFAULT_MAX_RETRIES] - Maximum number of retry attempts (default is 20).
 *   @param {number} [options.timeout=DEFAULT_TIMEOUT] - Maximum total duration for retries in milliseconds (default is 30000).
 *   @param {AbortSignal} [options.signal] - Optional AbortSignal to cancel retries.
 *
 * @returns {Promise<T>} - A promise that resolves with the result of the function if it succeeds, or rejects with an error if it fails.
 *
 * @throws {Error} - Throws an error if the function fails after all retries or if the timeout is reached.
 *
 */
export async function retry<T>(
  fn: () => Promise<T>,
  options: RetryOptions = {} as RetryOptions
): Promise<T> {
  const {
    maxSleep = DEFAULT_MAX_SLEEP,
    minSleep = DEFAULT_MIN_SLEEP,
    maxRetries = DEFAULT_MAX_RETRIES,
    timeout = DEFAULT_TIMEOUT,
    signal
  } = options

  let retries = 0
  let cause: Error | null = null
  const start = Date.now()

  while (
    (signal === undefined || signal.aborted === false) &&
    Date.now() - start < timeout &&
    (maxRetries === undefined || retries < maxRetries)
  ) {
    consoleLog(
      `retry fn:- Retry Number: ${retries} , Max Retries ${maxRetries} `
    )
    try {
      const result = await fn()
      return result
    } catch (err) {
      cause = err as Error
      const delay = Math.max(
        minSleep,
        Math.min(maxSleep, 2 ** retries * INCREASE_FACTOR)
      )
      retries++
      //await setTimeout(delay, undefined, { signal: signal })
      await delayWithAbortSignal(delay, signal)
    }
  }

  const timeSpent = Date.now() - start
  const isTimeout = timeSpent >= timeout
  const isMaxRetries = retries >= maxRetries

  throw new RetryException(
    `Failed after ${retries} attempts and ${timeSpent} ms`,
    retries,
    maxRetries,
    isTimeout,
    isMaxRetries,
    cause,
    timeSpent
  )
}

/**
 * Retries an asynchronous function until a specified condition is met, a timeout is reached, or the maximum number of retries is reached.
 * Uses exponential backoff for retry delays.
 *
 * @template T - The type of the result returned by the asynchronous function.
 *
 * @param {() => Promise<T>} fn - The asynchronous function to be executed.
 * @param {(result: T) => boolean} condition - A function that takes the result of `fn` and returns a boolean indicating whether the condition is met.
 * @param {RetryOptions} [options={}] - An optional configuration object:
 *   @param {number} [options.maxSleep=DEFAULT_MAX_SLEEP] - Maximum delay between retries in milliseconds (default is 1000).
 *   @param {number} [options.minSleep=DEFAULT_MIN_SLEEP] - Minimum delay between retries in milliseconds (default is 10).
 *   @param {number} [options.maxRetries=DEFAULT_MAX_RETRIES] - Maximum number of retry attempts (default is 20).
 *   @param {number} [options.timeout=DEFAULT_TIMEOUT] - Maximum total duration for retries in milliseconds (default is 30000).
 *   @param {AbortSignal} [options.signal] - Optional AbortSignal to cancel retries.
 *
 * @returns {Promise<T>} - A promise that resolves with the result of the function if the condition is met, or rejects with an error if it fails.
 *
 * @throws {Error} - Throws an error if the condition is not met after all retries or if the timeout is reached.
 *
 */
export async function retryIf<T>(
  fn: () => Promise<T>,
  condition: (result: T) => boolean,
  options: RetryOptions = {} as RetryOptions
): Promise<T> {
  const {
    maxSleep = DEFAULT_MAX_SLEEP,
    minSleep = DEFAULT_MIN_SLEEP,
    maxRetries = DEFAULT_MAX_RETRIES,
    timeout = DEFAULT_TIMEOUT,
    signal
  } = options

  let retries = 0
  let cause: Error | null = null
  const start = Date.now()

  while (
    (!signal || !signal.aborted) &&
    Date.now() - start < timeout &&
    (maxRetries === undefined || retries < maxRetries)
  ) {
    consoleLog(
      `retryIf fn:- Retry Number: ${retries} , Max Retries ${maxRetries} `
    )
    try {
      const result = await fn()
      if (condition(result)) {
        return result
      }
    } catch (err) {
      cause = err as Error
    }

    const delay = Math.max(
      minSleep,
      Math.min(maxSleep, 2 ** retries * INCREASE_FACTOR)
    )
    retries++
    //await setTimeout(delay, undefined, { signal })
    await delayWithAbortSignal(delay, signal)
  }

  const timeSpent = Date.now() - start
  const isTimeout = timeSpent >= timeout
  const isMaxRetries = retries >= maxRetries

  throw new RetryException(
    `Failed after ${retries} attempts and ${timeSpent} ms`,
    retries,
    maxRetries,
    isTimeout,
    isMaxRetries,
    cause,
    timeSpent
  )
}

function delayWithAbortSignal(ms: number, signal?: AbortSignal): Promise<void> {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => resolve(), ms)
    if (signal) {
      signal.addEventListener('abort', () => {
        clearTimeout(timer)
        reject(new Error('Aborted'))
      })
    }
  })
}
